import '../App.scss';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import BaseForm from "./BaseForm";
import ProductPricingSelection from "./ProductPricingSelection";
import SubmitButton from "./SubmitButton";
import React, {createRef, useContext, useEffect, useMemo, useState} from "react";
import moment from "moment/moment";
import {serverFetch, serverPatch, serverPost} from "../helpers/server";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {BaseContext} from "../helpers/common";
import CustomerInput from "./CustomerInput";
import {getAllProductPricingIdsInContract, getProductPricingIdsInPhase} from "../helpers/subscriptions";
import AddOrEditProductPricingModal2 from "./modals/AddOrEditProductPricingModal2";
const _ = require('lodash');

const BaseProposalCreateOrUpdateForm = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const [ searchParams ] = useSearchParams();
    let defaultPricingId = searchParams.get('pricing');
    let customerId = searchParams.get('cid');

    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [productPricings, setProductPricings] = useState([]);
    const [selectedProductPricingIds, setSelectedProductPricingIds] = useState([]);
    const [sort, setSort] = useState("createdAtDesc");
    const [error, setError] = useState(null);
    const [isTrial, setIsTrial] = useState(false);
    const [hasNonOneTimeItems, setHasNonOneTimeItems] = useState(false);
    const [completionAction, setCompletionAction] = useState("CONFIRMATION_PAGE");
    const [showAddProductPricingModal, setShowAddProductPricingModal] = useState(false);
    const pricingsRef = createRef();

    const [isEditing, setIsEditing] = useState(false);
    const defaultInitialFields = useMemo(() => {
        return {
            term: {
                count: 1,
                frequency: "YEAR"
            }
        }
    })
    const [initialFields, setInitialFields] = useState(defaultInitialFields);
    const [initialProposalData, setInitialProposalData] = useState({});

    useEffect(() => {
        const limit = 200
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
            if (defaultPricingId) {
                const data ={
                    ...initialFields,
                    ...initialProposalData,
                    phases: [{
                        product_pricing_ids: [defaultPricingId],
                        config: {}
                    }],
                }
                setInitialFields(data);
                setInitialProposalData(data);
                if (pricingsRef.current) {
                    pricingsRef.current.setInitialData(data);
                }
                setSelectedProductPricingIds([defaultPricingId]);
            }
        });
    }, [])

    useEffect(() => {
        if (!_.isNil(uuid)) {
            serverFetch(getApiUrl(`/proposals/${uuid}`)).then((res) => {
                const data ={
                    ...res,
                    phases: [{
                        product_pricing_ids: getProductPricingIdsInPhase(res),
                        config: _.keyBy(res.config_items, 'product_metric_pricing_id')
                    }],
                    customer: props.customer,
                }
                if (data.options) {
                    data.options.completion_action = data.options.completion_action || "CONFIRMATION_PAGE"
                }
                if (pricingsRef.current) {
                    pricingsRef.current.setInitialData(data);
                }
                setInitialFields(data);
                setInitialProposalData(data);
                setIsTrial(res.trial);
                if (res.trial && res.trial_term) {
                    data.num_trial_days = res.trial_term.count
                }
                setCompletionAction(data.options.completion_action);
                setSelectedProductPricingIds(getAllProductPricingIdsInContract(res));
            })
            setIsEditing(true);
        } else {
            setInitialFields(defaultInitialFields)
            setIsEditing(false);
        }
    }, [uuid])

    useEffect(() => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.customer = props.customer;
            return newFields;
        });
    }, [props.customer])

    const createPaymentLink = async (data, errorHandler) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }
        setError(null);
        const isToday = moment(0, "HH").diff(data.start_date, "days") == 0;
        const startDate = isToday ? moment(): moment(data.start_date)
        const paymentLinkData = {
            ...pricingData,
            options: data.options,
            term: data.term ? {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            }: {
                frequency: "YEAR",
                count: 1
            },
            start_date: props.type === "CHECKOUT" ? null: startDate.format(),
            type: props.type,
            trial: data.trial,
        }
        if (data.trial) {
            paymentLinkData['trial_term'] = {
                frequency: "DAY",
                count: data.num_trial_days
            }
        }
        if (props.customer) {
            paymentLinkData['customer_id'] = props.customer.id;
        }

        if (isEditing) {
            const paymentLinkUpdateResult = await serverPatch(getApiUrl(`/proposals/${uuid}`), paymentLinkData, {}, errorHandler)
            if (paymentLinkUpdateResult) {
                if (paymentLinkUpdateResult.id) {
                    navigate(getCompanySpecificUrl(`/payment_link/${paymentLinkUpdateResult.id}`))
                } else {
                    navigate(getCompanySpecificUrl(`/payment_links`))
                }
            }
        } else {
            const paymentLinkResult = await serverPost(getApiUrl('/proposals'), paymentLinkData, {}, errorHandler)
            if (paymentLinkResult) {
                if (props.onCreate) {
                    props.onCreate(paymentLinkResult);
                } else {
                    if (paymentLinkResult.id) {
                        navigate(getCompanySpecificUrl(`/payment_link/${paymentLinkResult.id}`))
                    } else {
                        navigate(getCompanySpecificUrl(`/payment_links`))
                    }
                }
            }
        }
    }

    const onFieldChange = (name, value) => {
        // console.log("Field change " + name + ", " + value);
        if (name === "options.completion_action") {
            setCompletionAction(value);
        } else if (name === "trial") {
            setIsTrial(value)
        }
        pricingsRef.current.onFieldChange(name, value);
    }

    const onModalClose = () => {
        setShowAddProductPricingModal(false);
    }

    const onProductPricingCreated = (productPricing) => {
        if (pricingsRef.current) {
            pricingsRef.current.onProductPricingCreated(productPricing)
        }
    }

    const onProductPricingSelectionChange = (phase, selectedProductPricings) => {
        setHasNonOneTimeItems(_.some(selectedProductPricings, (spp) => {
            const flag = _.some(spp.product_metric_pricings, (pmp) => {
                return pmp.item.type !== "ONETIME_ITEM";
            })
            return flag
        }))
    }

    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "BI_ANNUAL", label: "Bi-Annual" },
        { value: "YEAR", label: "Year" },
    ]

    const completionActionOptions = [
        { value: "CONFIRMATION_PAGE", label: "Show Confirmation Page" },
        { value: "REDIRECT", label: "Redirect to an URL" },
    ]

    const typeName = props.type === "CHECKOUT_SESSION" ? "Checkout Session": "Payment Link";
    return (
        <>
            <BaseForm initialFormFields={initialFields} onSubmit={createPaymentLink} onFieldChange={onFieldChange}>
                <Row className="align-items-center">
                    <Col md="8">
                        <h4>{ isEditing ? "Edit": "Create" } {typeName}</h4>
                    </Col>
                </Row>
                <hr/>
                {
                    props.customer &&
                        <Row className="mt-2">
                            <CustomerInput colSpan="6" defaultCustomer={props.customer} required />
                        </Row>
                }
                <Row className="mt-2">
                    {
                        hasNonOneTimeItems &&
                        <>
                            <Col sm="5">
                                <BaseForm.InputGroup label="Term">
                                    <BaseForm.Number name="term.count" min="1" required label="Count" hideLabel/>
                                    <BaseForm.Divider />
                                    <BaseForm.SingleSelect name="term.frequency" options={planLengthOptions} showSearch={false}/>
                                </BaseForm.InputGroup>
                            </Col>
                            <Col lg="3" className="flex items-end">
                                <BaseForm.Input
                                    type="switch" name="trial" label={"Free trial days"} outerInputClassName="grow"
                                />
                            </Col>
                            {
                                isTrial &&
                                <BaseForm.Input colSpan={3} type="number" name="num_trial_days" label="Trial Days" step="1" min="0"/>
                            }
                        </>
                    }

                </Row>
                <br/>
                <ProductPricingSelection
                    ref={pricingsRef} productPricings={productPricings}
                    initialSelectionData={initialProposalData}
                    initialFields={initialFields} setInitialFields={setInitialFields}
                    onSelectionChange={onProductPricingSelectionChange} allowCreation
                    onCreateProductPricing={() => setShowAddProductPricingModal(true)}
                    menuPlacement={"bottom"}
                />
                <br/>
                <hr/>
                <Row className="mt-2">
                    <div className="body2">Advanced Options</div>
                    <Col md="6">
                        <BaseForm.Input type="switch" name="options.allow_promo_code" label="Allow Promo Codes" formClassName="thin"/>
                    </Col>
                    <Col md="6">
                        <BaseForm.Input
                            type="select" name="options.completion_action" label="Completion Action"
                            options={completionActionOptions} showSearch={false} />
                        {
                            completionAction === "CONFIRMATION_PAGE" &&
                            <BaseForm.Input
                                type="textarea" name="options.confirmation_message" label="Confirmation Message"
                                placeholder={"Enter a custom message if you choose. Leave blank for default message."}
                                height="120px"
                            />
                        }
                        {
                            completionAction === "REDIRECT" &&
                            <BaseForm.Input
                                type="text" name="options.redirect_url" label="Redirect To"
                                validations={{ validURL: true, required: true }}
                                placeholder={"https://www.example.com"}
                            />
                        }
                    </Col>
                </Row>
                {
                    error &&
                    <div className="form-error-message">{ error }</div>
                }
                <Row>
                    <Col md="12">
                        <br/>
                        <SubmitButton errorOnRight={true}>{ isEditing ? "Update": "Create" } {typeName}</SubmitButton>
                    </Col>
                </Row>
            </BaseForm>
            <AddOrEditProductPricingModal2
                show={showAddProductPricingModal}
                onProductPricingCreated={onProductPricingCreated}
                onClose={onModalClose}
            />
        </>
    );
}

export default BaseProposalCreateOrUpdateForm;
