import '../App.scss';
import '../js/forms.js';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import classnames from "classnames";
const _ = require("lodash");

function SecondaryLeftNav(props) {
    const { t } = useTranslation('common');
    const [shouldAutoMatch, setShouldAutoMatch] = useState(true);

    const isItemActive = (item) => {
        if (item.active) {
            return item.active;
        } else if (shouldAutoMatch) {
            if (props.matchPathnameOnly || item.matchPathnameOnly) {
                return item.link === window.location.pathname;
            } else {
                return item.link === (window.location.pathname + window.location.search);
            }
        } else {
            return item.value === props.activeKey;
        }
    }

    return (
        <div className={classnames("metrics-menu", props.className)}>
            <div className="nav-items">
                {
                    _.map(props.items, (item, i) => {
                        if (!item) {

                        } else if (item.isHeader) {
                            return (<span key={i} className="nav-group-header body2">{ item.label }</span>);
                        } else {
                            return (<MenuItem addSearchToNavigation={props.addSearchToNavigation} key={i} label={item.label} link={item.link} active={isItemActive(item)} />);
                        }
                    })
                }
            </div>
        </div>
    )
}

function MenuItem(props) {
    const navigate = useNavigate();

    const handleClick = (event) => {
        event.preventDefault();
        if (props.onClick) {
            props.onClick();
        } else if (props.link) {
            if (props.addSearchToNavigation) {
                navigate(props.link + window.location.search);
            } else {
                navigate(props.link);
            }
        }
    }

    const activeClass = props.active ? "active": "";
    return (
        <div className={classnames("nav-item", activeClass)} onClick={(event) => handleClick(event)}>
            <div className="nav-title">
                {props.label}
            </div>
        </div>
    )
}

export default SecondaryLeftNav;
