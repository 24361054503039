import '../../App.scss';
import '../../css/modals.scss';
import { Alert } from 'react-bootstrap';
import React, {
    Fragment,
    useEffect,
    useState,
    useContext,
    createContext,
    forwardRef,
    useImperativeHandle,
    createRef
} from 'react';
import {BaseContext, renderChildren} from '../../helpers/common';
import BaseForm from '../BaseForm';
import classnames from 'classnames';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export const SideModalContext = createContext();

const BaseSideModal = (props) => {
    const [showChildren, setShowChildren] = useState(false);
    const [show, setShow] = useState(false);
    const [sizeClass, setSizeClass] = useState("max-w-md");

    const handleClose = () => {
        document.body.click();
        setShow(false);
        if (props.onClose) {
            props.onClose(false);
        }
    }

    useEffect(() => {
        setShow(props.show);
        if (!props.show && showChildren) {
            setTimeout(() => {
                setShowChildren(false);
            }, 250);
        } else {
            setShowChildren(props.show);
        }
    }, [props.show]);

    useEffect(() => {
        if (props.size === "lg") {
            setSizeClass("max-w-2xl");
        } else if (props.size === "md") {
            setSizeClass("max-w-lg");
        } else if (props.size === "sm") {
            setSizeClass("max-w-md");
        }
    }, [props.size]);

    return (
        <SideModalContext.Provider value={{ handleClose }}>
            <Transition.Root show={props.show || false} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleClose}>
                    <div className="fixed inset-0" />
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-400"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-400"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className={classnames("pointer-events-auto w-screen flex flex-col bg-white h-full", sizeClass, props.className)}>
                                        { showChildren && renderChildren(props) }
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </SideModalContext.Provider>
    )
}

const Header = (props) => {
    const { handleClose } = useContext(SideModalContext);
    return (
        <div className="bg-white p-3">
            <div className="flex items-center justify-between space-x-3">
                <div className="space-y-1">
                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        { props.title }
                    </Dialog.Title>
                    {
                        props.subtitle &&
                        <span className="text-sm text-gray-500">
                            { props.subtitle }
                        </span>
                    }
                </div>
                <div className="flex h-7 items-center">
                    <button
                        type="button"
                        className="relative text-gray-400 hover:text-gray-500"
                        onClick={() => handleClose()}
                    >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    );
}
BaseSideModal.Header = Header;


const Form = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getFormData() {
            if (formRef.current) {
                return formRef.current.getFormData();
            }
            return null;
        },
    }));
    const formRef = createRef();

    return (
        <BaseForm ref={formRef} {...props} className={classnames("flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl", props.className)}>
            { renderChildren(props) }
        </BaseForm>
    );
})
BaseSideModal.Form = Form;

const Content = (props)  => {
    return (
        <div className={classnames("flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl", props.className)}>
            { renderChildren(props) }
        </div>
    );
}
BaseSideModal.Content = Content;

const Body = (props) => {
    return (
        <div className={classnames("relative flex-1 p-3 overflow-auto", props.className)}>
            <div className="hide notification-modal ahide noprint">
                <Alert variant="warning">
                    Warning message
                </Alert>
            </div>
            { renderChildren(props) }
        </div>
    );
}
BaseSideModal.Body = Body;

const Title = (props) => {
    return (
        <div className="title">
            { renderChildren(props) }
        </div>
    );
}
BaseSideModal.Title = Title;

const Footer = (props) => {
    return (
        <div className="flex flex-shrink-0 justify-end p-3">
            { renderChildren(props) }
        </div>
    );
}
BaseSideModal.Footer = Footer;

export default BaseSideModal;