import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import React, { useContext, useState, useEffect } from 'react';
import {
    BaseContext, sleep,
} from '../../helpers/common';
import {Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MapleTable from "../MapleTable";
import {serverPost} from "../../helpers/server";
import Label from "../Label";
import Loader from "../Loader";
import Notification from "../Notification";
const _ = require("lodash");

function IntegrationReferenceEntitySummaryModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [references, setReferences] = useState([]);
    const [withoutReferences, setWithoutReferences] = useState([]);

    const fetchReferences = () => {
        const refData = {
            entity_key: props.entityKey,
            reference_id: props.referenceID
        }
        serverPost(getApiUrl(`/integrations/entity_summary`), refData).then(res => {
            if (res) {
                setReferences(res.references)
                setWithoutReferences(res.without_references)
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        if (props.show) {
            fetchReferences();
        }
    }, [props.show]);

    const pushIntegrationReference = async (ref) => {
        const data = {
            feature: ref.feature,
            sync_type: "PUSH",
            entity: ref.reference_type,
            entity_id: ref.reference_id,
        }
        const result = await serverPost(getApiUrl(`/integrations/${ref.integration_id}/trigger_sync`), data, {});
        if (result) {
            Notification.Success("Initiated a push.")
            setTimeout(() => {
                fetchReferences()
            }, 3000)
        }
    }

    const renderStatus = (ref) => {
        if (ref.state === "ACTIVE") {
            return <Label.Success>Synced</Label.Success>
        } else if (ref.state === "NEEDS_UPDATE") {
            return <Label.Warning>Pending update</Label.Warning>
        } else if (ref.state === "PENDING_REMOTE_CREATE") {
            return <Label.Warning>Pending creation</Label.Warning>
        } else if (ref.state === "NEEDS_MAPPING") {
            return <Label.Warning>Needs Mapping</Label.Warning>
        } else {
            return <Label.Info>{ ref.state }</Label.Info>
        }
    }

    const renderActions = (ref) => {
        const isIntegrationActive = ref.integration.status === "ACTIVE";
        if (ref.state === "ACTIVE" || _.isNil(ref.state)) {
            if (isIntegrationActive) {
                return <Button onClick={() => pushIntegrationReference(ref)}>Push</Button>
            }
        } else if (ref.state === "NEEDS_UPDATE") {
            return null
        } else if (ref.state === "PENDING_REMOTE_CREATE") {
            return null
        } else if (ref.state === "NEEDS_MAPPING") {
            if (isIntegrationActive) {
                return <Button onClick={() => pushIntegrationReference(ref)}>Push</Button>
            }
        } else {
            return null
        }
        return null;
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form>
                <BaseSideModal.Header title={"Integration - Connected References"}/>
                <BaseSideModal.Body>
                    <Loader loading={loading}>
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                    <tr>
                                        <MapleTable.TH>Integration</MapleTable.TH>
                                        <MapleTable.TH>Remote Details</MapleTable.TH>
                                        <MapleTable.TH>Status</MapleTable.TH>
                                        <MapleTable.TH className="!pl-0 w-px whitespace-nowrap"></MapleTable.TH>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(references, (ref, i) =>
                                        <tr key={i}>
                                            <td>{ ref.reference.integration.name }</td>
                                            <td>
                                                <div className="flex flex-col">
                                                    { ref.reference.remote_data && <span>{ ref.reference.remote_data.id }</span> }
                                                    { ref.reference.remote_type && <span className="text-gray-500">{ ref.reference.remote_type }</span> }
                                                </div>
                                            </td>
                                            <td>{ renderStatus(ref.reference) }</td>
                                            <td className="!pl-0 w-px whitespace-nowrap">
                                                {
                                                    ref.warning_message ?
                                                        <div className="form-error-message">{ ref.warning_message }</div>
                                                        : renderActions(ref.reference)
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    _.map(withoutReferences, (ref, i) =>
                                        <tr key={i}>
                                            <td>{ref.integration.name}</td>
                                            <td>

                                            </td>
                                            <td><Label.Info>Not Synced</Label.Info></td>
                                            <td className="!pl-0 w-px whitespace-nowrap">
                                                {
                                                    ref.warning_message ?
                                                        <div className="form-error-message">{ ref.warning_message }</div>
                                                        : renderActions(ref)
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Loader>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Button onClick={() => props.onClose()}>Close</Button>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default IntegrationReferenceEntitySummaryModal;
