import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../SubmitButton';
import Columns from "../../components/Columns";
import Notification from '../../components/Notification';
import {BaseContext, currencyFormatFromPrice, cyrb53, IntegrationContext} from '../../helpers/common';
import {serverPatch, serverPost} from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {ChevronDoubleDownIcon} from "@heroicons/react/24/outline";
import {useParams} from "react-router-dom";
import Link from "../Link";
import moment from "moment";
import CustomerInput from "../CustomerInput";
const _ = require('lodash');

function UpdateIntegrationReferenceModal(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const { integration } = useContext(IntegrationContext);
    let { entity } = useParams();
    entity = _.replace(_.upperCase(entity), " ", "_");
    const [mapleReference, setMapleReference] = useState(null);

    const remoteLabel = (remoteReference) => {
        let label = "";
        let subtitle = "";
        if (remoteReference.additional_data) {
            if (props.reference_type === "INVOICE") {
                label = remoteReference.additional_data.invoice_number
                const invoiceDate = remoteReference.additional_data.invoice_date
                subtitle = `${moment(invoiceDate).format("MMM D, YYYY")} - ${remoteReference.additional_data.balance}`
            } else {
                if (remoteReference.additional_data.display_name) {
                    label = remoteReference.additional_data.display_name
                    subtitle = `${remoteReference.additional_data.email}, ${remoteReference.additional_data.currency}`
                } else {
                    label = remoteReference.additional_data.email
                    subtitle = remoteReference.additional_data.currency
                }
            }
        }
        return (
            <div>
                <div className="flex flex-col">
                    <div className="text-sm font-semibold">{ label }</div>
                    <div className="text-sm text-gray-500 -mt-1">{ subtitle }</div>
                </div>
            </div>
        )
    }

    const mapleInvoiceLabel = (invoice) => {
        let label = invoice.number;
        let subtitle = `${moment(invoice.invoice_date).format("MMM D, YYYY")} - ${currencyFormatFromPrice(invoice.total)}`
        return (
            <div>
                <div className="flex flex-col">
                    <div className="text-sm font-semibold">{ label }</div>
                    <div className="text-sm text-gray-500 -mt-1">{ subtitle }</div>
                </div>
            </div>
        )
    }

    const loadMapleInvoices = async (query) => {
        const limit = 20;
        const params = {
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: limit
            },
            query: {
                statuses: ["PAID", "PAYMENT_PROCESSING", "PENDING", "OVERDUE", "VOID"],
                search: query,
            },
            include_meta: false
        }
        const remoteResult = await serverPost(getApiUrl("/invoices/find"), params);
        if (remoteResult) {
            return remoteResult.results || [];
        }

        return [];
    }

    const loadRemoteReferences = async (query) => {
        if (query.length < 3) {
            return [];
        }
        const remotePromise = serverPost(getApiUrl(`/integrations/${integration.id}/remote_auto_complete`), {
            entity_type: entity,
            search_term: query
        });
        const remoteResult = await remotePromise;
        return remoteResult || [];
    }

    const updateIntegrationReference = async (data, errorHandler) => {
        if (props.reference) {
            const mappingData = {
                integration_reference_id: props.reference.id,
                remote_id: data.remote_reference.remote_id
            }
            const result = await serverPatch(getApiUrl(`/integrations/${integration.id}/references/${props.reference.id}`), mappingData, {}, errorHandler)
            if (result) {
                props.onClose(true);
                Notification.Success("Updated Successfully");
            }
        } else {
            let reference_id = "";
            if (props.reference_type === "CUSTOMER") {
                reference_id = data.customer.id
            } else if (props.reference_type === "INVOICE") {
                reference_id = data.invoice.id
            }
            // Creating a new integration reference
            const mappingData = {
                reference_type: props.reference_type,
                reference_id: data.customer.id,
                feature: props.feature,
                state: "ACTIVE",
                remote_id: data.remote_reference.remote_id
            }
            const result = await serverPost(getApiUrl(`/integrations/${integration.id}/references`), mappingData, {}, errorHandler)
            if (result) {
                props.onClose(true);
                Notification.Success("Updated Successfully");
            }
        }
    }

    const renderReference = () => {
        if (!props.reference) {
            if (props.reference_type === "CUSTOMER") {
                return (
                    <div className="flex flex-col gap-1">
                        <span className="text-sm font-semibold">Pick a Maple customer to map in { integration.name }</span>
                        <CustomerInput colSpan="12" required />
                    </div>
                )
            } else if (props.reference_type === "INVOICE") {
                return (
                    <div className="flex flex-col gap-1">
                        <span className="text-sm font-semibold">Pick a Maple invoice to map in { integration.name }</span>
                        <BaseForm.Input
                            colSpan="12" name="invoice" label="Search Maple Invoices" type="selector"
                            loadOptions={loadMapleInvoices} getOptionLabel={mapleInvoiceLabel}
                            getOptionValue={(r) => r.remote_id} required />
                    </div>
                )
            }
        } else {
            if (props.reference.reference_type === "CUSTOMER") {
                return (
                    <div className="flex flex-col gap-1">
                        <span className="text-sm font-semibold">Map Maple customer to { integration.name }</span>
                        <div className="rounded-md border-1 border-slate-200 p-2">
                            <Columns.CustomerName customer={props.reference.customer}/>
                        </div>
                    </div>
                )
            } else if (props.reference.reference_type === "INVOICE") {
                return (
                    <div className="flex flex-col gap-1">
                        <span className="text-sm font-semibold">Map Maple Invoice to { integration.name }</span>
                        <Link
                            href={getCompanySpecificUrl(`/invoice/${props.reference.invoice.uuid}`)}
                            target="_blank" className="text-sm">
                            <div className="flex flex-col gap-2 rounded-md border-1 border-slate-200 p-2">
                                <div className="text-sm font-semibold">{ props.reference.invoice.number }</div>
                                <div className="text-sm text-gray-500 -mt-1">{ moment(props.reference.invoice.invoice_date).format("MMM D, YYYY") } - { currencyFormatFromPrice(props.reference.invoice.total_amount) }</div>
                            </div>
                        </Link>
                    </div>
                )
            }
        }
    }

    const onFieldChange = (name, value) => {
        if (_.includes(["invoice", "customer"], name)) {
            setMapleReference(value)
        }
    }

    const showRemoteSearch = !_.isNil(props.reference) || !_.isNil(mapleReference);
    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onFieldChange={onFieldChange} onSubmit={updateIntegrationReference}>
                <BaseSideModal.Header title={"Update Integration Mapping"}/>
                <BaseSideModal.Body>
                    <div className="flex flex-col gap-4">
                        { renderReference() }
                        <div className="align-self-center">
                            <ChevronDoubleDownIcon className="h-8 w-8 text-gray-500"/>
                        </div>
                        {
                            showRemoteSearch &&
                                <Row className="mt-4">
                                    <BaseForm.Input
                                        colSpan="12" name={"remote_reference"} label={`Search ${integration.name}`} type="selector"
                                        loadOptions={loadRemoteReferences} getOptionLabel={remoteLabel}
                                        getOptionValue={(r) => r.remote_id} required />
                                </Row>
                        }
                    </div>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Update</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default UpdateIntegrationReferenceModal;
