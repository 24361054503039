import '../../App.scss';
import variables from '../../App.scss';
import '../../css/contract.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverFetch, serverPost } from '../../helpers/server';
import {
    BaseContext, formatTerm,
    getLabelForSubscriptionLength, getPaymentMethodDescription,
    PaymentLinkContext,
} from '../../helpers/common';
import ContentContainer from '../../components/ContentContainer';
import SubmitButton from '../../components/SubmitButton';
import BaseForm from '../../components/BaseForm';
import Label from '../../components/Label';
import PricingBreakdown from '../../components/PricingBreakdown';
import StripeSetupPaymentMethod from '../../components/StripeSetupPaymentMethod';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { useSearchParams, useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthorizeNetPaymentInput from "../../components/AuthorizeNetPaymentInput";
import moment from "moment/moment";
import classnames from "classnames";
const _ = require('lodash');

function PaymentLinkView(props) {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    let setupIntent = searchParams.get('setup_intent');
    let token = searchParams.get('token');

    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { paymentLink } = useContext(PaymentLinkContext);
    const [pricingSummary, setPricingSummary] = useState(null);
    const [productNames, setProductNames] = useState(null);
    const [settings, setSettings] = useState({});
    const [customerToken, setCustomerToken] = useState(null);
    const [askForCode, setAskForCode] = useState(false);
    const [errorFields, setErrorFields] = useState({});
    const [showPromoCodeForm, setShowPromoCodeForm] = useState(paymentLink.options && paymentLink.options.allow_promo_code);
    const [acceptedCode, setAcceptedCode] = useState(null);
    const [promoErrorFields, setPromoErrorFields] = useState({});
    const [loadingPricing, setLoadingPricing] = useState(true);
    const [loadingSettings, setLoadingSettings] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const setupRef = createRef();
    const payment_config = paymentLink.payment_config || settings.payment_config;

    useEffect(() => {
        setPageTitle(`Buy`);
    }, [paymentLink]);

    useEffect(() => {

    }, [uuid])

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
            setLoadingSettings(false);
        })
    }, [])

    useEffect(() => {
        if (token && paymentLink.customer) {
            const options = {
                skipCache: true,
                accessToken: token,
                suppressUnauthenticated: true
            }
            serverFetch(getApiUrl(`/customers/${paymentLink.customer.id}/payment_methods`), options).then((res) => {
                if (res) {
                    const methods = res.methods || [];
                    setPaymentMethods(methods);
                    if (methods.length > 0) {
                        setSelectedPaymentMethod(methods[0].id)
                    }
                }
            });
        }
    }, [token]);

    useEffect(() => {
        if (!_.isNil(redirectStatus)) {
            if (redirectStatus === "succeeded") {
                payWithSetupIntent();
            }
        } else {
            if (!_.isNil(uuid)) {
                const existingCode = localStorage.getItem(`proposal_${uuid}_promo_code`);
                if (existingCode) {
                    setAcceptedCode(existingCode);
                }
                updatePricing(existingCode);
            }
        }
    }, [redirectStatus])

    const payWithSetupIntent = () => {
        const code = localStorage.getItem(`proposal_${uuid}_promo_code`);
        let payData = {
            setup_intent_id: setupIntent,
            promo_codes: code ? [code] : null
        }
        if (_.isNil(paymentLink.customer)) {
            const formData = JSON.parse(localStorage.getItem(`proposal_${uuid}_data`));
            payData.email = formData.email
            payData.name = formData.name
            payData.address = formData.address
            payData.phone = formData.phone
        }
        const payOptions = {

        }
        serverPost(getApiUrl(`/proposals/${uuid}/pay`), payData, payOptions).then((res) => {
            if (!_.isNil(res)) {
                localStorage.removeItem(`proposal_${uuid}_promo_code`);
                localStorage.removeItem(`proposal_${uuid}_data`)
                if (paymentLink.options && paymentLink.options.completion_action === "REDIRECT" && paymentLink.options.redirect_url) {
                    let redirectUrl = paymentLink.options.redirect_url;
                    let urlParser = new URL(redirectUrl);
                    if (res.customer) {
                        urlParser.searchParams.set('customer_id', res.customer.id);
                    }
                    window.location = urlParser.href;
                } else {
                    navigate(getCompanySpecificUrl(`/buy/${uuid}/done`));
                }
            }
        })
    }

    useEffect(() => {
        if (pricingSummary) {
            let subsProductNames = [];
            if (pricingSummary.subscription_details.product_breakdown) {
                subsProductNames = _.map(pricingSummary.subscription_details.product_breakdown, (pb) => pb.product.name);
            }
            let oneTimeProductNames = [];
            if (pricingSummary.one_time_details.product_breakdown) {
                oneTimeProductNames = _.map(pricingSummary.one_time_details.product_breakdown, (pb) => pb.product.name);
            }
            setProductNames(_.uniq(_.concat(subsProductNames, oneTimeProductNames)));
        } else {
            setProductNames(null);
        }
    }, [pricingSummary])

    const onPromoCodeError = async (res) => {
        const errorMessage = await res.json();
        if (res.status === 400) {
            setPromoErrorFields({
                code: errorMessage.error_message
            })
        }
    }

    const updatePricing = (code) => {
        if (paymentLink.status === "EXPIRED") {
            // The payment link is expired. Don't make this call.
            return;
        }
        const data = {};
        if (code) {
            data.promo_codes = [code];
        }
        setPromoErrorFields({});
        serverPost(getApiUrl(`/proposals/${uuid}/pricing`), data, {}, onPromoCodeError).then((res) => {
            if (res) {
                if (_.isUndefined(code) || _.isNil(code)) {
                    localStorage.removeItem(`proposal_${uuid}_promo_code`);
                    setAcceptedCode(null);
                } else {
                    localStorage.setItem(`proposal_${uuid}_promo_code`, code || null);
                    setAcceptedCode(code);
                }
                setPricingSummary(res);
                setLoadingPricing(false);
            }
        })
    }

    const onVerifyError = async (res) => {
        const errorMessage = await res.json();
        if (res.status === 400) {
            if (errorMessage.code_sent) {
                setAskForCode(true);
            } else {
                setErrorFields(errorMessage);
            }
        }
    };

    const removePromoCode = () => {
        setAcceptedCode(null);
        updatePricing(null);
    }

    const onError = async (res) => {
        const errorMessage = await res.json();
        setErrorFields(errorMessage);
    };

    const processPayment = async (data) => {
        const paymentData = {
            payment_data: data
        }
        const payResult = await serverPost(getApiUrl(`/proposals/${uuid}/pay`), paymentData, {}, onError);
        if (payResult) {
            if (paymentLink.options && paymentLink.options.completion_action === "REDIRECT" && paymentLink.options.redirect_url) {
                let redirectUrl = paymentLink.options.redirect_url;
                let urlParser = new URL(redirectUrl);
                if (payResult.customer) {
                    urlParser.searchParams.set('customer_id', payResult.customer.id);
                }
                window.location = urlParser.href;
            } else {
                navigate(getCompanySpecificUrl(`/buy/${uuid}/done`));
            }
        }
    }

    const onSubmit = async (data) => {
        const email = data.customer?.email ? data.customer.email : (data.email ? data.email : null);
        if (email) {
            const res = await serverPost(getApiUrl(`/proposals/${uuid}/validate_email`), { email }, {}, onError);
            if (!res) return;
        }

        if (data.payment_method) {
            const paymentMethodData = {}
            if (_.includes(["AUTHORIZE_NET", "authorize_net"], payment_config && payment_config.provider)) {
                paymentMethodData['authorize_net'] = {
                    payment_method_id: data.payment_method
                }
            } else if (_.includes(["STRIPE", "stripe"], payment_config && payment_config.provider)) {
                paymentMethodData['stripe'] = {
                    payment_method_id: data.payment_method
                }
            }
            await processPayment(paymentMethodData);
        } else {
            if (_.includes(["AUTHORIZE_NET", "authorize_net"], payment_config && payment_config.provider)) {
                if (setupRef.current) {
                    const { paymentMethodData, error } = await setupRef.current.getPaymentMethodData(data);
                    if (error) {
                        setErrorFields(error);
                        return;
                    }
                    await processPayment(paymentMethodData);
                }
            } else {
                // Store the information in local storage and call stripe.
                if (_.isNil(paymentLink.customer)) {
                    const formData = {
                        email: data.email,
                        address: data.address,
                        phone: data.phone,
                        name: data.name
                    }
                    localStorage.setItem(`proposal_${uuid}_data`, JSON.stringify(formData));
                }
                if (setupRef.current) {
                    await setupRef.current.onSubmit(data);
                }
            }
        }
    }

    const paymentMethodOptions = _.map(paymentMethods, (pm) => {
        return {
            label: getPaymentMethodDescription(t, pm),
            value: pm.id
        }
    })
    paymentMethodOptions.push({
        label: "Add new payment method",
        value: null
    })

    const showPaymentMethodInput = _.isNil(selectedPaymentMethod);

    let brandingLogo = null;
    let brandColor = variables.primaryColor;
    if (settings.branding) {
        brandingLogo = settings.branding.logo_url || null;
        brandColor = settings.branding.color || variables.primaryColor;
    }

    const renderFooterDetails = () => {
        if (payment_config && _.includes(["STRIPE", "stripe"], payment_config.provider)) {
            return (
                <div className="payment-details">
                    <div className="flex flex-row my-4 justify-center">
                        <a href="https://www.stripe.com" target="_blank">
                            <span className="gray3 caption">Payments powered by&nbsp;</span>
                            <svg className="inline-block InlineSVG Icon powered-by-icon Icon--md" focusable="false"
                                 width="33" height="15" role="img" aria-labelledby="stripe-title"><title
                                id="stripe-title">Stripe</title><g fill-rule="evenodd"><path
                                d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
                        </a>
                    </div>
                </div>
            )
        } else if (payment_config && _.includes(["AUTHORIZE_NET", "authorize_net"], payment_config.provider)) {
            return (
                <div className="payment-details">
                    <div className="flex flex-row my-4 justify-center">
                        <a href="https://www.authorize.net" target="_blank">
                            <span className="gray3 caption">Payments powered by Authorize.Net</span>
                        </a>
                    </div>
                </div>
            )
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "payment_method") {
            setSelectedPaymentMethod(value);
        }
    }

    let description = "";
    let actionLabel = "Subscribe";
    let productNameString = "";
    if (productNames) {
        productNameString = productNames.join(", ");
    }
    if (paymentLink.trial) {
        description = <div>
            <p className="text-sm gray4">Try { productNameString }</p>
            <p className="text-3xl font-semibold gray4 mb-2">{ formatTerm(paymentLink.trial_term) } free</p>
            <span className="text-sm gray4">Then Subscribe to { productNameString } for { formatTerm(paymentLink.term) } (Recurring)</span>
        </div>
        actionLabel = "Start trial"
    } else if (paymentLink.bundle_pricing) {
        description = <span className="body1 gray4">{ `Subscribe to ${ productNameString } for ${ formatTerm(paymentLink.term) } (Recurring)` }</span>
        actionLabel = "Subscribe";
    } else {
        // only one time billables
        description = `Purchase ${ productNameString }`;
        actionLabel = "Purchase";
    }

    const renderExpired = () => {
        let title = "Payment link expired"
        let description = "Oops, this contract has expired. Please reach out to the person who send you the contract for further details."
        if (paymentLink.type === "CHECKOUT_SESSION") {
            title = "Checkout session expired"
            description = "Oops, this checkout session has expired. Please reach out to the person who send you the contract for further details."
        }
        return (
            <ContentContainer className="full" bodyClassName="full">
                <div className="contract-wrapper">
                    <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                        <i className="fa fa-thin fa-shopping-cart danger-color fa-4x"/>
                        <h3 className="gray4">{ title }</h3>
                        <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>{ description }</div>
                    </div>
                </div>
            </ContentContainer>
        )
    }

    if (paymentLink.status === "EXPIRED") {
        return renderExpired();
    }

    let requiresPhone = paymentLink.options && paymentLink.options.requires_customer_phone;
    let requiresAddress = paymentLink.options && paymentLink.options.requires_customer_address;
    return (
        <ContentContainer className="full alt" bodyClassName="full">
            <div className="request-pay">
            {
                !_.isNil(redirectStatus) &&
                    <div className="flex flex-col justify-center items-center w-full h-full gap-3">
                        <div className="spinner-border text-secondary"/>
                        <span className="text-gray-700 text-base">Processing Payment...</span>
                    </div>
            }
            {
                !loadingPricing && !loadingSettings && _.isNil(redirectStatus) ?
                    <>
                    <div className="left-half flex flex-col">
                        <div className="p-8 overflow-visible max-h-full">
                            <div className="d-flex flex-row justify-content-end">
                                <div className="plan-details md:mb-6">
                                    <div className="d-flex flex-row gap-3 align-items-center">
                                        {
                                            brandingLogo ?
                                                <div className="brand-logo">
                                                    <img alt="Brand Logo" src={brandingLogo} />
                                                </div>
                                            : <i className="fa fa-cart-shopping" style={{ fontSize: "22px", color: brandColor }}/>
                                        }
                                        <h3 className="gray4">{ company.name }</h3>
                                    </div>
                                    <br/>
                                    {
                                        productNames &&
                                            <div>{ description }</div>
                                    }
                                    <br/>
                                    {
                                        pricingSummary &&
                                            <PricingBreakdown
                                                discountDetails={pricingSummary.discount_details}
                                                creditDetails={pricingSummary.credit_details}
                                                subscriptionDetails={pricingSummary.subscription_details}
                                                oneTimeDetails={pricingSummary.one_time_details}
                                                total={pricingSummary.total}
                                                expanded={true}
                                                simplified={true}
                                                isPreview={true} />
                                    }
                                    {
                                        acceptedCode &&
                                            <Container>
                                                <Row>
                                                <Col lg="12">
                                                    <div className="d-flex flex-row gap-3 justify-content-end align-items-center">
                                                        <span className="body2 gray3">Promo Code:&nbsp;</span>
                                                        <Label.Info>{ acceptedCode } <Button size="sm" variant="text" onClick={removePromoCode}><i className="fa fa-x"/></Button></Label.Info>
                                                    </div>
                                                </Col>
                                                </Row>
                                            </Container>
                                    }
                                    {
                                        !acceptedCode && showPromoCodeForm ?
                                            <BaseForm errorFields={promoErrorFields} onSubmit={(data) => updatePricing(data.code)}>
                                                <Container>
                                                <Row>
                                                <Col lg="12">
                                                    <div className="d-flex flex-row gap-3 justify-content-end">
                                                        <BaseForm.Input type="text" placeholder="Promo Code" name="code" formClassName="inline"/>
                                                        <SubmitButton color={brandColor}>Apply</SubmitButton>
                                                    </div>
                                                </Col>
                                                </Row>
                                                </Container>
                                            </BaseForm>
                                        : <div className="hide">
                                            <Button variant="text-primary" onClick={() => setShowPromoCodeForm(true)}>Apply Promo Code</Button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="d-none flex-row justify-content-end">
                                { renderFooterDetails() }
                            </div>
                        </div>
                    </div>
                    <div className="right-half flex flex-col">
                        <div className="p-8 overflow-visible max-h-full">
                            <div className="flex flex-col justify-center">
                                <div className="grow"/>
                                <div className="payment-details">
                                    <BaseForm
                                        onSubmit={onSubmit} initialFormFields={paymentLink} errorFields={errorFields}
                                        onFieldChange={onFieldChange}
                                    >
                                        <div className="body2">Contact Information</div>
                                        <br/>
                                        <Row>
                                            {
                                                _.isNil(paymentLink.customer) ?
                                                    <>
                                                        <BaseForm.Input type="text" name="name" label={t('common.name')}
                                                            placeholder={t('common.name')} required/>
                                                        <BaseForm.Input colSpan={"12"} type="text" name="email" label={t('common.email')}
                                                            placeholder={t('common.email')} transformations={["lowercase", "trim"]}
                                                            validations={{ required: true, validEmail: true }}/>
                                                        {
                                                            requiresPhone &&
                                                                <BaseForm.Input
                                                                    type="text" name="phone" label="Phone Number" required
                                                                    colSpan={"12"} placeholder={"+1 1234567890"}
                                                                />
                                                        }
                                                        {
                                                            requiresAddress &&
                                                            <BaseForm.Input
                                                                colSpan="12" type="simple_address" name="address" label="Address"
                                                                required
                                                            />
                                                        }
                                                    </>
                                                : <>
                                                    <BaseForm.Input type="text" name="customer.email" label={t('common.email')}
                                                                    placeholder={t('common.email')} disabled/>
                                                </>
                                            }
                                            {
                                                paymentLink.customer?.managed_externally ? <span
                                                    className="form-error-message">{`${paymentLink.customer.name} is managed externally, please contact support for more details.`}</span> :
                                                    <>
                                                        {
                                                            !_.isEmpty(paymentMethods) &&
                                                            <div>
                                                            <BaseForm.Input
                                                            type="select" name="payment_method" label="Select Payment"
                                                            options={paymentMethodOptions} showSearch={false}
                                                                />
                                                        </div>
                                                        }
                                                        {
                                                        showPaymentMethodInput &&
                                                        <div>
                                                            {
                                                                payment_config && _.includes(["STRIPE", "stripe"], payment_config.provider) &&
                                                                <div className="payment_input">
                                                                    <StripeSetupPaymentMethod ref={setupRef}
                                                                                              redirectUrl={window.location.href} paymentConfig={payment_config}
                                                                                              accessToken={customerToken}  setupUrl={getApiUrl(`/proposals/${uuid}/setup_intent`)} />
                                                                </div>
                                                            }
                                                            {
                                                                payment_config && _.includes(["AUTHORIZE_NET", "authorize_net"], payment_config.provider) &&
                                                                <AuthorizeNetPaymentInput ref={setupRef} {...props} />
                                                            }
                                                        </div>
                                                        }
                                                        {
                                                        askForCode &&
                                                        <>
                                                            <BaseForm.Input type="text" name="code" label={t('common.code')} placeholder={t('common.code')}
                                                                            description="We have sent a verification code to your email. Please enter the code here." required/>
                                                        </>
                                                        }
                                                    </>
                                            }
                                        </Row>
                                        <br/>
                                        {
                                            !paymentLink.customer?.managed_externally &&
                                                <SubmitButton variant="primary" className="btn-complete" color={brandColor} errorOnRight={true}>{actionLabel}</SubmitButton>
                                        }

                                    </BaseForm>
                                </div>
                                { renderFooterDetails() }
                                <div className="grow"/>
                            </div>
                        </div>
                    </div>
                    </>
                : <div/>
            }
            </div>
        </ContentContainer>
    )
}

export default PaymentLinkView;
