import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import {
    BaseContext,
    getDescriptionForMetric,
    renderDescriptionForItemPricing
} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MapleTable from "../MapleTable";
const _ = require('lodash');

function EditProductPricingModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [billableItems, setBillableItems] = useState([]);

    const [initialFields, setInitialFields] = useState(null);

    useEffect(() => {
        setBillableItems(props.billableItems);
    }, [props.billableItems]);

    useEffect(() => {
        if (props.show) {
            serverPost(getApiUrl("/billable/items/find"), { companyId: company.id }).then((res) => {
                if (res) {
                    setBillableItems(res);
                }
            });
        }
    }, [props.show, company, getApiUrl])

    useEffect(() => {
        if (!billableItems) {
            return;
        }
    }, [billableItems])

    const onFieldChange = (key, value) => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields[key] = value;
            return newFields
        })
    }

    if (!props.show) {
        return;
    }

    const getDescriptionForType = (type) => {
        if (type === "SUBS_PRICING") {
            return "Recurring";
        } else if (type === "ONETIME_PRICING" || type === "ONETIME_PRICING_WITH_CREDITS") {
            return "One-time";
        } else {
            return type;
        }
    }

    const renderProductMetric = (pmp) => {
        if (pmp.item.type === "ONETIME_ITEM") {
            return <span>One Time</span>;
        } else if (pmp.item.type === "LICENSE_ITEM") {
            return <span>Number of Licenses</span>;
        } else {
            return (
                <>
                    <span>{ pmp.metric.name }</span>
                    <span className="caption">{ getDescriptionForMetric(pmp.metric) }</span>
                </>
            )
        }
    }

    return (
        <>
            <BaseAddOrEditItemModal
                size="lg"
                {...props}
                initialFormFields={initialFields}
                onFieldChange={onFieldChange}
                itemLabel={t('billable_product_pricing.pricing')}
                itemBaseUrl="/product_pricings"
            >
                <Row>
                    <BaseForm.Input colSpan="6" name="name" label={t('common.name')} type="text" required />
                    <BaseForm.Input colSpan="6" name="description" label={t('common.description')} type="text" />
                    <BaseForm.Input colSpan="12" name="external_name" label={"Public Name (Optional)"} type="text"
                                    description="This will display in communications to your customers, if different from the name."/>
                </Row>
                <Row className="mt-2">
                    <Col md="12">
                        <p className="body2">Price Details</p>
                    </Col>
                </Row>
                <p>
                    <span className="body1">Type:</span> { getDescriptionForType(props.itemToEdit.type) }
                </p>
                <MapleTable>
                    <MapleTable.Content>
                        <thead>
                        <tr>
                            <MapleTable.TH>Pricing</MapleTable.TH>
                            <MapleTable.TH>Metric/Quantity</MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(props.itemToEdit.product_metric_pricings, (pmp, i) =>
                                <tr key={i}>
                                    <td>{ renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, billableItems) }</td>
                                    <td>{ renderProductMetric(pmp, null) }</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
                <span className="body1 italic warning-color">Note: You can't edit the pricing details once created. If you don't want this pricing to be available any more, you can archive it and create a new product pricing.</span>
            </BaseAddOrEditItemModal>
        </>
    );
}

export default EditProductPricingModal;
