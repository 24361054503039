import '../App.scss';
import '../css/modals.scss';
import React, {createRef, useState, useEffect, useContext, forwardRef, useImperativeHandle} from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import {
    BaseContext, currencyFormatFromPrice,
    getCurrencyOptions,
    getDescriptionForMetric,
    getLabelForSubscriptionLength,
    renderDescriptionForItemPricing,
    updateFormField,
} from '../helpers/common';
import {serverPost} from '../helpers/server';
import {Row, Col, Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from "react-select/async";
import AddOrEditBillableItemPricingModal from "./modals/AddOrEditBillableItemPricingModal";
import ItemPricingFields from "./ItemPricingFields";
import SubmitButton from "./SubmitButton";
import AddOrEditBillableMetricModal from "./modals/AddOrEditBillableMetricModal";
import Link from "./Link";
import AddOrEditBillableItemModal from "./modals/AddOrEditBillableItemModal";
const _ = require('lodash');

const BaseProductPricingCreateOrUpdate = forwardRef((props, ref)  => {
    const formRef = createRef();
    useImperativeHandle(ref, () => ({
        submitForm() {
            if (formRef.current) {
                formRef.current.submitForm();
            }
        },
    }));

    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);

    const [showAddOrEditBillableItemPricingModal, setShowAddOrEditBillableItemPricingModal] = useState(false);
    const [showAddOrEditBillableMetricModal, setShowAddOrEditBillableMetricModal] = useState(false);
    const [showAddOrEditBillableItemModal, setShowAddOrEditBillableItemModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [billableItems, setBillableItems] = useState([]);
    const [billableMetrics, setBillableMetrics] = useState([]);
    const [originalBillableItemPricings, setOriginalBillableItemPricings] = useState([]);
    const [billableItemPricings, setBillableItemPricings] = useState([]);
    const [loadingItemPricings, setLoadingItemPricings] = useState(true);
    const [onetimeItem, setOnetimeItem] = useState(null);
    const [licenseItem, setLicenseItem] = useState(null);
    const [onetimeMetric, setOnetimeMetric] = useState(null);
    const [licenseMetric, setLicenseMetric] = useState(null);

    const [pricingType, setPricingType] = useState(props.allowOnetimeOnly ? "ONETIME_PRICING": "SUBS_PRICING");
    const [recurringType, setRecurringType] = useState("SINGLE");
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [selectedTermCount, setSelectedTermCount] = useState(12);
    const [selectedMetricPricings, setSelectedMetricPricings] = useState([]);
    const [metricTypes, setMetricTypes] = useState({});
    const [error, setError] = useState(null);
    const [initialFields, setInitialFields] = useState({
        pp_type: pricingType,
        currency: company.preferred_currency,
        credit_type: "UNITS",
        term_count: 12
    });

    const [creditType, setCreditType] = useState("AMOUNT");

    const fieldsRef = createRef();

    useEffect(() => {
        if (props.pricingToDuplicate && !_.isEmpty(props.pricingToDuplicate)) {
            const ifields = {
                currency: company.preferred_currency,
                credit_type: "UNITS",
                name: props.pricingToDuplicate.name,
                description: props.pricingToDuplicate.description,
                external_name: props.pricingToDuplicate.external_name,
                type: props.pricingToDuplicate.type,
            }
            if (props.pricingToDuplicate.type === "ONETIME_PRICING") {
                const pmp = props.pricingToDuplicate.product_metric_pricings[0];
                if (pmp.item_pricing) {
                    ifields.onetime_price = pmp.item_pricing.fixed_price.price_per_unit/100;
                    ifields.currency = pmp.item_pricing.base_price.currency;
                }
                if (pmp.item_pricing.credit_price) {
                    const cp = pmp.item_pricing.credit_price;
                    setCreditType(cp.type);
                    ifields.credit_type = cp.type;
                    ifields.credit_value = cp.amount.value_in_cents/100;
                    ifields.credit_currency = cp.amount.currency;
                    ifields.credit_units = cp.units;
                    ifields.credit_item_id = cp.item_id;
                    ifields.type = "ONETIME_PRICING_WITH_CREDITS";
                }
            } else {
                ifields.product_metric_pricings = _.map(props.pricingToDuplicate.product_metric_pricings, (pmp) => {
                    return { metric_id: pmp.metric_id };
                })
                setSelectedMetricPricings(_.map(props.pricingToDuplicate.product_metric_pricings, (pmp, i) => {
                    return {
                        item_pricing: pmp.item_pricing,
                        item: pmp.item,
                    }
                }))
            }
            setPricingType(ifields.type);
            setInitialFields(ifields);
        }
    }, [props.pricingToDuplicate])

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), { companyId: company.id }).then((res) => {
            if (res) {
                setOnetimeItem(_.find(res, (r) => r.type === "ONETIME_ITEM"));
                setLicenseItem(_.find(res, (r) => r.type === "LICENSE_ITEM"));
                setBillableItems(res);
            }
        });

        fetchBillableMetrics();

        serverPost(getApiUrl("/item_pricings/find"), { companyId: company.id }).then((res) => {
            setOriginalBillableItemPricings(res || []);
        });
    }, [])

    const fetchBillableMetrics = (skipCache = false) => {
        serverPost(getApiUrl("/billable/metrics/find"), { skipCache }).then((res) => {
            if (res) {
                setOnetimeMetric(_.find(res, (r) => r.type === "ONETIME_METRIC"))
                setLicenseMetric(_.find(res, (r) => r.type === "LICENSE_METRIC"))
                setBillableMetrics(_.filter(res, (r) => !r.standard));
            }
        });
    }

    const fetchProducts = (skipCache=false) => {
        const pParams = {
            company_id: company.id,
            pagination: {
                limit: 100
            },
            query: {
                state: "ACTIVE"
            }
        }
        serverPost(getApiUrl("/products/find"), pParams,{ skipCache }).then((res) => {
            if (res) {
                const results = res.results || [];
                setProducts(results);
            }
        });
    }

    useEffect(() => {
        if (props.selectProduct) {
            fetchProducts(true);
        }
    }, [props.selectProduct]);

    useEffect(() => {
        setError(null);
    }, [selectedMetricPricings]);

    useEffect(() => {
        if (!billableItems) {
            return;
        }
        _.each(originalBillableItemPricings, (ip) => {
            ip.item = _.find(billableItems, (i) => i.id === ip.item_id);
        })
        setBillableItemPricings(originalBillableItemPricings);
        // if (originalBillableItemPricings.length > 0) {
        setLoadingItemPricings(false);
        // }
    }, [billableItems, originalBillableItemPricings])

    const onDeleteRow = (index) => {
        setSelectedMetricPricings(prevSelectedMetricPricings => {
            const newSelectedMetricPricings = [...prevSelectedMetricPricings];
            newSelectedMetricPricings.splice(index, 1)
            return newSelectedMetricPricings;
        });
    }

    const onFieldChange = (key, value) => {
        setError(null);
        if (key === "recurring_type") {
            setRecurringType(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else if (key.endsWith("metric_id")) {
            setMetricTypes(prevTypes => {
                const newTypes = {...prevTypes};
                newTypes[key] = value
                return newTypes;
            })
            setInitialFields(prevFields => {
                return updateFormField({...prevFields}, key, value);
            })
        } else if (key === "pp_type") {
            setPricingType(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else if (key === "credit_type") {
            setCreditType(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else if (_.includes(["name", "description", "external_name", "onetime_price", "currency", "credit_value", "credit_currency", "credit_units", "credit_item_id"], key)) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else if (key === "frequency") {
            setSelectedFrequency(value);
            setInitialFields(prevFields => {
                return updateFormField({...prevFields}, key, value)
            })
        } else if (key === "term_count") {
            setSelectedTermCount(value);
            setInitialFields(prevFields => {
                return updateFormField({...prevFields}, key, value)
            })
        } else if (key.startsWith("scheduled_price.price_per_unit_per_period")) {
            setInitialFields(prevFields => {
                return updateFormField({...prevFields}, key, value)
            })
        } else {
            setInitialFields(prevFields => {
                return updateFormField({...prevFields}, key, value)
            })
        }
        if (fieldsRef.current) {
            fieldsRef.current.onFieldChange(key, value);
        }
    }

    const handleError = async (error) => {
        const message = await error.json();
        setError(message.error_message || message.error)
    }

    const addItem = async (itemFields, errorHandler) => {
        setError(null);
        if (!itemFields.product_id) {
            itemFields.product_id = props.productID;
        }
        if (itemFields.pp_type === "SCHEDULED_PRICING") {
            const scheduledPriceData = {
                item_id: licenseItem.id,
                name: itemFields.name,
                description: itemFields.description,
                frequency: itemFields.frequency,
                term_count: itemFields.term_count,
                base_price: {
                    value_in_cents: 0,
                    currency: itemFields.currency
                },
                type: "SCHEDULED",
                proration_type: "NONE",
                charge_type: "PRE_POST",
                scheduled_price: {
                    price_per_unit_per_period: _.map(_.values(itemFields.scheduled_price.price_per_unit_per_period), v => {
                        return parseFloat(v)*100;
                    })
                }
            }
            // return;
            const scheduledPricing = await serverPost(getApiUrl("/item_pricings"), scheduledPriceData);
            if (!scheduledPricing) {
                setError("Unable to create pricing.")
                return;
            }

            const productPricingData = {
                product_id: itemFields.product_id,
                name: itemFields.name,
                external_name: itemFields.external_name,
                description: itemFields.description,
                type: "SUBS_PRICING",
                currency: itemFields.currency,
                product_metric_pricings: [
                    { item_id: licenseItem.id, metric_id: licenseMetric && licenseMetric.id, item_pricing_id: scheduledPricing.id }
                ]
            }
            const pricingResult = await serverPost(getApiUrl('/product_pricings'), productPricingData, {}, handleError)
            if (pricingResult) {
                if (props.onProductPricingCreated) {
                    props.onProductPricingCreated(pricingResult)
                }
            }
        } else if (itemFields.pp_type === "ONETIME_PRICING" || itemFields.pp_type === "ONETIME_PRICING_WITH_CREDITS") {
            let creditPrice = null;
            if (itemFields.pp_type === "ONETIME_PRICING_WITH_CREDITS") {
                creditPrice = {
                    type: itemFields.credit_type,
                    amount: {
                        value_in_cents: parseFloat(itemFields.credit_value) * 100,
                        currency: itemFields.credit_currency
                    },
                    units: itemFields.credit_units,
                    item_id: itemFields.credit_item_id
                }
            }
            const onetimeData = {
                item_id: onetimeItem.id,
                name: itemFields.name,
                description: itemFields.description,
                frequency: "MONTH",
                base_price: {
                    value_in_cents: 0,
                    currency: itemFields.currency
                },
                type: "FIXED",
                proration_type: "NONE",
                charge_type: "PRE_POST",
                fixed_price: { price_per_unit: parseFloat(itemFields.onetime_price) * 100 },
                credit_price: creditPrice
            }
            const onetimePricing = await serverPost(getApiUrl("/item_pricings"), onetimeData);
            if (!onetimePricing) {
                setError("Unable to create pricing.")
                return;
            }

            const productPricingData = {
                product_id: itemFields.product_id,
                name: itemFields.name,
                external_name: itemFields.external_name,
                description: itemFields.description,
                type: "ONETIME_PRICING",
                currency: itemFields.currency,
                product_metric_pricings: [
                    { item_id: onetimeItem.id, metric_id: onetimeMetric && onetimeMetric.id, item_pricing_id: onetimePricing.id }
                ]
            }
            const pricingResult = await serverPost(getApiUrl('/product_pricings'), productPricingData, {}, handleError)
            if (pricingResult) {
                if (props.onProductPricingCreated) {
                    props.onProductPricingCreated(pricingResult)
                }
            }
        } else if (recurringType === "MULTI") {
            if (selectedMetricPricings.length === 0) {
                setError("Please select at least one price");
                return
            }
            const currencies = _.map(selectedMetricPricings, (mp) => {
                return mp.item_pricing.base_price.currency
            })
            const uniqueCurrencies = _.uniq(currencies);
            if (uniqueCurrencies.length > 1) {
                setError("All prices should be of the same currency");
                return
            }

            itemFields.type = itemFields.pp_type;
            itemFields.product_metric_pricings = _.map(selectedMetricPricings, (mp, i) => {
                let metric_id = null;
                if (mp.item.type === "LICENSE_ITEM") {
                    metric_id = licenseMetric && licenseMetric.id;
                } else if (mp.item.type === "ONETIME_ITEM") {
                    metric_id = onetimeMetric && onetimeMetric.id;
                } else if (_.has(itemFields.product_metric_pricings, String(i))) {
                    metric_id = itemFields.product_metric_pricings[String(i)].metric_id;
                }
                return {
                    item_id: mp.item_pricing.item_id,
                    metric_id: metric_id,
                    item_pricing_id: mp.item_pricing.id,
                    currency: mp.item_pricing.base_price.currency
                }
            })
            const allGood = _.every(itemFields.product_metric_pricings, (pmp) => !_.isNil(pmp.metric_id))
            if (!allGood) {
                setError("Please select a valid metric for the price.");
                return
            }

            itemFields.currency = itemFields.product_metric_pricings[0].currency;
            const pricingResult = await serverPost(getApiUrl('/product_pricings'), itemFields, {}, handleError)
            if (pricingResult) {
                if (props.onProductPricingCreated) {
                    props.onProductPricingCreated(pricingResult)
                }
            }
        } else {
            let newFields = null;
            if (itemFields.type === "GRADIENT") {
                itemFields.gradient_price = initialFields.gradient_price;
            }
            if (itemFields.type === "VOLUME") {
                itemFields.volume_price = initialFields.volume_price;
            }
            if (fieldsRef.current) {
                newFields = fieldsRef.current.processFields(itemFields);
            } else {
                newFields = {...itemFields};
            }

            // const itemPricingResult = const addItemUrl = getApiUrl(props.itemBaseUrl);
            const itemPricingResult = await serverPost(getApiUrl("/item_pricings"), newFields, {}, errorHandler)
            if (!itemPricingResult) {
                console.log("Error creating item pricing")
                return;
            }

            let metric_id = itemFields.metric_id;
            if (!metric_id) {
                // If metric id is not provided, then it is a license metric
                metric_id = licenseMetric && licenseMetric.id
            }
            const productPricingData = {
                product_id: itemFields.product_id,
                name: itemFields.name,
                external_name: itemFields.external_name,
                description: itemFields.description,
                type: "SUBS_PRICING",
                currency: newFields.base_price.currency,
                product_metric_pricings: [
                    { item_id: itemPricingResult.item_id, metric_id: metric_id, item_pricing_id: itemPricingResult.id }
                ]
            }
            const pricingResult = await serverPost(getApiUrl('/product_pricings'), productPricingData, {}, handleError)
            if (pricingResult) {
                if (props.onProductPricingCreated) {
                    props.onProductPricingCreated(pricingResult)
                }
            }
        }
    }

    const loadItemPrices = (query, callback) => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            search_query: query,
            query: {
                ...props.extraQueryFilters,
                is_recurring: pricingType === "SUBS_PRICING"
            },
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/item_pricings/autocomplete"), params).then(async (res) => {
            if (res) {
                const results = res.results || [];
                results.unshift({ value: "new", label: <div><span className="text-sm font-semibold">Create New Item Pricing</span></div> })
                callback(results);
            }
        });
    }

    const pricingLabel = (pricing) => {
        if (pricing.value === "new") {
            return pricing.label
        } else {
            return renderDescriptionForItemPricing(pricing, true, _.find(billableItems, (b) => b.id === pricing.item_id))
        }
    }

    const getMetricOptionsForItemPricing = (itemPricing) => {
        return _.map(_.filter(billableMetrics, (m) => m.item_id === itemPricing.item_id), (bm) => {
            return { value: bm.id, label: bm.name }
        })
    }

    const creditTypeOptions = [
        { value: "AMOUNT", label: "Amount" },
        { value: "UNITS", label: "Units" },
    ]

    const pricingTypeOptions = [
        !props.allowOnetimeOnly && { value: "SUBS_PRICING", label: "Recurring" },
        { value: "ONETIME_PRICING", label: "One-time" },
        { value: "ONETIME_PRICING_WITH_CREDITS", label: "One-time (credits)" },
        !props.allowOnetimeOnly && { value: "SCHEDULED_PRICING", label: "Custom Pricing" },
    ]

    const periodOptions = [
        { value: "WEEK", label: "Weekly" },
        { value: "MONTH", label: "Monthly" },
        { value: "QUARTER", label: "Quarterly" },
        { value: "BI_ANNUAL", label: "Bi-Annually" },
        { value: "YEAR", label: "Yearly" }
    ]

    const recurringTypeOptions = [
        { value: "SINGLE", label: "Simple Pricing" },
        { value: "MULTI", label: "Multi Item Pricing" },
    ]

    const billableItemOptions = _.map(_.filter(billableItems, (r) => !r.standard), (bi) => {
        return { value: bi.id, label: bi.name }
    })

    const productOptions = _.map(products, (p) => {
        return { value: p.id, label: p.name }
    })

    const getDescriptionForType = (type) => {
        if (type === "SUBS_PRICING") {
            return "Recurring";
        } else if (type === "ONETIME_PRICING" || type === "ONETIME_PRICING_WITH_CREDITS") {
            return "One-time";
        } else {
            return type;
        }
    }

    const onModalClose = () => {
        setShowAddOrEditBillableItemPricingModal(false);
        setShowAddOrEditBillableMetricModal(false);
        setShowAddOrEditBillableItemModal(false);
    }

    const onBillableMetricAdded = (metric) => {
        // Re-fetch the billable metrics on any new metric addition
        if (fieldsRef.current) {
            fieldsRef.current.refreshBillableMetrics();
        } else {
            fetchBillableMetrics(true);
        }
    }

    const onBillableItemAdded = (item) => {
        serverPost(getApiUrl("/billable/items/find"), { companyId: company.id }).then((res) => {
            if (res) {
                setOnetimeItem(_.find(res, (r) => r.type === "ONETIME_ITEM"));
                setBillableItems(res);
            }
        });
        // Re-fetch the billable metrics on any new metric addition
        if (fieldsRef.current) {
            fieldsRef.current.refreshBillableItems();
        }
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.item_id = item.id;
            return newFields;
        })
    }

    const onItemPricingCreated = (itemPricing) => {
        itemPricing.item = _.find(billableItems, (i) => i.id === itemPricing.item_id);
        setSelectedMetricPricings(prevSelectedMetricPricings => {
            const newSelectedMetricPricings = [...prevSelectedMetricPricings];
            newSelectedMetricPricings.push({
                item_pricing: itemPricing,
                item: itemPricing.item,
            });
            return newSelectedMetricPricings;
        })
    }

    const renderProductMetric = (pmp) => {
        if (pmp.item.type === "ONETIME_ITEM") {
            return <span>One Time</span>;
        } else if (pmp.item.type === "LICENSE_ITEM") {
            return <span>Number of Licenses</span>;
        } else {
            return (
                <>
                    <span>{ pmp.metric.name }</span>
                    <span className="caption">{ getDescriptionForMetric(pmp.metric) }</span>
                </>
            )
        }
    }

    const getScheduledPriceTotal = (scheduledPrice) => {
        if (scheduledPrice && scheduledPrice.price_per_unit_per_period) {
            const values = _.values(scheduledPrice.price_per_unit_per_period);
            const sum = _.sumBy(values, v => parseFloat(v)*100);
            return sum
        }
        return 0
    }

    return (
        <>
            <BaseForm ref={formRef} initialFormFields={initialFields} onSubmit={addItem} onFieldChange={onFieldChange}>
                {
                    props.selectProduct &&
                        <Row>
                            <BaseForm.Input colSpan="6" name="product_id" label="Product" type="select" options={productOptions} showSearch={productOptions.length > 5} required />
                        </Row>
                }
                <Row>
                    <BaseForm.Input colSpan="6" name="name" label={t('common.name')} type="text" required />
                    <BaseForm.Input colSpan="6" name="description" label={t('common.description')} type="text" />
                    <BaseForm.Input colSpan="12" name="external_name" label={"Public Name (Optional)"} type="text"
                                    description="This will display in communications to your customers, if different from the name."/>
                    <BaseForm.Input colSpan="12" name="pp_type" label="Frequency" type="box-select"
                                    options={pricingTypeOptions} showSearch={false} />
                </Row>
                {
                    pricingType === "SUBS_PRICING" &&
                        <Row>
                            <BaseForm.Input
                                colSpan="6" name="recurring_type" label="Pricing Type" type="box-select"
                                options={recurringTypeOptions} showSearch={false}
                            />
                        </Row>
                }
                {
                    pricingType === "ONETIME_PRICING" &&
                        <Row>
                            <BaseForm.Input colSpan="4" name="onetime_price" label={t('billable_item_pricing.price')} type="number" step="0.01" min="0" required/>
                            <BaseForm.Input colSpan="4" name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} showSearch={false} required/>
                        </Row>
                }
                {
                    pricingType === "ONETIME_PRICING_WITH_CREDITS" &&
                        <>
                        <Row>
                            <BaseForm.Input colSpan="4" name="onetime_price" label={t('billable_item_pricing.price')} type="number" step="0.01" min="0" required />
                            <BaseForm.Input colSpan="4" name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} showSearch={false} required />
                        </Row>
                        <p className="mt-2 font-semibold text-sm">Credit Details</p>
                        <span className="text-sm">Enter the details about the credits. These credits will be applied to the customer when purchased.</span>
                        <Row className="mt-2">
                            <BaseForm.Input colSpan="4" name="credit_type" label={t('credits.type')} type="select" options={creditTypeOptions} showSearch={false} />
                            {
                                creditType === "AMOUNT" &&
                                <>
                                    <BaseForm.Input colSpan="4" name="credit_value" label={t('credits.amount')} type="number" step="0.01" min="0.01" required />
                                    <BaseForm.Input colSpan="4" name="credit_currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required />
                                </>
                            }
                             {creditType === "UNITS" && (
                                <>
                                    <BaseForm.Input
                                        colSpan="4"
                                        name="credit_units"
                                        label={t("credits.units")}
                                        type="number"
                                        step="0.01"
                                        required
                                        disabled={!billableItemOptions.length}
                                        min="0.01"
                                    />
                                    <BaseForm.Input
                                        colSpan="4"
                                        name="credit_item_id"
                                        label={t("credits.item")}
                                        type="select"
                                        options={billableItemOptions}
                                        required
                                        showSearch={false}
                                        disabled={!billableItemOptions.length}
                                    />
                                    {!billableItemOptions.length && (
                                        <span className="body1 error">
                                            In order to create a pricing with credit type units, we need to
                                            create a billable item first.{" "}
                                            <Link onClick={() => setShowAddOrEditBillableItemModal(true)}>
                                                Get started here.
                                            </Link>
                                        </span>
                                    )}
                                </>
                            )}
                        </Row>
                        </>
                }
                {
                    pricingType === "SUBS_PRICING" && !loadingItemPricings && recurringType === "SINGLE" &&
                        <>
                            <ItemPricingFields
                                ref={fieldsRef} setInitialFields={setInitialFields} initialFields={initialFields}
                                itemToEdit={props.pricingToEdit} onCreateBillableItem={() => setShowAddOrEditBillableItemModal(true)}
                                onCreateBillableMetric={() => setShowAddOrEditBillableMetricModal(true)}
                                shouldSelectMetric
                            />
                        </>
                }
                {
                    pricingType === "SUBS_PRICING" && !loadingItemPricings && recurringType !== "SINGLE" &&
                    <>
                        {
                            _.map(selectedMetricPricings, (mp, i) =>
                                    <Row key={i} className="metric-pricing-row">
                                        <Col lg="6" className="d-flex align-self-center">
                                            <span className="body1">
                                            {
                                                renderDescriptionForItemPricing(mp.item_pricing, true, mp.item)
                                            }
                                            </span>
                                        </Col>
                                        <Col lg="5" className="align-self-center">
                                            {
                                                mp.item.type === "ONETIME_ITEM" &&
                                                <>
                                                    <span className="body2">Metric</span><br/>
                                                    <span className="body1">One Time</span>
                                                </>
                                            }
                                            {
                                                mp.item.type === "LICENSE_ITEM" &&
                                                <>
                                                    <span className="body2">Metric</span><br/>
                                                    <span className="body1">Number of Licenses</span>
                                                </>
                                            }
                                            {
                                                mp.item.type !== "ONETIME_ITEM" && mp.item.type !== "LICENSE_ITEM" &&
                                                <Row>
                                                    {
                                                        _.isEmpty(getMetricOptionsForItemPricing(mp.item_pricing)) ?
                                                            <span className="d-flex flex-row gap-2 align-items-center">
                                                                <i className="fa fa-triangle-exclamation warning-color fa-1-5x"/>
                                                                <span
                                                                    className="body1">No metrics present for this item.&nbsp;
                                                                    <Link onClick={() => setShowAddOrEditBillableMetricModal(true)}>Create one</Link>
                                                                </span>
                                                            </span>
                                                            : <BaseForm.Input
                                                                type="select"
                                                                colSpan={metricTypes[`${i}.metric_id`] === "FIXED" ? "6" : "12"}
                                                                label="Metric" name={`product_metric_pricings.${i}.metric_id`}
                                                                showSearch={false}
                                                                options={getMetricOptionsForItemPricing(mp.item_pricing)}/>
                                                    }
                                                    {
                                                        metricTypes[`product_metric_pricings.${i}.metric_id`] === "FIXED" &&
                                                        <BaseForm.Input type="number" colSpan="6" label="Quantity"
                                                                        name={`product_metric_pricings.${i}.count`}
                                                                        required/>
                                                    }
                                                </Row>
                                            }
                                        </Col>
                                        <Col lg="1"
                                             className="d-flex flex-column align-items-center justify-content-center">
                                            <DeleteButton onDelete={() => onDeleteRow(i)}/>
                                        </Col>
                                    </Row>
                            )
                        }
                        <br/>
                        <Row>
                            <Col lg="6">
                                <div className="form-group">
                                    <span className="body2">Add an item pricing</span>
                                    <div className="form-input">
                                        <Select
                                            menuPlacement="auto"
                                            components={{ DropdownIndicator: null }}
                                            isMulti={false}
                                            className="select-container"
                                            loadOptions={loadItemPrices}
                                            defaultOptions
                                            classNamePrefix="select2"
                                            getOptionLabel={pricingLabel}
                                            getOptionValue={(c) => c.id}
                                            value={null}
                                            placeholder="Add an item pricing..."
                                            onChange={(value, meta) => {
                                                if (value.value === "new") {
                                                    setShowAddOrEditBillableItemPricingModal(true);
                                                } else {
                                                    setSelectedMetricPricings(prevSelectedMetricPricings => {
                                                        const newSelectedMetricPricings = [...prevSelectedMetricPricings];
                                                        newSelectedMetricPricings.push({
                                                            item_pricing: value,
                                                            item: value.item,
                                                        });
                                                        return newSelectedMetricPricings;
                                                    })
                                                }
                                            }}
                                            isClearable={false}
                                            noOptionsMessage={(inputValue) => { return `Start typing...` }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
                {
                    pricingType === "SCHEDULED_PRICING" &&
                    <>
                        <Row>
                            <BaseForm.Input colSpan="4" name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} showSearch={false} required/>
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="4" name="frequency" label={"Frequency"} type="select" options={periodOptions} showSearch={false} />
                            <BaseForm.Input colSpan="4" name="term_count" label={"Number of periods"} type="number" />
                        </Row>
                        <p className="text-sm font-semibold">Payment Schedule</p>
                        <Row>
                            <Col md="5">
                                <Table borderless className="no-background">
                                    <tbody>
                                    {
                                        _.map(_.range(parseInt(selectedTermCount)+1), (c, j) =>
                                            <tr key={j}>
                                                <td className="text-sm text-gray-500 !p-1">
                                                    {
                                                        j === 0 ?
                                                            <span className="text-sm text-gray-500">Initial</span>
                                                            : j === parseInt(selectedTermCount) ?
                                                                <span className="text-sm text-gray-500">End of term</span>
                                                            : <span className="text-sm text-gray-500">{_.startCase(getLabelForSubscriptionLength(selectedFrequency))} {j}</span>
                                                    }
                                                </td>
                                                <td className="!p-1">
                                                    <BaseForm.Input
                                                        colSpan="12" name={`scheduled_price.price_per_unit_per_period.${j}`}
                                                        label={"Period"} type="number" hideLabel placeholder={`Period number` ? `${j}` : null}
                                                        required formClassName="inline" outerInputClassName="grow"
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td className="!p-1">
                                            <span className="text-sm text-gray-700 font-semibold">Total</span>
                                        </td>
                                        <td className="!p-1">
                                            <span className="text-sm text-gray-700 font-semibold">
                                                { currencyFormatFromPrice({value_in_cents: getScheduledPriceTotal(initialFields.scheduled_price), currency: initialFields.currency })}
                                            </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
                {
                    error &&
                        <p className="form-error-message">{error}</p>
                }
                {
                    !props.hideCreateButton &&
                        <SubmitButton className="mt-4" errorOnRight>Create</SubmitButton>
                }
            </BaseForm>
            <AddOrEditBillableItemPricingModal
                show={showAddOrEditBillableItemPricingModal}
                formClassName={"ml-4"}
                onClose={onModalClose}
                billableItems={billableItems}
                onAdded={onItemPricingCreated}
                onCreateBillableItem={props.onCreateBillableItem}
            />
            <AddOrEditBillableMetricModal
                show={showAddOrEditBillableMetricModal}
                onClose={onModalClose}
                billableItems={_.filter(billableItems, i => !i.standard)}
                onAdded={onBillableMetricAdded}
            />
            <AddOrEditBillableItemModal
                show={showAddOrEditBillableItemModal}
                onClose={onModalClose}
                onAdded={onBillableItemAdded}
            />
        </>
    );
})

export default BaseProductPricingCreateOrUpdate;
