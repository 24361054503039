import '../../App.scss';
import '../../css/modals.scss';
import React, {useState, useEffect, useContext, createRef} from 'react';
import BaseModal from './BaseModal';
import StripeInvoicePaymentModal from './StripeInvoicePaymentModal';
import AuthorizeNetInvoicePaymentModal from './AuthorizeNetInvoicePaymentModal';
import { useTranslation } from 'react-i18next';
import {serverPost} from "../../helpers/server";
import {BaseContext} from "../../helpers/common";
import RazorPayInvoicePaymentModal from "./RazorPayInvoicePaymentModal";
const _ = require('lodash');

function InvoicePaymentModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [paymentConfig, setPaymentConfig] = useState({});
    const paymentRef = createRef();

    useEffect(() => {
        setPaymentConfig(props.paymentConfig);
    }, [props.paymentConfig])

    const onError = async (res) => {
        const errorMessage = await res.json();
        if (paymentRef.current) {
            paymentRef.current.setErrorFields({
                error: errorMessage.error_message
            })
        }
    };

    const onPay = async (paymentMethodData, paymentAmountInCents) => {
        const paymentData = {
            payment_data: paymentMethodData
        }
        if (paymentAmountInCents) {
            paymentData.amount = {
                value_in_cents: paymentAmountInCents,
                currency: props.invoice.due.currency
            }
        }
        const payResult = await serverPost(getApiUrl(`/invoices/${props.invoice.uuid}/pay`), paymentData, {}, onError);
        if (payResult) {
            if (props.onClose) {
                props.onClose(true);
            }
        }
    }

    if (paymentConfig && _.includes(["STRIPE", "stripe"], paymentConfig.provider)) {
        return <StripeInvoicePaymentModal ref={paymentRef} {...props} onPay={onPay} />
    } else if (paymentConfig && _.includes(["AUTHORIZE_NET", "authorize_net"], paymentConfig.provider)) {
        return <AuthorizeNetInvoicePaymentModal ref={paymentRef} {...props} onPay={onPay}/>
    } else if (paymentConfig && _.includes(["RAZORPAY", "razorpay"], paymentConfig.provider)) {
        return <RazorPayInvoicePaymentModal ref={paymentRef} {...props} onPay={onPay} />
    } else {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('common.edit')} Provider not supported</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    The provider { paymentConfig && paymentConfig.provider } is not supported. Please contact your company admin.
                </BaseModal.Body>
                <BaseModal.Footer>
                </BaseModal.Footer>
            </BaseModal>
        )
    }
}

export default InvoicePaymentModal;
