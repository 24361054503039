import '../App.scss';
import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import 'daterangepicker/daterangepicker';
import JoditEditor from "jodit-pro-react";
import PropTypes from 'prop-types';
import React, { useContext, createContext, useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';
import { getValueFromEvent } from '../helpers/input';
import {
    validateURL,
    validateEmail,
    escapeSelector,
    BaseContext,
    getAddressFromGooglePlace,
    getFormattedAddressForAddress,
    getColorOptions,
    convertHtmlIntoVariables,
    convertVariablesIntoHtml,
    debounceAwaitFunction
} from '../helpers/common';
import { serverPost, serverPut } from '../helpers/server';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { SketchPicker } from 'react-color';
import Select from 'react-select/async'
import CreatableSelect from 'react-select/async-creatable'
import classnames from 'classnames';
import RuleDisplay from './Rule';
import MultiSelectDropdown from './MultiSelectDropdown';
import SingleSelectDropdown from './SingleSelectDropdown';
import EditorVariablesModal from "./EditorVariablesModal";
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditor from "react-froala-wysiwyg";
import BaseFroalaEditor from 'froala-editor';
import classNames from 'classnames';

const _ = require("lodash");

export const BaseFormContext = createContext();

const getValue = (formFields, name, defaultValue) => {
    const parts = name.split(".");
    let value = formFields;
    parts.forEach(part => {
        if (!_.isNil(value)) {
            value = value[part];
        }
    })
    if (!_.isNil(value) && !_.isNaN(value)) {
        return value;
    } else {
        return defaultValue;
    }
}

const getValidationMessage = (key, params) => {
    const validationMessages = {
        required: `${params.label} is required`,
        validEmail: `Email address must be valid`,
        validURL: `URL must be valid`,
        slugCheck: `Slug already exists. Please select a different slug.`,
        adminEmailCheck: `Email already exists. Please contact support to make this email an admin.`,
        userEmailCheck: `Email already exists.`,
        validEmails: `One or more of the email addresses is not valid`,
        minLength: `${params.label} must be at least ${params.value} characters long`,
        min: `${params.label} must be at least ${params.value}`,
        max: `${params.label} must be at most ${params.value}`,
        gt: `${params.label} must be greater than ${params.value}`,
        assigned: `${params.label} must be assigned`,
        isChecked: `${params.label} must be checked`
    }
    return validationMessages[key]
}
const customValidators = {
    validURL: (shouldPass, value, isRequired) => validateURL(value, isRequired),
    validEmail: (shouldPass, value, isRequired) => validateEmail(value, isRequired),
    validEmails: (shouldPass, value, isRequired) => {
        if (!isRequired && (_.isNil(value) || value === "")) {
            return true
        }
        const emails = value.split(",")
        const invalidEmails = _.filter(emails, (e) => !validateEmail(e.trim(), isRequired));
        return invalidEmails.length === 0;
    },
    slugCheck: async (params, value) => {
        var shouldPass = true;
        var exceptionSlug = null;
        if (typeof(params) === 'object') {
            shouldPass = !_.isNil(params.shouldPass) ? params.shouldPass : true;
            exceptionSlug = params.except;
        }
        if (!shouldPass) {
            return true;
        } else if (exceptionSlug && value === exceptionSlug) {
            return true;
        }
        const data = {
            slug: value
        };
        const result = await serverPost(params.getUrl('/companies/slug_check'), data);
        return Promise.resolve(!result.used);
    },
    assigned: async (shouldPass, value) => {
        return value && value !== 0;
    },
    isChecked: async (shouldPass, value) => {
        return value === true;
    },
    gt: async (rightValue, value) => {
        return value > rightValue;
    }
};
const processValidations = (name, label, validations) => {
    const processedValidations = {};
    let isRequired = false;
    if (_.has(validations, 'required')) {
        isRequired = validations['required'];
    }
    _.forEach(validations, (value, key) => {
        const params = { name, label, value };
        if (_.has(customValidators, key)) {
            processedValidations['validate'] = processedValidations['validate'] || {}
            processedValidations['validate'][key] = async (inputValue) => {
                const checkResult = await customValidators[key](value, inputValue, isRequired);
                if (!checkResult) {
                    return getValidationMessage(key, {...params, inputValue})
                } else {
                    return null;
                }
            }
        } else if (typeof(value) === "object") {
            processedValidations[key] = value;
        } else {
            processedValidations[key] = {
                value: value,
                message: getValidationMessage(key, params)
            }
        }
    });
    if (!_.has(processedValidations, 'required')) {
        processedValidations["required"] = {
            value: false
        }
    }
    return processedValidations;
}

const formatCC = (value) => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []

    for (var i=0, len=match.length; i<len; i+=4) {
        parts.push(match.substring(i, i+4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return v
    }
}

const processTransformations = (value, transformations) => {
    let processedValue = value;
    _.each(transformations, (transformation) => {
        if (transformation === "uppercase") {
            processedValue = processedValue.toUpperCase();
        } else if (transformation === "lowercase") {
            processedValue = processedValue.toLowerCase();
        } else if (transformation === "cc") {
            processedValue = formatCC(processedValue);
        } else if (transformation === "trim") {
            processedValue = processedValue.trim();
        }
    })
    return processedValue
}

const getGroupErrors = (errors, customErrorFields, props) => {
    const children = React.Children.toArray(props.children);
    const names = _.map(_.filter(children, (c) => c && c.props && c.props.name), (c) => c.props.name)
    const groupErrors = {};
    _.each(names, (name) => {
        let e = getValue(customErrorFields, name);
        if (!_.isNil(e)) {
            groupErrors[name] = e;
        } else {
            e = getValue(errors, name);
            if (!_.isNil(e)) {
                groupErrors[name] = e;
            }
        }
    })
    return groupErrors;
}

const hasCheckbox = (props) => {
    const children = React.Children.toArray(props.children);
    const checkField = _.find(children, c => c && c.type === BaseForm.CheckboxRadio);
    const hasCheck = checkField !== undefined;
    return hasCheck;
}

const hasRequiredFields = (props) => {
    const children = React.Children.toArray(props.children);
    const hasRequired = _.some(children, (c) => {
        if (_.isNil(c) || _.isNil(c.props)) {
            return false;
        }
        if (c.props && c.props.required) {
            return true;
        }
        const validation = (c.props && c.props.validations) || (c.props.rules) || {};
        if (_.isEmpty(validation)) {
            return false;
        } else {
            if (!validation.required) {
                return false;
            } else if (typeof(validation.required) === 'boolean') {
                return validation.required;
            } else {
                return validation.required.value;
            }
        }
    });
    return hasRequired;
}

export async function uploadToB2AndGetUrl(getApiUrl, type, file, customer_id=null) {
    const originalFilename = file.name;
    const normalizedFilename = originalFilename.replace(/[^a-zA-Z0-9\-\/. ]/g, "")
    const fileData = {
        config_name: type,
        filename: normalizedFilename,
        content_type: file.type,
        file_size: file.size
    }
    const signedUrlResponse = await serverPost(getApiUrl('/files/upload_url'), fileData);

    const presignedUrl = signedUrlResponse.url;

    const putResponse = await serverPut(presignedUrl, file, {headers: signedUrlResponse.headers})
    if (putResponse) {
        fileData.filename = signedUrlResponse.filename;
        fileData.original_filename = originalFilename;
        fileData.customer_id = customer_id;
        const ackResponse = await serverPost(getApiUrl('/files/ack_upload'), fileData)

        return {
            finalUrl: ackResponse.finalUrl,
            fileId: ackResponse.file_id
        };
    } else {
        return Promise.resolve(null);
    }
}

const BaseForm = forwardRef((props, ref)  => {
    const baseContext = useContext(BaseContext);
    useImperativeHandle(ref, () => ({
        submitForm() {
            return handleSubmit(onSubmit)();
        },
        getFormData() {
            return getCurrentFormData();
        },
        onResetFields(fields) {
            onResetFields(fields);
        }
    }));

    let { register, handleSubmit, reset, resetField, formState: { errors }, control, getValues } = useForm({ reValidateMode: 'onChange' });

    const fieldNames = [];
    const fieldTypeMap = {};
    const fileFieldNames = {};
    const addressFieldNames = {};
    const debounceMap = {};
    const timerMap = useRef({});
    const [formFields, setFormFields] = useState({});
    const [customErrorFields, setCustomErrorFields] = useState({});
    const [originalFormFields, setOriginalFormFields] = useState({});
    const [randomId, setRandomId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setRandomId(Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5))
    }, []);

    useEffect(() => {
        if (!_.isEqual(props.initialFormFields, originalFormFields)) {
            const newFormFields = {};
            _.each(fieldNames, (n) => {
                newFormFields[n] = getValue(props.initialFormFields || {}, n);
            });
            reset(newFormFields || {});
            setFormFields(props.initialFormFields || {});
        }
        setOriginalFormFields(props.initialFormFields || {});
    }, [props.initialFormFields]);

    useEffect(() => {
        setCustomErrorFields(props.errorFields);
    }, [props.errorFields]);

    const updateFormFields = (key, value) => {
        setFormFields((prevFormFields) => {
            const newFormFields = {...prevFormFields};
            let parts = key.split(".");
            let ob = newFormFields;
            for (let i = 0; i < parts.length - 1; i++) {
                if (!_.has(ob, parts[i]) || _.isNil(ob[parts[i]])) {
                    ob[parts[i]] = {};
                }
                ob = ob[parts[i]];
            }
            ob[parts[parts.length - 1]] = value;
            return newFormFields;
        });
        if (debounceMap[key]) {
            clearTimeout(timerMap.current[key]);
            timerMap.current[key] = setTimeout((callback, kk, vv) => {
                    if (callback) {
                        callback(kk, vv);
                    }
                    timerMap.current[key] = null;
                },
                debounceMap[key],
                props.onFieldChange,
                key,
                value,
            );
        } else {
            if (props.onFieldChange) {
                props.onFieldChange(key, value);
            }
        }
    }

    const onResetFields = (fields) => {
        setFormFields(prevFields => {
            const newFormFields = {...prevFields};
            _.map(fields, (value, key, i) => {
                newFormFields[key] = value;
            })
            return newFormFields;
        });
        _.each(_.keys(fields), (key, i) => {
            resetField(key);
        })
    }

    const registerName = (name, type, debounce=null) => {
        if (!fieldNames.includes(name)) {
            fieldNames.push(name);
        }
        fieldTypeMap[name] = type;
        if (debounce) {
            debounceMap[name] = debounce;
        } else {
            const isControlType = _.includes(["text", "number", "textarea"], type);
            if (isControlType) {
                debounceMap[name] = 400;
            }
        }
    }

    const registerFileField = (name, type) => {
        if (!_.has(fileFieldNames, name)) {
            fileFieldNames[name] = type
        }
    }

    const registerAddressField = (name, type) => {
        if (!_.has(addressFieldNames, name)) {
            addressFieldNames[name] = type
        }
    }

    const setValueForName = (data, name, value) => {
        let d = data;
        const nameParts = name.split(".");
        _.each(_.range(nameParts.length-1), (i) => {
            d[nameParts[i]] = d[nameParts[i]] || {};
            d = d[nameParts[i]];
        })
        if (value === undefined) {
            d[nameParts[nameParts.length-1]] = null;
        } else {
            d[nameParts[nameParts.length-1]] = value;
        }
        return data;
    }

    const getCurrentFormData = () => {
        const formValues = getValues();
        let data = {};
        _.forEach(fieldNames, (name) => {
            let fieldValue = getValue(formValues, name);
            if (fieldTypeMap[name] === "number") {
                fieldValue = parseFloat(fieldValue);
            } else if (fieldTypeMap[name] === "editor") {
                fieldValue = convertHtmlIntoVariables(fieldValue);
            }
            data = setValueForName(data, name, fieldValue)
        });
        return data;
    }

    async function defaultErrorHandler(res, errorResponseJson) {
        if (!_.includes([401], res.status)) {
            let errorResponse = errorResponseJson;
            if (!errorResponse) {
                errorResponse = await res.json();
            }
            const errorMap = {};
            _.each(errorResponse.errors, (er) => {
                _.each(_.keys(er), (k) => {
                    errorMap[k] = er[k];
                })
            })
            _.each(errorResponse.validation_errors, (er) => {
                errorMap[er.field.toLowerCase()] = er.message;
            })
            errorMap['error_message'] = errorResponse.error_message || errorResponse.error;
            setCustomErrorFields(errorMap);
        }
    }

    const onSubmit = async () => {
        if (!loading) {
            var submitButton = $('#' + randomId).find(':submit');
            if (submitButton) {
                submitButton.addClass('loading');
            }
            setLoading(true);
            const formData = getCurrentFormData();
            await Promise.all(_.map(Object.keys(fileFieldNames), async (name) => {
                const formValue = getValue(formData, name);
                if (formValue && formValue.size > 0) {
                    const fileData = formValue;
                    const urlData = await uploadToB2AndGetUrl(baseContext.getApiUrl, fileFieldNames[name], formValue, props.customer_id);
                    setValueForName(formData, name, urlData ? urlData.finalUrl : null);
                    setValueForName(formData, name+"_id", urlData ? urlData.fileId : null)
                    setValueForName(formData, name+"_extra", fileData);
                }
            }));
            await Promise.all(_.map(Object.keys(addressFieldNames), async (name) => {
                const formValue = getValue(formData, name);
                if (formValue) {
                    if (_.isNil(formValue.country)) {
                        setValueForName(formData, name, null);
                    }
                    const formattedValue = getValue(formData, `${name}_formatted`)
                    if (formattedValue) {
                        formValue.formatted = formattedValue
                        setValueForName(formData, name, formValue);
                    }
                }
            }));
            setCustomErrorFields(null);
            await props.onSubmit(formData, defaultErrorHandler);
            if (submitButton) {
                submitButton.removeClass('loading');
            }
            setLoading(false);
        }
    };

    if (!_.isEmpty(errors)) {
        console.log(errors);
    }

    return (
        <BaseFormContext.Provider value={{ initialFormFields:formFields, updateFormFields:updateFormFields, register,
                                            registerName, registerFileField, registerAddressField, errors, control, getValues, getValue,
                                            reset, customErrorFields }}>
            <Form id={randomId} onSubmit={handleSubmit(onSubmit)} className={props.className}>
                { props.children }
            </Form>
        </BaseFormContext.Provider>
    );
})

const Input = (props) => {
    return (
        <div className={classnames("col-md-" + props.colSpan, props.outerInputClassName) }>
        {
            props.type === "select" &&
                <BaseForm.SelectGroup
                    id={props.id} name={props.name} required={props.required} options={props.options}
                    label={props.label} errorLabel={props.errorLabel} labelField={props.labelField} idField={props.idField}
                    showSearch={props.showSearch} disabled={props.disabled} className={props.className} formClassName={props.formClassName}
                    formInputClassName={props.formInputClassName} hideLabel={props.hideLabel} align={props.align}
                    menuPlacement={props.menuPlacement} description={props.description} fullWidth={true} defaultOptions={props.defaultOptions} />
        }
        {
            props.type === "box-select" &&
                <BaseForm.BoxSelectGroup
                    id={props.id} name={props.name} required={props.required} options={props.options}
                    label={props.label} errorLabel={props.errorLabel} labelField={props.labelField} idField={props.idField}
                    showSearch={props.showSearch} disabled={props.disabled} className={props.className} formClassName={props.formClassName}
                    formInputClassName={props.formInputClassName} hideLabel={props.hideLabel} align={props.align}
                    menuPlacement={props.menuPlacement} description={props.description} fullWidth={true} />
        }
        {
            props.type === "selector" &&
                <BaseForm.SelectorGroup id={props.id} name={props.name} required={props.required} options={props.options}
                    label={props.label} errorLabel={props.errorLabel} labelField={props.labelField} idField={props.idField}
                    showSearch={props.showSearch} disabled={props.disabled} className={props.className} isMulti={props.isMulti}
                    canCreate={props.canCreate} loadOptions={props.loadOptions} getOptionLabel={props.getOptionLabel}
                    getOptionValue={props.getOptionValue} formatCreateLabel={props.formatCreateLabel}
                    formClassName={props.formClassName}
                    defaultOptions={props.defaultOptions} description={props.description} hideLabel={props.hideLabel}
                    isOptionDisabled={props.isOptionDisabled} isValidNewOption={props.isValidNewOption} />
        }
        {
            props.type === "rule" &&
                <BaseForm.RuleGroup id={props.id} name={props.name} required={props.required} options={props.options}
                    label={props.label} errorLabel={props.errorLabel} labelField={props.labelField} idField={props.idField}
                    showSearch={props.showSearch} propertyValues={props.propertyValues}
                    hideLabel={props.hideLabel} disabled={props.disabled} className={props.className}
                    isMulti={props.isMulti} propertyFields={props.propertyFields} isColored={props.isColored}
                    description={props.description}/>
        }
        {
            props.type === "multi-select" &&
                <BaseForm.MultiSelectGroup id={props.id} name={props.name} required={props.required} options={props.options}
                    label={props.label} errorLabel={props.errorLabel} showAll={props.showAll} labelField={props.labelField} idField={props.idField}
                    showSearch={props.showSearch}
                    disabled={props.disabled} className={props.className} valueAsCommaSeparated={props.valueAsCommaSeparated}
                    defaultSelectAll={props.defaultSelectAll} description={props.description}/>
        }
        {
            (props.type === "check" || props.type === "radio") &&
                <BaseForm.CheckGroup id={props.id} name={props.name} required={props.required} validations={props.validations}
                     disabled={props.disabled} type={props.type} formInputClassName={props.formInputClassName}
                     label={props.label} errorLabel={props.errorLabel} value={props.value} description={props.description}/>
        }
        {
            props.type === "switch" &&
                <BaseForm.SwitchGroup id={props.id} name={props.name} required={props.required} validations={props.validations}
                     disabled={props.disabled} type={props.type} formClassName={props.formClassName}
                     label={props.label} errorLabel={props.errorLabel} value={props.value} description={props.description}
                                      formInputClassName={props.formInputClassName} />
        }
        {
            props.type === "date" &&
                <BaseForm.DateGroup id={props.id} placeholder={props.placeholder} type={"text"} required={props.required}
                    validations={props.validations}  name={props.name} icon={"calendar-icon"} 
                    formClassName={props.formClassName} formInputClassName={props.formInputClassName} valueClassName={props.valueClassName}
                    dateRangeOpens={props.dateRangeOpens} dateRangeDrops={props.dateRangeDrops}
                    borderless={props.borderless} prefixText={props.prefixText}
                    disabled={props.disabled} label={props.label} errorLabel={props.errorLabel}
                    minDate={props.minDate} maxDate={props.maxDate} includeTime={props.includeTime} description={props.description}/>
        }
        {
            (props.type === "text" || props.type === "password" || props.type === "textarea" || props.type === "number") &&
                <BaseForm.ControlGroup id={props.id} placeholder={props.placeholder} type={props.type}
                    required={props.required} validations={props.validations} name={props.name} label={props.label}
                    className={props.className} formClassName={props.formClassName}
                    disabled={props.disabled} min={props.min} max={props.max} onBlur={props.onBlur} step={props.step}
                    readOnly={props.readOnly} hideLabel={props.hideLabel} errorLabel={props.errorLabel} icon={props.icon}
                    description={props.description} height={props.height} transformations={props.transformations} />
        }
        {
            props.type === "address" &&
                <BaseForm.AddressGroup id={props.id} placeholder={props.placeholder} type={props.type}
                    required={props.required} validations={props.validations} name={props.name} label={props.label}
                    className={props.className}
                    disabled={props.disabled} min={props.min} max={props.max} onBlur={props.onBlur} step={props.step}
                    readOnly={props.readOnly} hideLabel={props.hideLabel} errorLabel={props.errorLabel} icon={props.icon}
                    description={props.description} height={props.height} />
        }
        {
            props.type === "simple_address" &&
                <BaseForm.SimpleAddressGroup id={props.id} placeholder={props.placeholder} type={props.type}
                    required={props.required} validations={props.validations} name={props.name} label={props.label}
                    className={props.className}
                    disabled={props.disabled} min={props.min} max={props.max} onBlur={props.onBlur} step={props.step}
                    readOnly={props.readOnly} hideLabel={props.hideLabel} errorLabel={props.errorLabel} icon={props.icon}
                    description={props.description} height={props.height} />
        }
        {
            props.type === "file" &&
                <BaseForm.FileGroup id={props.id} placeholder={props.placeholder} type={props.type} required={props.required}
                    validations={props.validations} name={props.name} label={props.label} accept={props.accept}
                    fileType={props.fileType}
                    disabled={props.disabled} hidePreview={props.hidePreview} errorLabel={props.errorLabel}
                    description={props.description}/>
        }
        {
            props.type === "color" &&
                <BaseForm.ColorGroup name={props.name} label={props.label} disabled={props.disabled}
                    errorLabel={props.errorLabel} description={props.description}/>
        }
        {
            props.type === "editor" &&
                <BaseForm.EditorGroup id={props.id} placeholder={props.placeholder}
                    className={props.className} name={props.name} label={props.label} required={props.required}
                    validations={props.validations} disabled={props.disabled} height={props.height}
                    hideLabel={props.hideLabel} errorLabel={props.errorLabel} tokens={props.tokens}
                    description={props.description}/>
        }
        {
            props.type === "editor2" &&
                <BaseForm.FroalaEditorGroup id={props.id} placeholder={props.placeholder}
                    className={props.className} name={props.name} label={props.label} required={props.required}
                    validations={props.validations} disabled={props.disabled} height={props.height}
                    hideLabel={props.hideLabel} errorLabel={props.errorLabel} tokens={props.tokens}
                    description={props.description}/>
        }
        </div>
    );
}
BaseForm.Input = Input;

const InputWrapper = (props) => {
    return (
        <div className={classnames("col-lg-" + props.colSpan, props.className)}>
            <div className="form-group">
                { props.children }
            </div>
        </div>
    )
}
BaseForm.InputWrapper = InputWrapper;

const Group = (props) => {
    const { errors, customErrorFields } = useContext(BaseFormContext);
    const hasCheck = hasCheckbox(props);
    const groupErrors = getGroupErrors(errors, customErrorFields, props);
    const hasError = !_.isEmpty(groupErrors);
    const hasRequired = hasRequiredFields(props);

    return (
        <>
            <Form.Group {...props} className={classnames("form-group", props.className, hasCheck ? "inline": "", hasError ? "error": "", hasRequired ? "required": "")} >
                { props.children }
            </Form.Group>
            {
                hasError &&
                    _.map(groupErrors, (error, key) =>
                        <p key={key} className="form-error-message">{error.message}</p>
                    )
            }
        </>
    );
}
BaseForm.Group = Group;

const BaseInputGroup = (props) => {
    const { errors, customErrorFields } = useContext(BaseFormContext);
    const groupErrors = getGroupErrors(errors, customErrorFields, props);
    const hasError = !_.isEmpty(groupErrors);
    const hasRequired = hasRequiredFields(props);
    const showLabel = !(props.hideLabel || _.isNil(props.label));

    const children = React.Children.toArray(props.children);
    const hasBorderlessElement = props.borderless || _.some(children, (c) => c && c.props && _.includes(["checkbox", "radio", "switch"], c.props.type));
    const hasTextareaElement = _.some(children, (c) => c && c.props && _.includes(["textarea"], c.props.type));

    const pprops = {...props};
    delete pprops['errorLabel'];
    const classNames = classnames(props.className,
        props.disabled ? "disabled" : "",
        hasBorderlessElement ? "borderless": "",
        hasTextareaElement ? "d-inline-table": "");
    return (
        <div className={classnames("form-group", props.formClassName)}>
            {
                showLabel &&
                    <div className="form-label">{props.label}</div>
            }
            {
                !_.isNil(props.description) &&
                    <div className="text-sm text-gray-700">{props.description}</div>
            }
            <div className={classnames("form-input", hasRequired ? "required": "", hasBorderlessElement ? "borderless": "",
                props.disabled ? "disabled": "", hasTextareaElement ? "textarea": "", hasError ? "error": "", props.formInputClassName)}>
                {
                    props.icon &&
                        <div className={classnames(props.icon, "form-input-icon")}/>
                }
                {
                    React.Children.map(children, child => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, { disabled: props.disabled || props.readOnly, className: classnames(classNames, child.props.className) });
                        }
                        return child;
                    })
                }
            </div>
            {
                hasError &&
                    _.map(groupErrors, (error, key) =>
                        <div key={key} className="form-error-message w-full">{error.message || error}</div>
                    )
            }
        </div>
    );
}
BaseForm.InputGroup = BaseInputGroup;

const SingleSelect = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "select");
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const idField = props.idField || "value";
    const labelField = props.labelField || "label";
    const formValue = getValue(initialFormFields, props.name);
    const value = !_.isNil(formValue)? formValue : (props.options.length > 0 ? props.options[0][idField] : null);
    const adjustedValue = (!_.isUndefined(value) && !_.isNull(value) && !_.isNil(value)) ? value : null;
    const showSearch = _.isNil(props.showSearch) ? true: props.showSearch;
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations);
    return (
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={adjustedValue}
            render={({ field }) => {
                return (
                    <SingleSelectDropdown
                        items={props.options} selectedId={adjustedValue} showAll={props.showAll}
                        name={props.name} idField={idField} labelField={labelField}
                        showSearch={showSearch} align={props.align} hideLabel={!showLabel}
                        menuPlacement={props.menuPlacement}
                        alignDropdown={props.alignDropdown}
                        className={classnames("form-select", props.disabled ? "disabled": "", props.className)}
                        fullWidth={props.fullWidth} disabled={props.disabled}
                        onSelect={(value) => {
                            updateFormFields(props.name, value);
                            field.onChange(value);
                        }} />
                );
            }}
        />

    );
}
const SelectGroup = (props) => {
    const pprops = {...props};
    return (
        <BaseForm.InputGroup
            className={props.className} formClassName={props.formClassName} label={props.label}
            hideLabel={props.hideLabel} disabled={props.disabled} borderless={props.borderless}
            description={props.description} formInputClassName={props.formInputClassName}>
            <BaseForm.SingleSelect {...pprops} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Select = SingleSelect;
BaseForm.SingleSelect = SingleSelect;
BaseForm.SelectGroup = SelectGroup;

const BoxSelect = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "select");
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const idField = props.idField || "value";
    const labelField = props.labelField || "label";
    const formValue = getValue(initialFormFields, props.name);
    const value = !_.isNil(formValue)? formValue : (props.options.length > 0 ? props.options[0][idField] : null);
    const adjustedValue = (!_.isUndefined(value) && !_.isNull(value) && !_.isNil(value)) ? value : null;
    const showSearch = _.isNil(props.showSearch) ? true: props.showSearch;
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations);
    return (
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={adjustedValue}
            render={({ field }) => {
                return (
                    <div className="flex flex-row gap-2">
                        {
                            _.map(_.filter(props.options, a => a), (op, i) =>
                                <div
                                    key={i}
                                    className={classnames("hover:border-gray-900 border-gray-700 border px-4 py-3 rounded-md cursor-pointer max-w-[150px] text-center flex items-center", adjustedValue === op.value ? "bg-gray-100": "")}
                                    onClick={() => {
                                        updateFormFields(props.name, op.value);
                                        field.onChange(op.value);
                                    }}
                                >
                                    { op.label }
                                </div>
                            )
                        }
                    </div>
                );
            }}
        />

    );
}
const BoxSelectGroup = (props) => {
    const pprops = {...props};
    return (
        <BaseForm.InputGroup
            className={props.className} formClassName={props.formClassName} label={props.label}
            hideLabel={props.hideLabel} disabled={props.disabled} borderless
            description={props.description} formInputClassName={props.formInputClassName}>
            <BaseForm.BoxSelect {...pprops} />
        </BaseForm.InputGroup>
    );
}
BaseForm.BoxSelect = BoxSelect;
BaseForm.BoxSelectGroup = BoxSelectGroup;

const MultiSelect = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "multi-select");
    const value = getValue(initialFormFields, props.name);
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    let adjustedValue = (!_.isUndefined(value) && !_.isNull(value) && !_.isNil(value)) ? value : (props.defaultSelectAll ? _.map(props.options, (o) => o[props.idField || "id"]).join(",") : "");
    let formattedValue = adjustedValue;
    if (props.valueAsCommaSeparated) {
        if (typeof(adjustedValue) === "string" && _.isEmpty(adjustedValue)) {
            formattedValue = [];
        } else {
            formattedValue = String(adjustedValue).split(",");
        }
    }
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const idField = props.idField || "value";
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations);
    return (
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={adjustedValue}
            render={({ field }) => {
                return (
                    <MultiSelectDropdown
                        label={props.label} items={props.options}
                        className={classnames("form-select", props.disabled ? "disabled": "")}
                        onItemsChange={(value) => {
                            let avalue = value;
                            if (props.valueAsCommaSeparated) {
                                avalue = value.join(",");
                            }
                            updateFormFields(props.name, avalue);
                            field.onChange(avalue);
                        }}
                        selectedItems={formattedValue}
                        hideLabel={!showLabel}
                        idField={idField}
                        labelField={props.labelField}
                    />
                );
            }}
        />

    );
}
const MultiSelectGroup = (props) => {
    const pprops = {...props};
    delete pprops['errorLabel'];
    // delete pprops['label'];
    return (
        <BaseForm.InputGroup className={props.className} label={props.label}
                             hideLabel={props.hideLabel} disabled={props.disabled} borderless={props.borderless}
                             description={props.description}>
            <BaseForm.MultiSelect {...pprops} hideLabel={true} />
        </BaseForm.InputGroup>
    );
}
BaseForm.MultiSelect = MultiSelect;
BaseForm.MultiSelectGroup = MultiSelectGroup;

const Control = forwardRef((props, ref)  => {
    const controlField = useRef(null);
    const timer = useRef(null);
    useImperativeHandle(ref, () => ({
        updateControl(value) {
            if (controlField.current) {
                controlField.current.onChange(value);
            }
        },
    }));

    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, props.type, props.debounce);
    const showLabel = !(props.hideLabel || _.isNil(props.label)) && false;
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const pprops = {...props};
    delete pprops['required'];
    delete pprops['changeYear'];
    delete pprops['changeMonth'];
    delete pprops['defaultDate'];
    delete pprops['minDate'];
    delete pprops['maxDate'];
    delete pprops['yearRange'];
    delete pprops['hideIfNoPrevNext'];
    delete pprops['hideLabel'];
    delete pprops['dateFormat'];
    delete pprops['errorLabel'];
    delete pprops['controlRef'];
    delete pprops['formClassName'];
    if (props.type === "date") {
        pprops['type'] = "text";
        pprops['id'] = pprops['id'] || pprops['name'];
    }
    if (props.type === "textarea") {
        pprops['as'] = "textarea"
    }
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations || {});
    return (
        <>
        {
            showLabel &&
                <Form.Label>{props.label}</Form.Label>
        }
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={getValue(initialFormFields, props.name, null)}
            render={({ field }) => {
                controlField.current = field;
                let defaultValue = getValue(initialFormFields, props.name, "");
                if (props.type === "date") {
                    const fieldId = props.id || props.name;
                    const dateFormat = props.dateFormat || "YYYY-MM-DD";
                    $( function() {
                        const params = {
                            autoApply: true,
                            minDate: props.minDate && moment(props.minDate),
                            maxDate: props.maxDate && moment(props.maxDate),
                            singleDatePicker: true,
                            autoUpdateInput: false,
                            drops: "auto",
                            timePicker: props.includeTime ? true: false,
                            timePickerIncrement: 5
                        }
                        if (defaultValue) {
                            params.startDate = moment(defaultValue);
                        }
                        $('#' + escapeSelector(fieldId)).daterangepicker(params,
                            function(start, end, label) {
                                updateFormFields(props.name, start.format(dateFormat));
                                field.onChange(start.format(dateFormat));
                            }
                        );
                    });
                }
                return (
                    <Form.Control
                        {...pprops} ref={props.controlRef}
                        disabled={props.disabled}
                        value={defaultValue}
                        onWheel={(event) => { event.target.blur(); }}
                        style={props.height && { height: props.height }}
                        onChange={(event) => {
                            const v = getValueFromEvent(event);
                            const processedV = processTransformations(v, props.transformations || []);
                            updateFormFields(props.name, processedV);
                            field.onChange(processedV);
                            if (props.onChange) {
                                props.onChange(props.name, processedV);
                            }
                        }}>
                        { props.children }
                    </Form.Control>
                );
            }}
        />
        </>
    );
})
const ControlGroup = (props) => {
    return (
        <BaseForm.InputGroup
            className={props.className} label={props.label} hideLabel={props.hideLabel}
            formClassName={props.formClassName} disabled={props.disabled} readOnly={props.readOnly}
            icon={props.icon} description={props.description}>
            <BaseForm.Control {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Control = Control;
BaseForm.ControlGroup = ControlGroup;

const AddressGroup = (props) => {
    const { updateFormFields } = useContext(BaseFormContext);

    const line1Ref = useRef();
    const line2Ref = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const zipRef = useRef();
    const countryRef = useRef();
    const formattedRef = useRef();
    const placeIdRef = useRef();

    const { ref: addressRef } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        onPlaceSelected: (place) => {
            if (_.isNil(place.formatted_address)) {
                // This is not a valid google place address so don't replace everything else
                return;
            }
            const address = getAddressFromGooglePlace(place);
            updateFormFields(`${props.name}_formatted`, address.formatted_address);
            updateFormFields(`${props.name}.place_id`, address.place_id);
            updateFormFields(`${props.name}.address_line_1`, address.address_line_1);
            updateFormFields(`${props.name}.address_line_2`, address.address_line_2);
            updateFormFields(`${props.name}.city`, address.city);
            updateFormFields(`${props.name}.state`, address.state);
            updateFormFields(`${props.name}.zip`, address.zip);
            updateFormFields(`${props.name}.country`, address.country);

            if (line1Ref.current) {
                line1Ref.current.updateControl(address.address_line_1);
            }
            if (line2Ref.current) {
                line2Ref.current.updateControl(address.address_line_2);
            }
            if (cityRef.current) {
                cityRef.current.updateControl(address.city);
            }
            if (stateRef.current) {
                stateRef.current.updateControl(address.state);
            }
            if (zipRef.current) {
                zipRef.current.updateControl(address.zip);
            }
            if (countryRef.current) {
                countryRef.current.updateControl(address.country);
            }
            if (formattedRef.current) {
                formattedRef.current.updateControl(place.formatted_address);
            }
            if (placeIdRef.current) {
                placeIdRef.current.updateControl(place.place_id);
            }
        },
        options:{
            types: ["address"],
            libraries: ["places"]
        }
    });

    const onFieldChange = (name, value) => {
        // Some field changed. We can't rely on the google place id anymore.
        updateFormFields(`${props.name}_formatted`, null);
        updateFormFields(`${props.name}.place_id`, null);
        if (formattedRef.current) {
            formattedRef.current.updateControl(null);
        }
        if (placeIdRef.current) {
            placeIdRef.current.updateControl(null);
        }
    }

    return (
        <Row>
            <Col md="12">
                <BaseForm.InputGroup className={props.className} label={props.label} icon="search-icon"
                    disabled={props.disabled} readOnly={props.readOnly}>
                    <BaseForm.Control {...props} name={`${props.name}.address_line_1`} type="address" placeholder="Address Line 1"
                        ref={line1Ref} controlRef={addressRef} onChange={onFieldChange}/>
                </BaseForm.InputGroup>
            </Col>
            <Col md="12">
                <BaseForm.InputGroup className={props.className}
                    disabled={props.disabled} readOnly={props.readOnly} hideLabel={true}>
                    <BaseForm.Control {...props} required={false} name={`${props.name}.address_line_2`} type="text" placeholder="Address Line 2"
                        ref={line2Ref} onChange={onFieldChange} />
                </BaseForm.InputGroup>
            </Col>
            <Col md="6">
                <BaseForm.InputGroup className={props.className} label="City"
                    disabled={props.disabled} readOnly={props.readOnly} hideLabel={true}>
                    <BaseForm.Control {...props} name={`${props.name}.city`} type="text" placeholder="City" ref={cityRef}
                        onChange={onFieldChange}/>
                </BaseForm.InputGroup>
            </Col>
            <Col md="6">
                <BaseForm.InputGroup className={props.className} label="State/Province"
                    disabled={props.disabled} readOnly={props.readOnly} hideLabel={true}>
                    <BaseForm.Control {...props} name={`${props.name}.state`} type="text" placeholder="State/Province"
                        ref={stateRef} onChange={onFieldChange}/>
                </BaseForm.InputGroup>
            </Col>
            <Col md="6">
                <BaseForm.InputGroup className={props.className} label="Zip/Postal Code"
                    disabled={props.disabled} readOnly={props.readOnly} hideLabel={true}>
                    <BaseForm.Control {...props} name={`${props.name}.zip`} type="text" placeholder="Zip/Postal Code"
                        ref={zipRef} onChange={onFieldChange}/>
                </BaseForm.InputGroup>
            </Col>
            <Col md="6">
                <BaseForm.InputGroup className={props.className} label="Country"
                    disabled={props.disabled} readOnly={props.readOnly} hideLabel={true}>
                    <BaseForm.Control {...props} name={`${props.name}.country`} type="text" placeholder="Country"
                        ref={countryRef} onChange={onFieldChange}/>
                </BaseForm.InputGroup>
            </Col>
            <BaseForm.Hidden name={`${props.name}_formatted`} ref={formattedRef}/>
            <BaseForm.Hidden name={`${props.name}.place_id`} ref={placeIdRef}/>
        </Row>
    );
}
BaseForm.AddressGroup = AddressGroup;

const SimpleAddressGroup = (props) => {
    const { initialFormFields, updateFormFields, registerAddressField } = useContext(BaseFormContext);

    const lineRef = useRef();
    const fullRef = useRef();

    const { ref: addressRef } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        onPlaceSelected: (place) => {
            if (_.isNil(place.formatted_address)) {
                // This is not a valid google place address so don't replace everything else
                return;
            }
            const address = getAddressFromGooglePlace(place);
            updateFormFields(props.name, address);
            updateFormFields(`${props.name}_formatted`, address.formatted_address);

            if (lineRef.current) {
                lineRef.current.updateControl(address.formatted_address);
            }
            if (fullRef.current) {
                fullRef.current.updateControl(address);
            }
        },
        options:{
            types: ["address"],
            libraries: ["places"]
        }
    });
    registerAddressField(props.name);

    const onFieldChange = (name, value) => {
        // Some field changed. We can't rely on the google place id anymore.
        // updateFormFields(props.name, null);
        // console.log("on field change " + name + ", " + value);
    }

    const defaultValue = getValue(initialFormFields, props.name, "");
    useEffect(() => {
        if (_.isNil(defaultValue.country)) {
            if (lineRef.current) {
                lineRef.current.updateControl(null);
            }
            if (fullRef.current) {
                fullRef.current.updateControl(null);
            }
        }
        if (!_.isNil(defaultValue) && defaultValue.formatted) {
            if (lineRef.current) {
                lineRef.current.updateControl(defaultValue.formatted);
                updateFormFields(`${props.name}_formatted`, defaultValue.formatted)
            }
        }
    }, [defaultValue]);

    return (
        <Row>
            <Col md="12">
                <BaseForm.InputGroup className={props.className} label={props.label} icon="search-icon"
                                     disabled={props.disabled} readOnly={props.readOnly} required={props.required}>
                    <BaseForm.Control {...props} name={`${props.name}_formatted`} type="address" placeholder="Search Address"
                                      ref={lineRef} controlRef={addressRef} onChange={onFieldChange} />
                    <BaseForm.Hidden name={props.name} ref={fullRef}/>
                    {
                        !_.isNil(defaultValue.country) &&
                            <div className="form-clear file-clear">
                                <Button variant="text" className="thin" size="sm"
                                    onClick={() => {
                                        updateFormFields(props.name, null);
                                        updateFormFields(`${props.name}_formatted`, null);
                                        if (lineRef.current) {
                                            lineRef.current.updateControl(null);
                                        }
                                        if (fullRef.current) {
                                            fullRef.current.updateControl(null);
                                        }
                                    }}>
                                    <i className="fa fa-x gray3-color"/>
                                </Button>
                            </div>
                    }
                </BaseForm.InputGroup>
            </Col>
            <span className="caption gray4 hide">{ defaultValue && getFormattedAddressForAddress(defaultValue) }</span>
        </Row>
    );
}
BaseForm.SimpleAddressGroup = SimpleAddressGroup;

const Color = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "color");
    const [show, setShow] = useState(false);
    const style = {};
    const processedValidations = processValidations(props.name, props.label, props.validations);
    const colorValue = getValue(initialFormFields, props.name, null);
    style["background"] = colorValue;

    const handleClick = useCallback((event) => {
        const length = $(event.target).closest('.form-color').length;
        const length2 = $(event.target).closest('.sketch-picker').length;
        if (length === 0 && length2 === 0) {
            setShow(false);
        }
    }, []);

    useEffect(() => {
        if (show) {
            document.addEventListener('click', handleClick);
        } else {
            document.removeEventListener('click', handleClick);
        }
    }, [show])

    const onClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const length2 = $(event.target).closest('.sketch-picker').length;
        if (length2 === 0) {
            setShow(!show);
        }
    }

    return (
        <>
            <Controller
                control={control}
                name={props.name}
                rules={processedValidations}
                defaultValue={colorValue}
                render={({ field }) => {
                    return (
                        <div className="d-flex flex-row flex-grow-1 gap-2" onClick={onClick}>
                            <span className="form-color-label flex-grow-1 body1 align-self-center">{ props.label }</span>
                            {
                                colorValue &&
                                <div className="form-clear file-clear">
                                    <Button variant="text" className="thin" size="sm"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                setShow(false);
                                                field.onChange(null);
                                                updateFormFields(props.name, null);
                                            }}>
                                        <i className="fa fa-x gray3-color"/>
                                    </Button>
                                </div>
                            }
                            <div className="form-color">
                                <div className="form-color-value" style={style} onClick={(event) => setShow(!show)} />
                                {
                                    show &&
                                    <SketchPicker
                                        triangle="hide"
                                        colors={getColorOptions().map((c) => c.value)}
                                        color={ getValue(initialFormFields, props.name, "")}
                                        onChange={(color) => {
                                            field.onChange(color.hex);
                                            updateFormFields(props.name, color.hex);
                                        }} />
                                }
                            </div>
                        </div>
                    );
                }}
            />
        </>
    );
}
const ColorGroup = (props) => {
    return (
        <BaseForm.InputGroup leftContent={props.leftContent} rightContent={props.rightContent}
                             disabled={props.disabled} label={props.label}>
            <BaseForm.Color {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Color = Color;
BaseForm.ColorGroup = ColorGroup;

const Text = (props) => {
    return (
        <BaseForm.Control {...props} type="text" />
    );
}
const TextGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="text" />
    );
}
BaseForm.Text = Text;
BaseForm.TextGroup = TextGroup;

const Number = (props) => {
    return (
        <BaseForm.Control {...props} type="number" />
    );
}
const NumberGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="number" />
    );
}
BaseForm.Number = Number;
BaseForm.NumberGroup = NumberGroup;

const Password = (props) => {
    return (
        <BaseForm.Control {...props} type="password" />
    );
}
const PasswordGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="password" />
    );
}
BaseForm.Password = Password;
BaseForm.PasswordGroup = PasswordGroup;

const TextArea = (props) => {
    return (
        <BaseForm.Control {...props} type="textarea" />
    );
}
const TextAreaGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="textarea" />
    );
}
BaseForm.TextArea = TextArea;
BaseForm.TextAreaGroup = TextAreaGroup;

const Date = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "date");
    const showLabel = !(props.hideLabel || _.isNil(props.label)) && false;
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const isRequired = validations['required'];
    const defaultValue = getValue(initialFormFields, props.name, null);
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations || {});
    return (
        <>
        {
            showLabel &&
                <Form.Label>{props.label}</Form.Label>
        }
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={defaultValue}
            render={({ field }) => {
                const fieldId = _.replace(props.id || props.name, ".", "_");
                const dateFormat = props.dateFormat || "YYYY-MM-DD";
                const datetimeFormat = props.datetimeFormat || "YYYY-MM-DD hh:mma";
                const adjustedFormat = props.includeTime ? datetimeFormat: dateFormat;
                let defaultValueString = defaultValue && moment(defaultValue).format(adjustedFormat);
                $( function() {
                    const params = {
                        autoApply: true,
                        minDate: props.minDate && moment(props.minDate),
                        maxDate: props.maxDate && moment(props.maxDate),
                        singleDatePicker: true,
                        autoUpdateInput: false,
                        opens: props.dateRangeOpens || null,
                        drops: props.dateRangeDrops || "auto",
                        timePicker: props.includeTime ? true: false,
                        timePickerIncrement: 1
                    }
                    if (defaultValue) {
                        params.startDate = moment(defaultValue);
                    }
                    if (!props.disabled) {
                        $('#' + escapeSelector(fieldId)).daterangepicker(params,
                            function(start, end, label) {
                                field.onChange(start);
                                updateFormFields(props.name, start);
                                $('#' + escapeSelector(fieldId)).html(start.format(adjustedFormat))
                            }
                        );
                    }
                });
                return (
                    <>
                        <span className={classNames(props.valueClassName, "form-control")} id={escapeSelector(fieldId)}>
                            {defaultValueString ? `${props.prefixText || ""}${defaultValueString}` : props.placeholder}
                        </span>
                        {
                            defaultValue && !isRequired &&
                                <div className="form-clear">
                                    <Button variant="text" className="thin" size="sm"
                                        onClick={() => {
                                            field.onChange(null);
                                            updateFormFields(props.name, null);
                                        }}>
                                        <i className="fa fa-x gray3-color"/>
                                    </Button>
                                </div>
                        }
                    </>
                );
            }}
        />
        </>
    );
}
const DateGroup = (props) => {
    return (
        <BaseForm.InputGroup className={props.className} formClassName={props.formClassName} label={props.label} hideLabel={props.hideLabel}
            disabled={props.disabled} readOnly={props.readOnly} icon={props.icon} description={props.description} borderless={props.borderless}
            formInputClassName={props.formInputClassName}>
            <BaseForm.Date {...props} type="date" />
        </BaseForm.InputGroup>
    );
}
BaseForm.Date = Date;
BaseForm.DateGroup = DateGroup;

const Switch = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "switch");
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, props.validations);
    return (
        <>
            <Controller
                control={control}
                name={props.name}
                rules={processedValidations}
                defaultValue={getValue(initialFormFields, props.name, false)}
                render={({ field }) => {
                    return (
                        <Form.Check
                            type="switch" className={props.className}
                            checked={getValue(initialFormFields, props.name, false)}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onChange={(event) => {
                                updateFormFields(props.name, getValueFromEvent(event));
                                field.onChange(getValueFromEvent(event));
                            }}
                            disabled={props.disabled}>
                            { props.children }
                        </Form.Check>
                    );
                }}
            />
        </>
    );
}
BaseForm.Switch = Switch;
const SwitchGroup = (props) => {
    return (
        <>
            <BaseForm.InputGroup disabled={props.disabled} formClassName={props.formClassName}
                                 formInputClassName={classnames(props.formInputClassName, "transparent", "flex-grow-1")} borderless>
                <div className="flex flex-row flex-grow items-center">
                    <div className="flex-grow-1 d-flex flex-column">
                        {
                            props.label && <div style={{ paddingLeft: "0px" }} className="body2">{props.label}</div>
                        }
                        {
                            props.description && <div style={{ paddingLeft: "0px" }} className="body1">{props.description}</div>
                        }
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <BaseForm.Switch {...props} />
                    </div>
                </div>
            </BaseForm.InputGroup>
        </>
    );
}
BaseForm.SwitchGroup = SwitchGroup;


const Selector = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "selector");
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations || {});
    let defaultValue = getValue(initialFormFields, props.name, props.isMulti ? [] : "");
    let formatCreateLabel = props.formatCreateLabel || ((inputValue) => { return `Create ${inputValue}` });
    if (!props.canCreate) {
        formatCreateLabel = (s) => undefined
    }
    const noOptionsMessage = (inputValue) => { return `Start typing...` };
    const getOptionLabel = props.getOptionLabel || ((option) => {
        return (
            <>
                <span className="title">{ option.label || option.value }</span><br/>
            </>
        )
    })
    const getOptionValue = props.getOptionValue || ((option) => {
        return option.value;
    })

    let valueForSelect = defaultValue;
    let isMulti = props.isMulti || false;
    if (isMulti) {
        valueForSelect = _.map(defaultValue, (v) => {
            const d = _.find(props.defaultOptions, (a) => v === getOptionValue(a));
            if (d) {
                return d;
            } else {
                return { value: v, label: v };
            }
        });
    }

    const canCreate = !_.isNil(props.canCreate) ? props.canCreate : false;
    return (
        <>
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={defaultValue}
            render={({ field }) => {
                const selectProps = {
                    components: { DropdownIndicator: null },
                    isMulti: props.isMulti,
                    className: classnames("select-container", isMulti ? "multi": ""),
                    loadOptions: debounceAwaitFunction(props.loadOptions, 250),
                    classNamePrefix: "select2",
                    getOptionLabel: getOptionLabel,
                    getOptionValue: getOptionValue,
                    value: valueForSelect,
                    onChange: (value, meta) => {
                        if (props.isMulti) {
                            updateFormFields(props.name, _.map(value, (v) => getOptionValue(v)));
                            field.onChange(_.map(value, (v) => getOptionValue(v)));
                        } else {
                            updateFormFields(props.name, value);
                            field.onChange(value);
                        }
                    },
                    isClearable: !props.required,
                    noOptionsMessage: props.noOptionsMessage || noOptionsMessage,
                    formatCreateLabel: formatCreateLabel,
                    isDisabled: props.disabled,
                    defaultOptions: props.defaultOptions,
                    isOptionDisabled: props.isOptionDisabled,
                    isValidNewOption: props.isValidNewOption,
                    onBlur: (event) => {
                        if (_.isEmpty(event.target.value.trim())) {
                            return;
                        }
                        if (props.isMulti) {
                            if (canCreate) {
                                const newValues = [...defaultValue, event.target.value]
                                updateFormFields(props.name, newValues);
                                field.onChange(newValues);
                            }
                        } else {
                            if (canCreate) {
                                updateFormFields(props.name, event.target.value);
                                field.onChange(event.target.value);
                            }
                        }
                    }
                }
                if (canCreate) {
                    return (<CreatableSelect {...selectProps} />);
                } else {
                    return (<Select {...selectProps} />);
                }
            }}
        />
        </>
    );
}
const SelectorGroup = (props) => {
    return (
        <BaseForm.InputGroup disabled={props.disabled} hideLabel={props.hideLabel} formClassName={props.formClassName}
            label={props.label} description={props.description} >
            <BaseForm.Selector {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Selector = Selector;
BaseForm.SelectorGroup = SelectorGroup;

const File = (props) => {
    const { initialFormFields, updateFormFields, registerName, registerFileField, control } = useContext(BaseFormContext);
    registerName(props.name, "file");
    registerFileField(props.name, props.fileType);
    const value = getValue(initialFormFields, props.name);
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, props.validations);

    const [fileValue, setFileValue] = useState(null);
    const fileRef = useRef();
    useEffect(() => {
        if (value !== undefined) {
            if (value.size) {
                var reader = new FileReader();
                reader.onload = function(event) {
                    setFileValue(event.target.result);
                };
                reader.readAsDataURL(value)
            } else {
                setFileValue(value);
            }
        } else {
            fileRef.current.value = value || "";
            setFileValue(value);
        }
    }, [value])
    return (
        <>
            <Controller
                control={control}
                name={props.name}
                rules={processedValidations}
                defaultValue={getValue(initialFormFields, props.name, "")}
                render={({ field }) => {
                    return (
                        <>
                            <input type="file" className="form-file-input" name={props.name} label={props.label}
                                ref={fileRef}
                                disabled={props.disabled}
                                placeholder={props.placeholder}
                                accept={props.accept || "*/*"}
                                onChange={async (event) => {
                                    updateFormFields(props.name, getValueFromEvent(event));
                                    field.onChange(getValueFromEvent(event));
                                }}
                                required={props.required}
                            />
                            {
                                !props.hidePreview && fileValue &&
                                    <div className="form-clear file-clear">
                                        <Button variant="text" className="thin" size="sm"
                                            onClick={() => {
                                                field.onChange(null);
                                                updateFormFields(props.name, null);
                                            }}>
                                            <i className="fa fa-x gray3-color"/>
                                        </Button>
                                    </div>
                            }
                        </>
                    );
                }}
            />
            {
                !props.hidePreview && fileValue &&
                    <div className="file-preview"><img src={fileValue} alt="Preview"/></div>
            }
        </>
    );
}
const FileGroup = (props) => {
    return (
        <BaseForm.InputGroup className="form-group" disabled={props.disabled} label={props.label}
            description={props.description}>
            <BaseForm.File {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.File = File;
BaseForm.FileGroup = FileGroup;
FileGroup.propTypes = {
  fileType: PropTypes.string.isRequired
};

const EditorGroup = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "editor");

    const editor = useRef(null);
    let extraButtons = [
        {
            name: 'Page Break',
            exec: function (editor, t, {control}) {
                if (!control.isParent) {
                    editor.selection.insertHTML('<p class="template-page-break" style="page-break-after: always;">&nbsp;</p>');
                }
            }
        }
    ]
    if (!_.isEmpty(props.tokens)) {
        extraButtons.push({
            name: 'Variables',
            list: props.tokens,
            isParent: true,
            childTemplate: (editor, key, value) => {
                return `<span>${props.tokens[key].name}</span>`;
            },
            exec: function (editor, t, {control}) {
                if (!control.isParent) {
                    editor.selection.insertHTML(control.args[0]);
                }
            }
        })
    }
    const config = useMemo(() => {
        return {
            license: process.env.REACT_APP_JODIT_PRO_KEY,
            readonly: false,
            placeholder: props.placeholder || "",
            height: props.height || 250,
            removeButtons: 'about,pageBreak',
            enter: "br",
            disablePlugins: ["page-break"],
            uploader: {
                insertImageAsBase64URI: true
            },
            extraButtons: extraButtons
        };
    }, [props.height, props.placeholder, props.tokens])
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const value = getValue(initialFormFields, props.name);
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations);
    return (
        <BaseForm.InputGroup label={props.label} disabled={props.disabled} description={props.description} borderless>
            <Controller
                control={control}
                name={props.name}
                rules={processedValidations}
                defaultValue={getValue(initialFormFields, props.name) || ""}
                render={({ field }) => {
                    return (
                        <div className="form-editor">
                            <JoditEditor
                                ref={editor}
                                value={value}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => {
                                    updateFormFields(props.name, newContent);
                                    field.onChange(newContent);
                                }}
                                onChange={newContent => newContent => {
                                    updateFormFields(props.name, newContent);
                                    field.onChange(newContent);
                                }}
                            />
                        </div>
                    );
                }}
            />
        </BaseForm.InputGroup>
    );
}
BaseForm.EditorGroup = EditorGroup;

const FroalaEditorGroup = (props) => {
    const { initialFormFields, updateFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "editor");
    const isInsideSet = useRef(false);
    const [rect, setRect] = useState(null);
    const [showVariablesSelector, setShowVariablesSelector] = useState(false);

    const editor = useRef(null);
    BaseFroalaEditor.DefineIcon('variables', {NAME: 'Insert Variable'});
    BaseFroalaEditor.RegisterCommand('variables', {
        title: 'Insert Variable',
        icon: `<i class="fa fa-bolt"></i>`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            const editorRect = $(".form-editor")[0].getBoundingClientRect();
            const rect = $(".form-editor").parent().closest('.content-body')[0].getBoundingClientRect();
            setRect({ x: rect.x + (rect.width-600)/2, y: editorRect.y + 48 });
            setShowVariablesSelector(!showVariablesSelector);
            this.selection.save();
            editor.current = this;
        },
        refresh: function (data) {
        }
    });

    BaseFroalaEditor.DefineIcon('insertPageBreak', {NAME: 'Page Break'});
    BaseFroalaEditor.RegisterCommand('insertPageBreak', {
        title: 'Insert Page Break',
        icon: `<span>PB</span>`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('<p class="template-page-break" style="page-break-after: always;">&nbsp;</p>');
        },
        refresh: function (data) {
        }
    });

    const onVariableSelect = (variable) => {
        if (editor.current) {
            editor.current.selection.restore();
            editor.current.html.insert(convertVariablesIntoHtml(variable));
            // editor.current.selection.save();
            setShowVariablesSelector(false);
        }
    }

    const config = useMemo(() => {
        return {
            key: process.env.REACT_APP_FROALA_KEY,
            attribution: false,
            charCounterCount: false,
            imageUploadMethod: "POST",
            events: {
                'paste.afterCleanup': function (html) {
                    return convertVariablesIntoHtml(html);
                },
                "image.beforeUpload": function(files) {
                    var editor = this;
                    if (files.length) {
                        // Create a File Reader.
                        var reader = new FileReader();
                        // Set the reader to insert images when they are loaded.
                        reader.onload = function(e) {
                            var result = e.target.result;
                            editor.image.insert(result, null, null, editor.image.get());
                        };
                        // Read image as base64.
                        reader.readAsDataURL(files[0]);
                    }
                    editor.popups.hideAll();
                    // Stop default upload chain.
                    return false;
                },
                'html.set': function(html) {
                    if (!isInsideSet.current) {
                        isInsideSet.current = true;
                        this.html.set(convertVariablesIntoHtml(this.html.get()));
                        isInsideSet.current = false;
                    }
                }
            },
            toolbarButtons: {
                moreText: {
                    buttons: [
                        'bold', 'italic', 'underline', 'clearFormatting', 'color', 'alignLeft', 'alignCenter', 'alignRight',
                        'alignJustify', 'formatOL', 'formatUL', 'outdent', 'indent', 'variables', 'insertPageBreak'
                    ],
                    align: 'left',
                    buttonsVisible: 20
                },
                more: {
                    buttons: [
                        'paragraphFormat', 'fontFamily', 'fontSize', 'lineHeight', 'insertLink', 'insertImage', 'insertTable',
                        'undo', 'redo', 'html', 'fullscreen'
                    ],
                    align: 'left',
                    buttonsVisible: 20
                }
            },
            paragraphFormatSelection: true,
            fontFamilySelection: true,
            fontSizeSelection: true,
            toolbarSticky: false,
            placeholder: props.placeholder || "",
            height: props.height || 250,
            enter: FroalaEditor.ENTER_BR,
            disablePlugins: ["page-break"],
            tableCellStyles: {
                'fr-bordered': 'Bordered',
            },
            uploader: {
                insertImageAsBase64URI: true
            }
        };
    }, [props.height, props.placeholder, props.tokens])
    const value = getValue(initialFormFields, props.name);
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations);
    return (
        <BaseForm.InputGroup label={props.label} disabled={props.disabled} description={props.description} borderless>
            <Controller
                control={control}
                name={props.name}
                rules={processedValidations}
                defaultValue={getValue(initialFormFields, props.name) || ""}
                render={({ field }) => {
                    // $( function() {
                    //     if (_.includes($('.fr-wrapper').children().first().text(), "Unlicensed")) {
                    //         $('.fr-wrapper').children().first().hide()
                    //     }
                    // });
                    return (
                        <div className="form-editor">
                            <FroalaEditor
                                tag='textarea'
                                config={config}
                                model={value || ""}
                                onModelChange={newContent => {
                                    updateFormFields(props.name, newContent);
                                    field.onChange(newContent);
                                }}
                            />
                        </div>
                    );
                }}
            />
            {
                showVariablesSelector &&
                    <EditorVariablesModal
                        variables={props.tokens}
                        x={rect.x}
                        y={rect.y}
                        onClose={() => {
                            setShowVariablesSelector(false);
                        }}
                        onVariableSelect={onVariableSelect}/>
            }
        </BaseForm.InputGroup>
    );
}
BaseForm.FroalaEditorGroup = FroalaEditorGroup;

const Rule = (props) => {
    const { initialFormFields, registerName, control, updateFormFields, errors } = useContext(BaseFormContext);
    registerName(props.name, "rule");
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const error = getValue(errors, props.name);
    const hasError = !_.isEmpty(error);
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, validations || {});
    let defaultValue = getValue(initialFormFields, props.name, null);
    return (
        <div className="form-group">
        {
            showLabel &&
                <Form.Label>{props.label}</Form.Label>
        }
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={defaultValue}
            render={({ field }) => {
                return (
                    <>
                        <div className={classnames(hasError ? "form-input error": "")}>
                            <RuleDisplay
                                propertyFields={props.propertyFields}
                                value={defaultValue}
                                propertyValues={props.propertyValues}
                                isColored={props.isColored || false}
                                onChange={(value) => {
                                    field.onChange(value);
                                    updateFormFields(props.name, value);
                                }}
                            />
                        </div>
                        {
                            hasError &&
                                <p className="form-error-message">{error.message}</p>
                        }
                    </>
                );
            }}
        />
        </div>
    );
}
const RuleGroup = (props) => {
    return (
        <BaseForm.Rule {...props} />
    );
}
BaseForm.Rule = Rule;
BaseForm.RuleGroup = RuleGroup;

const CheckboxRadio = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, errors } = useContext(BaseFormContext);
    registerName(props.name, props.type);
    const processedValidations = processValidations(props.name, props.errorLabel || props.label, props.validations);
    const error = getValue(errors, props.name);
    const hasError = !_.isEmpty(error);
    let isChecked = getValue(initialFormFields, props.name);
    if (props.type === "radio") {
        isChecked = String(getValue(initialFormFields, props.name)) === String(props.value);
    }
//    const label = props.type === "radio" ? props.value : props.label;
    return (
        <>
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
//            defaultValue={getValue(initialFormFields, props.name)}
            render={({ field }) => {
                return (
                    <Form.Check type={props.type || "radio"} className={classnames(props.className, hasError ? "error": "")}
                        value={props.value}
                        label={props.value}
                        checked={isChecked}
                        onChange={(event) => {
                            updateFormFields(props.name, getValueFromEvent(event));
                            field.onChange(getValueFromEvent(event));
                        }}
                        disabled={props.disabled}>
                        { props.children }
                    </Form.Check>
                );
            }}
        />
        </>
    );
}
BaseForm.CheckboxRadio = CheckboxRadio;
const CheckGroup = (props) => {
    return (
        <BaseForm.InputGroup disabled={props.disabled} formInputClassName={props.formInputClassName}>
            <BaseForm.CheckboxRadio {...props} type={props.type === "check" ? "checkbox": props.type} />
        </BaseForm.InputGroup>
    );
}
BaseForm.CheckGroup = CheckGroup;

const Divider = (props) => {
    return (
        <span className="input-group-text divider"></span>
    );
}
BaseForm.Divider = Divider;

const Label = (props) => {
    return (
        <Form.Label {...props}>
            { props.children }
        </Form.Label>
    );
}
BaseForm.Label = Label;

const Hidden = forwardRef((props, ref)  => {
    const controlField = useRef(null);
    useImperativeHandle(ref, () => ({
        updateControl(value) {
            if (controlField.current) {
                controlField.current.onChange(value);
            }
        },
    }));

    const { initialFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name, "hidden");
    return (
        <Controller
            control={control}
            name={props.name}
            defaultValue={getValue(initialFormFields, props.name)}
            render={({ field }) => {
                controlField.current = field;
                return (
                    <></>
                );
            }}
        />
    );
})
BaseForm.Hidden = Hidden;

export default BaseForm;
