import React, {useContext, useEffect, useMemo, useState} from "react";
import {BaseContext, UserContext} from "../../helpers/common";
import {serverPost} from "../../helpers/server";
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import InfiniteScroll from "react-infinite-scroll-component";
import Section from "../../components/Section";
import MapleTable from "../../components/MapleTable";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import SortableTableHeader from "../../components/SortableTableHeader";
import moment from "moment/moment";
import BillableEventModal from "../../components/modals/BillableEventModal";
import BaseCSVExportModal from "../../components/modals/BaseCSVExportModal";
import {getBillableEventsExportFields} from "../../helpers/exportFields";
import Notification from "../../components/Notification";
import {useSearchParams} from "react-router-dom";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
const _ = require('lodash');

function BillableEventsNew() {
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const [sort, setSort] = useState("createdAtDesc");
    const [fromKey, setFromKey] = useState(null);
    const [events, setEvents] = useState([]);
    const [meta, setMeta] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [showSideModal, setShowSideModal] = useState(false);
    const [filters, setFilters] = useState([]);
    const [showExportModal, setShowExportModal] = useState(false);
    const [ searchParams ] = useSearchParams();
    let subscriptionMetricId = searchParams.get('subscription_metric_id');
    const disableInitialFilters = subscriptionMetricId !== null;

    const cachedSelectedFilters = useMemo(() => {
        if (disableInitialFilters) {
            return null;
        }
        const cachedString = localStorage.getItem(company.id + "_filter_cache_billable_events");
        if (cachedString) {
            return JSON.parse(cachedString);
        }
    }, [company])
    const defaultSelectedFilters = useMemo(() => {
        return cachedSelectedFilters || { event_date: { GTE: moment().add(-7, 'days').utcOffset(0, true), LTE: moment().utcOffset(0, true) } }
    }, [])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    useEffect(() => {
        setPageTitle(`Billable Events`);
    }, []);

    useEffect(() => {
        setFilters([
            { title: "Event Date", type: "date", name: "event_date" }
        ])
    }, [])

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                ...selectedFilters,
                subscription_metric_record_id: subscriptionMetricId ? subscriptionMetricId : null
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/events/find_events"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setEvents(results);
                } else {
                    setEvents(_.concat(events, results));
                }
                if (res.meta) {
                    setMeta(res.meta);
                }

                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    const onParamsChange = (data) => {
        setSelectedFilters(data);
        if (!disableInitialFilters) {
            localStorage.setItem(company.id + "_filter_cache_billable_events", JSON.stringify(data));
        }
    }

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    const onRowClick = (event) => {
        setCurrentEvent(event);
        setShowSideModal(true);
    }

    const onViewSubscriptionClick = (event) => {
        const url = getCompanySpecificUrl(`/subscription/${event.metric_record.subscription_id}`)
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const getQueryParams = () => {
        return {
            ...selectedFilters,
            subscription_metric_record_id: subscriptionMetricId ? subscriptionMetricId : null
        }
    }

    const csvExportSuccessNotification = (res) => {
        if (res) {
            Notification.Success("The CSV export will be emailed to you shortly", 8000)
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                    dataLength={events.length}
                >
                    <Section
                        title={"Billable Events"}
                        subtitle={''}
                        loading={loading}
                    >
                        <MapleTable>
                            <MapleTableHeaderWithActions
                                showSearch={true}
                                searchPlaceholder={"Search Events"}
                                showFilters={true}
                                filters={filters}
                                meta={meta}
                                onParamsChange={onParamsChange}
                                defaultSelectedFilters={defaultSelectedFilters}
                                cachedSelectedFilters={cachedSelectedFilters}
                                showExport={true}
                                onExport={() => setShowExportModal(true)}
                            />
                            <div className="mt-1 text-xs text-gray-700">
                                Date/Time is based on your local timezone
                            </div>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <SortableTableHeader
                                        onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                                        currentSort={sort}>
                                        Ingested Time
                                    </SortableTableHeader>
                                    <MapleTable.TH>Transaction ID</MapleTable.TH>
                                    <MapleTable.TH>Item</MapleTable.TH>
                                    <MapleTable.TH>Customer</MapleTable.TH>
                                    <MapleTable.TH className={"d-none d-lg-table-cell"}>Properties</MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(events, (row, i) =>
                                        <MapleTable.TR key={i} className="cursor-pointer hover:bg-gray-100"
                                                       onClick={() => onRowClick(row)}>
                                            <td className="">
                                                <span>{moment(row.created_at).format("MMM D, YYYY h:mm:ss a")}</span>
                                            </td>
                                            <td>
                                                <div className={"flex flex-col"}>
                                                    {row.transaction_id.length > 20
                                                    ? <BaseOverlayTrigger
                                                        content={row.transaction_id}><span>{row.transaction_id.substring(0, 17) + "..."}</span></BaseOverlayTrigger>
                                                    : row.transaction_id}
                                                    <p className={"font-normal text-gray-400 whitespace-nowrap"}>{moment(row.timestamp).format("MMM D, YYYY h:mm:ss a")}</p>
                                                </div>
                                            </td>
                                            <td>{row.item.name}</td>
                                            <td>{row.customer.display_name}</td>
                                            <td className="d-none d-lg-table-cell">
                                                <div className="text-xs text-gray-500 max-w-md truncate">
                                                    <code>
                                                        {JSON.stringify(row.properties).length > 30
                                                            ? JSON.stringify(row.properties).substring(0, 27) + "..."
                                                            : JSON.stringify(row.properties)}
                                                    </code>
                                                </div>
                                            </td>
                                        </MapleTable.TR>
                                    )
                                }
                                {
                                    hasMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
            {
                currentEvent &&
                <BillableEventModal className={"shadow-xl"} show={showSideModal} onClose={setShowSideModal} billableEvent={currentEvent} size={"md"}/>
            }
            <BaseCSVExportModal
                show={showExportModal}
                onClose={setShowExportModal}
                sort={sort}
                useQuery={true}
                query={getQueryParams()}
                fields={getBillableEventsExportFields()}
                title={"Billable Events CSV Export"}
                filenamePrefix="BillableEvents-CSV"
                exportUrl={'/events/export'}
                noDownload={true}
                onNoDownloadSuccess={csvExportSuccessNotification}
                bodyText={<div>The exported CSV will have a <strong>maximum of 10,000 rows</strong>. The CSV will be sent to you in an email once exported.</div>}
            />
        </BaseContainer>
    )
}

export default BillableEventsNew;