import '../App.scss';
import React, {Fragment, useEffect, useState} from 'react';
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

function Notification(props) {
    const [show, setShow] = useState(false)
    const [delay, setDelay] = useState(2000)
    const [params, setParams] = useState({
        title: "Successfully saved!",
        message: "Anyone with a link can now view this file."
    })

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setShow(false);
            }, delay);
        }
    }, [show])

    useEffect(() => {
        function showNotification(notificationParams) {
            setShow(true);
            setDelay(notificationParams.detail.delay || 2000);
            setParams(notificationParams.detail);
        }

        window.addEventListener('notification.show', showNotification);
        return () => {
            window.removeEventListener('notification.show', showNotification);
        };
    }, []);

    return (
        <>
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-4 sm:items-start sm:p-6 z-20"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-3">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {
                                            params.type === "danger" ?
                                                <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                            : params.type === "warning" ?
                                                <ExclamationTriangleIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
                                            : <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                        }
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{ params.title }</p>
                                        <p className="mt-1 text-sm text-gray-500">{ params.message }</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setShow(false)
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}
Notification.Show = (title, type, delay=2000) => {
    const notificationParams = {
        detail: {
            type: type,
            title: title,
            delay: delay,
        }
    }
    window.dispatchEvent(new CustomEvent('notification.show', notificationParams));
}

Notification.Success = (message, delay=2000) => {
    Notification.Show(message, 'success', delay)
}

Notification.Warning = (message, delay=2000) => {
    Notification.Show(message, 'warning', delay)
}

Notification.Danger = (message, delay=2000) => {
    Notification.Show(message, 'danger', delay)
}

export default Notification;
