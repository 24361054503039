import '../../App.scss';
import '../../css/modals.scss';
import { Modal, Alert } from 'react-bootstrap';
import React, {createContext, useContext, useEffect, useState} from 'react';
import { renderChildren } from '../../helpers/common';
import {Dialog} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";

export const ModalContext = createContext();

const BaseModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = (event) => {
        if (event) {
            event.stopPropagation()
        }
        setShow(false);
        if (props.onClose) {
            props.onClose(false);
        }
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <ModalContext.Provider value={{ handleClose }}>
            <Modal show={show} onHide={handleClose} size={props.size || "md"} className={"max-w-full !inline-flex"}>
                { show && renderChildren(props) }
            </Modal>
        </ModalContext.Provider>
    );
}

const Header = (props) => {
    const { handleClose } = useContext(ModalContext);
    return (
        <Modal.Header>
            <div className="flex items-center gap-3 w-full">
                <div className="grow">
                    { props.children }
                </div>
                <div className="flex h-7 items-center">
                    <button
                        type="button"
                        className="relative text-gray-400 hover:text-gray-500"
                        onClick={() => handleClose()}
                    >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </Modal.Header>
    );
}
BaseModal.Header = Header;

const Body = (props) => {
    return (
        <Modal.Body className={props.className}>
            <div className="notification-modal ahide noprint hide">
                <Alert variant="warning">
                    Warning message
                </Alert>
            </div>
            { props.children }
        </Modal.Body>
    );
}
BaseModal.Body = Body;

const Title = (props) => {
    return (
        <Modal.Title>
            <h4>{ props.children }</h4>
        </Modal.Title>
    );
}
BaseModal.Title = Title;

const Footer = (props) => {
    return (
        <Modal.Footer>
            { props.children }
        </Modal.Footer>
    );
}
BaseModal.Footer = Footer;

export default BaseModal;
