import '../../App.scss';
import '../../css/modals.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle} from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Row, Col } from 'react-bootstrap';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext, currencyFormatFromPrice } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
const _ = require('lodash');

const StripeInvoicePaymentModal = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        setErrorFields(errors) {
            setErrorFields(errors);
        },
    }));

    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [errorFields, setErrorFields] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [setupIntent, setSetupIntent] = useState(null);

    useEffect(() => {
        if (props.show) {
            setInvoice(props.invoice);
        } else {
            setInvoice(null);
        }

        if (!props.show) {
            return;
        }

        const setupData = {
            currency: props.invoice.currency
        }
        serverFetch(getApiUrl(`/invoices/${props.invoice.uuid}/setup_intent`), setupData).then((res) => {
            if (res) {
                setSetupIntent(res);
            }
        })
    }, [props.show, props.invoice])

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    const onPay = async (paymentMethod, paymentAmountInCents) => {
        const paymentMethodData = {
            stripe: {
                payment_method_id: paymentMethod
            }
        }
        if (props.onPay) {
            await props.onPay(paymentMethodData, paymentAmountInCents);
        }
    }

    if (!props.show) {
        return
    }

    const publishableKey = settings.payment_provider_live_mode ?
        process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY
        : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;
    const stripePromise = setupIntent && loadStripe(publishableKey, {
        stripeAccount: setupIntent && setupIntent.provider_account_id
    });
    const options = setupIntent && {
        clientSecret: setupIntent.intent_secret,
        appearance: {/*...*/},
    };

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{t('invoice.payment.title')}</BaseModal.Title>
            </BaseModal.Header>
            {
                (_.isNil(invoice) || _.isNil(setupIntent)) ?
                    <BaseModal.Body>
                        <div className="spinner-border text-secondary"/>
                    </BaseModal.Body>
                :   <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm invoice={invoice} settings={settings} onPay={onPay} errorFields={errorFields}/>
                    </Elements>
            }
            {
                errorFields &&
                    <div className="form-error-message">{ errorFields.error_message }</div>
            }
        </BaseModal>
    );
})

function CheckoutForm(props) {
    const stripe = useStripe();
    const { company } = useContext(BaseContext);
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [initialFields, setInitialFields] = useState({});
    const [invoice, setInvoice] = useState(null);
    const [settings, setSettings] = useState(props.settings);

    useEffect(() => {
        if (_.isNil(props.invoice)) {
            setRedirectUrl(null);
            return;
        }
        const company = props.invoice.company;
        const domain = window.location.origin;
        const url = `${domain}/c/${company.id}/invoice/view?uuid=${props.invoice.uuid}`
        setRedirectUrl(url);
        setInvoice(props.invoice);
        setInitialFields({
            amount: props.invoice.due.value_in_cents/100
        })
    }, [props.invoice])

    useEffect(() => {
        setSettings(props.settings)
    }, [props.settings])

    const onSubmit = async (data) => {
        if (!stripe || !elements) {
            return;
        }
        setErrorMessage(null);

        const {error: submitError} = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
            return;
        }

        const {error, setupIntent} = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: redirectUrl,
            },
            redirect: "if_required"
        });

        if (error) {
            setErrorMessage(error.message);
        } else if (setupIntent) {
            if (props.onPay) {
                await props.onPay(setupIntent.payment_method, data.amount*100)
            }
        }
    };

    const allowPartialPayments = (settings && settings.invoice_options && settings.invoice_options.allow_partial_payments) || false;
    return (
        <>
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit} errorFields={props.errorFields}>
                <BaseModal.Body>
                    <div className="body1">
                        Processing payment for invoice ({ props.invoice.number }) for the amount { currencyFormatFromPrice(props.invoice.due) }
                    </div>
                    {
                        allowPartialPayments &&
                            <BaseForm.Input
                                colSpan={6} name="amount" label="Amount" type="number" step="0.01" min="0.01"
                                max={invoice && invoice.due.value_in_cents/100} validations={{ required: true, min: 0.01, max: invoice && invoice.due.value_in_cents/100}} />
                    }
                    <div>
                        <PaymentElement />
                        {errorMessage && <div className="form-error-message">{errorMessage}</div>}
                    </div>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Process Payment</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </>
    )
}

export default StripeInvoicePaymentModal;
