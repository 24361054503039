import '../App.scss';
import '../js/forms.js';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseContext, UserContext } from '../helpers/common';
import BaseLeftNav from '../components/BaseLeftNav';

function LeftNav(props) {
    const { userInfo, isMapleUser } = useContext(UserContext);
    const { getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const { t } = useTranslation('common');

    const items = [
        hasAccess("reports", userInfo, "read") && { label: t('common.dashboard'), link: getCompanySpecificUrl("/"), icon: "dashboard-icon" },
        hasAccess("customers", userInfo, "read") && { label: t('common.customers'), link: getCompanySpecificUrl("/customers"), icon: "customers-icon" },
        hasAccess("subscriptions", userInfo, "read") && { label: t('common.subscriptions'), link: getCompanySpecificUrl("/subscriptions"), icon: "subscription-icon" },
        hasAccess("proposal", userInfo, "read") && { label: t('common.contracts'), link: getCompanySpecificUrl("/contracts"), icon: "contracts-icon" },
        hasAccess("invoices", userInfo, "read") && { label: t('common.invoices'), link: getCompanySpecificUrl("/invoices"), icon: "invoice-icon" },
        hasAccess("payments", userInfo, "read") && { label: t('common.payments'), link: getCompanySpecificUrl("/payments"), icon: "payments-icon", hasSubMenu: true,
            items: [
                { label: t('tabs.payments.overview'), link: getCompanySpecificUrl("/payments") },
                { label: t('tabs.payments.recovery'), link: getCompanySpecificUrl("/campaigns") },
            ]
        },
        hasAccess("pricing", userInfo, "read") && { label: t('tabs.pricing'), link: getCompanySpecificUrl("/billables"), hasSubMenu: true, icon: "billables-icon",
            items: [
                { label: t('tabs.pricing.items'), link: getCompanySpecificUrl("/billable_items") },
                //{ label: t('tabs.pricing.pricings'), link: getCompanySpecificUrl("/billable_item_pricings") },
                { label: t('tabs.pricing.metrics'), link: getCompanySpecificUrl("/billable_metrics") },
                { label: t('tabs.pricing.objects'), link: getCompanySpecificUrl("/objects") },
            ]},
        hasAccess("pricing", userInfo, "read") && { label: t('common.products'), link: getCompanySpecificUrl("/products"), icon: "products-icon", hasSubMenu: true,
            items: [
                { label: t('tabs.products.overview'), link: getCompanySpecificUrl("/products") },
                { label: t('tabs.products.bundles'), link: getCompanySpecificUrl("/bundles") },
                { label: t('common.coupons'), link: getCompanySpecificUrl("/coupons") },
                { label: t('tabs.products.payment_links'), link: getCompanySpecificUrl("/payment_links") },
                { label: t('tabs.entitlements'), link: getCompanySpecificUrl("/entitlements") },
            ]},
        hasAccess("reports", userInfo, "read") && { label: t('common.reports'), link: getCompanySpecificUrl("/metrics"), icon: "reports-icon", hasSubMenu: true,
            items: [
                { label: t('tabs.reports.analytics'), link: getCompanySpecificUrl("/metrics") },
                { label: t('tabs.reports.mrr_log'), link: getCompanySpecificUrl("/mrr_logs") },
                { label: t('tabs.reports.credit_logs'), link: getCompanySpecificUrl("/credits_log") },
                { label: t('tabs.reports.financials'), link: getCompanySpecificUrl("/reports") },
            ]},
        false && hasAccess("reports", userInfo, "read") && { label: t('common.activity'), link: getCompanySpecificUrl("/activity"), icon: "activity-icon" },
        hasAccess("integrations", userInfo, "read") && { label: t('common.integrations'), link: getCompanySpecificUrl("/integrations"), icon: "integrations-icon" },
        hasAccess("settings", userInfo, "read") && { label: t('common.settings'), link: getCompanySpecificUrl("/settings"), icon: "settings-icon", hasSubMenu: true,
            items: [
                { label: t('tabs.settings.overview'), link: getCompanySpecificUrl("/settings") },
                { label: t('tabs.settings.invoices'), link: getCompanySpecificUrl("/settings/invoices") },
                { label: t('tabs.settings.contracts'), link: getCompanySpecificUrl("/settings/contracts") },
                { label: t('tabs.settings.customer_portal'), link: getCompanySpecificUrl("/settings/customer_portal") },
                { label: t('tabs.settings.taxes'), link: getCompanySpecificUrl("/settings/taxes") },
                { label: t('tabs.settings.team'), link: getCompanySpecificUrl("/settings/team") },
                isMapleUser && { label: t('tabs.settings.imports'), link: getCompanySpecificUrl("/settings/imports") },
            ]},
        hasAccess("developers", userInfo, "read") && { label: t('tabs.developers.title'), link: getCompanySpecificUrl("/developers"), hasSubMenu: true, icon: "developer-icon",
            items: [
                { label: t('tabs.developers.api_tokens'), link: getCompanySpecificUrl("/developers/api_tokens") },
                { label: t('tabs.developers.webhooks'), link: getCompanySpecificUrl("/developers/webhooks") },
                { label: t('tabs.developers.events'), link: getCompanySpecificUrl("/developers/events") },
                { label: t('tabs.developers.requests'), link: getCompanySpecificUrl("/developers/requests") },
            ]},
    ];

    return (
        <BaseLeftNav items={items} {...props} />
    );
}

export default LeftNav;
