import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch } from '../../helpers/server';
import {
    BaseContext,
    currencyFormatFromPrice,
    getCustomerNameOrEmail,
    getNameForProviderType, getPaymentMethodDescription, UserContext
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import ContentContainer from '../../components/ContentContainer';
import Label from '../../components/Label';
import Loader from '../../components/Loader';
import RefundPaymentModal from '../../components/modals/RefundPaymentModal';
import moment from 'moment';
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../components/DropdownMenu";
import ErrorComponent from "../../components/ErrorComponent";
import {renderTypeLabel} from "../../helpers/payments";
import IntegrationReferenceEntitySummaryModal from "../../components/modals/IntegrationReferenceEntitySummary";
const _ = require('lodash');

function Payment(props) {
    const { t } = useTranslation('common');
    const { isMapleUser } = useContext(UserContext);
    const { paymentId } = useParams();
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle } = useContext(BaseContext);
    const [showRefundPaymentModal, setShowRefundPaymentModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(true);
    const [payment, setPayment] = useState({});
    const [details, setDetails] = useState({});

    useEffect(() => {
        setPageTitle(`Payment`);
    }, [payment]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/payments/transactions/${paymentId}`), { skipCache }).then((res) => {
            if (res) {
                setPayment(res);
            }
            setLoadingPayment(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(payment)) {
            return;
        }
        const data = {
            'Customer': <Link to={getCompanySpecificUrl(`/customer/${payment.customer.id}`)}>{getCustomerNameOrEmail(payment.customer)}</Link>,
            'ID': payment.id,
            'Type': renderTypeLabel(payment),
            'Status': renderPaymentStatus(),
            ...(payment.status_message ? { 'Failure Details': payment.status_message } : {}),
            'Provider': getNameForProviderType(payment.provider_type),
            'Date': moment(payment.created_at).format("DD MMM, YYYY h:mm:ssa"),
            'Invoice': <Link to={getCompanySpecificUrl(`/invoice/${payment.invoice_uuid}`)}>{ payment.invoice_number }</Link>,
            'Total Amount': currencyFormatFromPrice(payment.total_amount),
        };
        if (payment.status === "SUCCESS") {
            data['Fees'] = currencyFormatFromPrice(payment.stripe_fee_amount)
            data['Net Amount'] = currencyFormatFromPrice(payment.net_amount)
        }
        if (payment.connected_account_transaction) {
            data['Application Fees'] = currencyFormatFromPrice(payment.application_fee_amount)
        }
        data['Is Connected Account Transaction'] = payment.connected_account_transaction ? "Yes": "No"
        data['Note'] = payment.note
        setDetails(data);
    }, [payment]);

    const onModalClose = (didUpdate=false) => {
        setShowRefundPaymentModal(false);
        setShowReferenceSummaryModal(false);
        fetchData(didUpdate);
    }

    const onActionSelected = (type) => {
        if (type === "refund") {
            setShowRefundPaymentModal(true);
        } else if (type === "references") {
            setShowReferenceSummaryModal(true);
        }
    }

    const canRefund = _.includes(["SUCCESS", "PARTIAL_REFUNDED"], payment.status) && payment.is_refundable;
    const showReferences = isMapleUser && _.includes(["SUCCESS", "PARTIAL_REFUNDED"], payment.status);
    const actionOptions = [
        canRefund && { id: "refund", label: "Refund" },
        showReferences && { id: "references", label: "References" },
    ];

    const renderActions = () => {
        if (_.isEmpty(actionOptions)) {
            return null;
        }
        return (
            <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>
        )
    }

    const renderPaymentStatus = () => {
        if (payment.status === "SUCCESS") {
            return <Label.Success>Succeeded</Label.Success>
        } else if (payment.status === "PROCESSING") {
            return <Label.Warning>Processing</Label.Warning>
        } else if (_.includes(["PARTIAL_REFUNDED", "REFUNDED", "CANCELLED", "PROCESSING"], payment.status)) {
            const mmap = {
                "PARTIAL_REFUNDED": "Partially Refunded",
                "REFUNDED": "Refunded",
                "CANCELLED": "Cancelled",
                "PROCESSING": "Processing"
            }
            return <Label.Info>{ mmap[payment.status] }</Label.Info>
        } else if (payment.status === "FAILED") {
            return <Label.Danger>Failed</Label.Danger>
        }
        return <Label.Info>{ payment.status }</Label.Info>
    }

    return (
        <BaseContainer>
            <Loader loading={loadingPayment}>
            {
                _.isEmpty(payment) ?
                    <ErrorComponent
                        title={"Payment Not Found"}
                        description={"Oops, we can't find the payment you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/payments"), label:"Show all payments" }}
                        hideSupport
                    />
                : <ContentContainer title={
                        <div className="d-flex flex-row gap-3">
                            <span>Payment for {payment.customer && (payment.customer.name || payment.customer.email)}</span>
                        </div>
                    } customActions={ renderActions() }>
                    <KeyValueDisplay
                        items={details}
                    />
                </ContentContainer>
            }
            </Loader>
            <RefundPaymentModal show={showRefundPaymentModal} onClose={onModalClose} payment={payment} />
            <IntegrationReferenceEntitySummaryModal
                show={showReferenceSummaryModal} onClose={onModalClose} entityKey={"PAYMENT"}
                referenceID={paymentId}
            />
        </BaseContainer>
    );
}

export default Payment;
