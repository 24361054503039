import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverFetch, serverPost} from '../../helpers/server';
import {BaseContext, getCurrentCompanyUser, UserContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Metric from '../../components/Metric';
import Section from '../../components/Section';
import Checklist from '../../components/Checklist';
import { Row, Col } from 'react-bootstrap';
import GoalProgress from "../../components/GoalProgress";
import ApprovalRequestListSection from "../../components/ApprovalRequestListSection";
import { useNavigate } from 'react-router-dom';
const _ = require('lodash');

function CompanyHome() {
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, hasAccess, getCompanySpecificUrl } = useContext(BaseContext);
    const [checklistItems, setChecklistItems] = useState([]);
    const [approvalRequests, setApprovalRequests] = useState([]);

    const hasMetricsPermission = hasAccess("reports", userInfo, "read");
    const hasApprovalsPermission = hasAccess("proposal", userInfo, "read");

    useEffect(() => {
        if (!hasMetricsPermission) {
            // restriced access for most components on dashboard, redirect to customers page until we have custom dashboard for roles
            navigate(getCompanySpecificUrl("/customers"))
        }
    }, [hasMetricsPermission])

    useEffect(() => {
        setPageTitle("Dashboard");
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/checklist")).then((res) => {
            setChecklistItems(res);
        });
    }, []);

    useEffect(() => {
        const companyUser = getCurrentCompanyUser(company.id, userInfo);
        if (!companyUser) {
            return;
        }
        const requestData = {
            query: {
                company_user_id: companyUser.id,
                statuses: ["NEEDS_APPROVAL"]
            }
        }
        serverPost(getApiUrl(`/approval_requests/find`), requestData).then((res) => {
            if (res) {
                setApprovalRequests(res);
            }
        });
    }, []);

    return (
        <BaseContainer>
            <ContentContainer title={`${company.name}`}>
                {
                    hasMetricsPermission &&
                        <GoalProgress variant="success" />
                }
                <Section title="Get Started" className={"hide"}>
                    <Checklist checklistItems={checklistItems} />
                </Section>
                {
                    hasApprovalsPermission &&
                        <ApprovalRequestListSection approvalRequests={approvalRequests} redirectActions/>
                }
                {
                    hasMetricsPermission &&
                        <Row className="gy-3 gx-3">
                            <Col lg="12">
                                <Metric
                                    metricKey="MRR"
                                    chartType="line"
                                    label="Monthly Recurring Revenue"
                                    yLabel="MRR"
                                    type="currency"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="ACTIVE_CUSTOMERS"
                                    chartType="line"
                                    label="Active Customers"
                                    yLabel="Customers"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="ACTIVE_SUBS"
                                    chartType="line"
                                    label="Active Subscriptions"
                                    yLabel="Subscriptions"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="NEW_CUSTOMERS"
                                    chartType="bar"
                                    label="New Customers"
                                    yLabel="Customers"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="NEW_SUBS"
                                    chartType="bar"
                                    label="New Subscriptions"
                                    yLabel="Subscriptions"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                        </Row>
                }
            </ContentContainer>
        </BaseContainer>
    );
}

export default CompanyHome;
