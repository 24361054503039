import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import {
    BaseContext, currencyFormatFromPrice,
    getDescriptionForMetric, getLabelForSubscriptionLength, getNumberOfMonths,
    renderDescriptionForItemPricing, updateFormField
} from '../../helpers/common';
import {serverPatch, serverPost} from '../../helpers/server';
import {Row, Col, Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MapleTable from "../MapleTable";
import BaseSideModal from "./BaseSideModal";
import SubmitButton from "../SubmitButton";
const _ = require('lodash');

function EditRecognitionScheduleModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [type, setType] = useState("EVENLY");
    const [itemPricing, setItemPricing] = useState(null);

    const [initialFields, setInitialFields] = useState(null);

    useEffect(() => {
        setItemPricing(props.itemPricing);
        if (!_.isNil(props.itemPricing)) {
            setType(props.itemPricing.recognition_schedule.recognition_schedule_type);
            setInitialFields({
                ...props.itemPricing.recognition_schedule,
                amount_per_period: _.mapValues(props.itemPricing.recognition_schedule.amount_per_period, v => v/100)
            });
        }
    }, [props.itemPricing]);

    const onFieldChange = (name, value) => {
        if (name === "recognition_schedule_type") {
            setType(value);
        }
        setInitialFields(prevFields => {
            return updateFormField({...prevFields}, name, value)
        })
    }

    const updateSchedule = (data) => {
        const updateData = {
            recognition_schedule: {
                recognition_schedule_type: data.recognition_schedule_type,
            }
        }
        if (data.recognition_schedule_type === "CUSTOM") {
            updateData.recognition_schedule.amount_per_period = _.map(_.values(data.amount_per_period), v => parseFloat(v)*100);
        }
        serverPatch(getApiUrl(`/item_pricings/${itemPricing.id}`), updateData).then((res) => {
            if (res) {
                props.onClose();
            }
        });
    }

    const getRecognitionAmountsTotal = (recognitionAmounts) => {
        if (recognitionAmounts) {
            return _.sumBy(_.values(recognitionAmounts), v => parseFloat(v)*100);
        }
        return 0
    }

    if (!props.show) {
        return;
    }

    const typeOptions = [
        { value: "IMMEDIATE", label: "Immediate" },
        { value: "EVENLY", label: "Evenly" },
        { value: "CUSTOM", label: "Custom" },
    ]

    const numberOfMonths = itemPricing ? getNumberOfMonths(itemPricing.frequency, itemPricing.term_count): 1;
    return (
        <>
            <BaseSideModal {...props} size="lg">
                <BaseSideModal.Form
                    initialFormFields={initialFields}
                    onFieldChange={onFieldChange}
                    onSubmit={updateSchedule}
                >
                    <BaseSideModal.Header title="Update Recognition Schedule" />
                    <BaseSideModal.Body>
                        <p className="body2">Price Details</p>
                        <MapleTable>
                            <MapleTable.Content>
                                <tbody>
                                    <tr>
                                        <td>
                                            { itemPricing && renderDescriptionForItemPricing(itemPricing, true, itemPricing.item) }
                                        </td>
                                    </tr>
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                        <Row>
                            <BaseForm.Input
                                colSpan="6" name="recognition_schedule_type" label="Recognition Type" type="select"
                                options={typeOptions} showSearch={false}
                            />
                        </Row>
                        {
                            type === "CUSTOM" &&
                            <>
                                <p className="text-sm font-semibold">Payment Schedule</p>
                                <Row>
                                    <Col md="5">
                                        <Table borderless className="no-background">
                                            <tbody>
                                            {
                                                _.map(_.range(parseInt(numberOfMonths)+1), (c, j) =>
                                                    <tr key={j}>
                                                        <td className="text-sm text-gray-500 !p-1">
                                                            {
                                                                j === 0 ?
                                                                    <span className="text-sm text-gray-500">Initial</span>
                                                                    : j === parseInt(12) ?
                                                                        <span className="text-sm text-gray-500">End of term</span>
                                                                        : <span className="text-sm text-gray-500">{_.startCase(getLabelForSubscriptionLength("MONTH"))} {j}</span>
                                                            }
                                                        </td>
                                                        <td className="!p-1">
                                                            <BaseForm.Input
                                                                colSpan="12" name={`amount_per_period.${j}`}
                                                                label={"Period"} type="number" hideLabel placeholder={`Month` ? `${j}` : null}
                                                                required formClassName="inline" outerInputClassName="grow"
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            <tr>
                                                <td className="!p-1">
                                                    <span className="text-sm text-gray-700 font-semibold">Total</span>
                                                </td>
                                                <td className="!p-1">
                                            <span className="text-sm text-gray-700 font-semibold">
                                                { currencyFormatFromPrice({value_in_cents: getRecognitionAmountsTotal(initialFields.amount_per_period), currency: initialFields.currency })}
                                            </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                        }
                    </BaseSideModal.Body>
                    <BaseSideModal.Footer>
                        <SubmitButton variant="primary">Update</SubmitButton>
                    </BaseSideModal.Footer>
                </BaseSideModal.Form>
            </BaseSideModal>
        </>
    );
}

export default EditRecognitionScheduleModal;
