import '../../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {serverDelete, serverFetch, serverPatch, serverPost} from '../../../helpers/server';
import {
    BaseContext, IntegrationContext, UserContext
} from '../../../helpers/common';
import Label from "../../../components/Label";
import ContentContainer from '../../../components/ContentContainer';
import Section from '../../../components/Section';
import MapleTable from '../../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import MapleTableHeaderWithActions from "../../../components/MapleTableHeaderWithActions";
import SectionNav from "../../../components/SectionNav";
import UpdateIntegrationReferenceModal from "../../../components/modals/UpdateIntegrationReferenceModal";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import classnames from "classnames";
import {HandRaisedIcon} from "@heroicons/react/20/solid";
import ContentBox from "../../../components/ContentBox";
import moment from 'moment';
import {renderMapleEntity, renderRemoteData} from "../../../helpers/integrations";
import MoreButton from "../../../components/MoreButton";
import SimpleModal from "../../../components/modals/SimpleModal";
const _ = require('lodash');

function IntegrationReferences() {
    const navigate = useNavigate();
    const { isMapleUser } = useContext(UserContext);
    let { feature, entity } = useParams();
    entity = _.replace(_.upperCase(entity), " ", "_");
    feature = _.replace(_.upperCase(feature), " ", "_");
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [references, setReferences] = useState([]);
    const [referenceToEdit, setReferenceToEdit] = useState(null);
    const [settings, setSettings] = useState({});
    const [showUpdateIntegrationReferenceModal, setShowUpdateIntegrationReferenceModal] = useState(false);
    const [showUnlinkConfirmationModal, setShowUnlinkConfirmationModal] = useState(false);
    const [referenceToUnlink, setReferenceToUnlink] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");
    const [meta, setMeta] = useState({});
    const [query, setQuery] = useState(null);
    const [ syncPreview, setSyncPreview ] = useState({});

    useEffect(() => {
        setPageTitle(`Integration Sync`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })

        fetchSyncPreview(true);
    }, []);

    useEffect(() => {
        setLoading(true);
        setReferences([]);
    }, [entity]);

    const fetchSyncPreview = (skipCache=false) => {
        const previewData = {
            feature: feature
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/syncs/preview`), previewData,{ skipCache }).then((res) => {
            if (res) {
                setSyncPreview(res[feature.toLowerCase()])
            }
        })
    }

    const onSearch = (restart = true) => {
        const limit = 25;
        const params = {
            company_id: company.id,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                feature: feature,
                reference_type: entity,
                search: query
            },
            include_meta: restart
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/references/find`), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setReferences(results);
                    setMeta(res.meta);
                } else {
                    setReferences(_.concat(references, results));
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [entity, sort, query]);

    const updateReference = (row) => {
        setReferenceToEdit(row);
        setShowUpdateIntegrationReferenceModal(true);
    }

    const addNewReference = () => {
        setReferenceToEdit(null);
        setShowUpdateIntegrationReferenceModal(true);
    }

    const unmatchReference = async (row) => {
        const mappingData = {
            integration_reference_id: row.id,
            remote_id: null
        }
        const result = await serverPatch(getApiUrl(`/integrations/${row.integration.id}/references/${row.id}`), mappingData)
        if (result) {
            Notification.Success("Unmatched Successfully");
            onSearch(true)
        }
    }

    const ignoreReference = async (row) => {
        const mappingData = {
            integration_reference_id: row.id,
            state: "IGNORED"
        }
        const result = await serverPatch(getApiUrl(`/integrations/${row.integration.id}/references/${row.id}`), mappingData)
        if (result) {
            Notification.Success("Unmatched Successfully");
            onSearch(true)
        }
    }

    const deleteReference = async (row) => {
        const result = await serverDelete(getApiUrl(`/integrations/${row.integration.id}/references/${row.id}`));
        if (result) {
            Notification.Success("Deleted Successfully");
            onSearch(true)
        }
    }

    const onModalClose = (didUpdate) => {
        setShowUpdateIntegrationReferenceModal(false);
        if (didUpdate) {
            onSearch(true);
        }
    }

    const tabItems = [{
        'label': 'Customers',
        'href': getIntegrationSpecificUrl(`/${feature.toLowerCase()}/references/customer`),
        'id': 'customers',
    }, {
        'label': 'Invoices',
        'href': getIntegrationSpecificUrl(`/${feature.toLowerCase()}/references/invoice`),
        'id': 'invoices',
    }]

    const onParamsChange = (data) => {
        setQuery(data.search);
    }

    const onMoreActionSelected = (action, ref) => {
        if (action === "unlink") {
            setShowUnlinkConfirmationModal(true);
            setReferenceToUnlink(ref);
        } else if (action === "match") {
            updateReference(ref);
        } else if (action === "ignore") {
            ignoreReference(ref);
        } else if (action === "delete") {
            deleteReference(ref);
        }
    }

    const renderActions = (row) => {
        if (!_.includes(["CUSTOMER", "INVOICE"], entity)) {
            return;
        }
        if (row.state === "IGNORED") {
            let actionOptions = [
                { id: "delete", label: "Delete", icon: "fa fa-trash", className: "error" },
            ]
            return (
                <div className="flex flex-row justify-end items-center">
                    <Button variant="primary" onClick={() => pushIntegrationReference(row)}>Push</Button>
                    <MoreButton items={actionOptions} onSelect={(action) => onMoreActionSelected(action, row)} />
                </div>
            )
        } else if (row.state === "PENDING_REMOTE_CREATE" || row.state === "NEEDS_MAPPING") {
            if (integration.status === "ACTIVE") {
                // If the integration is active, make pushing the primary action
                let actionOptions = [
                    { id: "match", label: "Match", icon: "fa fa-handshake" },
                    { id: "ignore", label: "Ignore", icon: "fa fa-link-slash" },
                ]
                return (
                    <div className="flex flex-row justify-end items-center">
                        <Button variant="primary" onClick={() => pushIntegrationReference(row)}>Push</Button>
                        <MoreButton items={actionOptions} onSelect={(action) => onMoreActionSelected(action, row)} />
                    </div>
                )
            } else {
                // If the integration is not active/still onboarding, make mapping the primary action
                let actionOptions = [
                    { id: "ignore", label: "Ignore", icon: "fa fa-link-slash" },
                ]
                return (
                    <div className="flex flex-row justify-end items-center">
                        <Button variant="text-primary" onClick={() => updateReference(row)}>Match</Button>
                        <MoreButton items={actionOptions} onSelect={(action) => onMoreActionSelected(action, row)} />
                    </div>
                )
            }
        } else if (row.state === "CONFLICT") {
            let actionOptions = [
                { id: "delete", label: "Delete", icon: "fa fa-trash", className: "error" },
            ]
            return (
                <div className="flex flex-row justify-end items-center">
                    <Button variant="text-primary" onClick={() => updateReference(row)}>Resolve</Button>
                    <MoreButton items={actionOptions} onSelect={(action) => onMoreActionSelected(action, row)} />
                </div>
            )
        } else if (row.state === "ACTIVE") {
            let actionOptions = [
                { id: "unlink", label: "Unlink", icon: "fa fa-link-slash" },
            ]
            return (
                <div className="flex flex-row justify-end items-center">
                    <MoreButton items={actionOptions} onSelect={(action) => onMoreActionSelected(action, row)} />
                </div>
            )
        }
    }

    const renderState = (row) => {
        if (row.state === "PENDING_REMOTE_CREATE") {
            return <Label.Info>Missing in {integration.name}</Label.Info>
        } else if (row.state === "ACTIVE") {
            return <Label.Success>Matched</Label.Success>
        } else if (row.state === "CONFLICT") {
            return <Label.Danger>Conflict</Label.Danger>
        } else if (row.state === "NEEDS_MAPPING") {
            return <Label.Info>Unmatched</Label.Info>
        } else if (row.state === "NEEDS_UPDATE") {
            return <Label.Warning>Pending Update</Label.Warning>
        } else if (row.state === "IGNORED") {
            return <Label.Info>Ignored</Label.Info>
        } else {
            return <Label.Info>{row.state}</Label.Info>
        }
    }

    const pushIntegrationReference = async (ref) => {
        const data = {
            feature: ref.feature,
            sync_type: "PUSH",
            entity: ref.reference_type,
            entity_id: ref.reference_id,
        }
        const result = await serverPost(getApiUrl(`/integrations/${integration.id}/trigger_sync`), data, {});
        if (result) {
            Notification.Success("Initiated a push.")

        }
    }

    const triggerSync = async () => {
        const params = {
            feature: feature,
            entity: entity,
            sync_type: "INITIAL"
        }
        const res = await serverPost(getApiUrl(`/integrations/${integration.id}/trigger_sync`), params)
        if (res) {
            onSearch(true);
        }
    }

    const renderSyncPreview = () => {
        if (_.isNil(syncPreview) || _.isEmpty(syncPreview)) {
            return null;
        }
        const invoiceCount = syncPreview.invoices ? syncPreview.invoices.length: 0;
        if (invoiceCount < 1) {
            return null;
        }
        return <ContentBox>
            <ContentBox.Body>
                <div className="flex flex-col gap-2">
                    <span className="text-sm font-semibold">Next Sync Preview</span>
                    <span className="text-sm">There are {invoiceCount} invoices with updates that are yet to be pushed.</span>
                    <span className="text-xs text-gray-400 italic hide">Next scheduled push: {moment().format("MMM D, YYYY h:mm:ssa")}.</span>
                </div>
            </ContentBox.Body>
        </ContentBox>
    }

    const hasLoadedWithoutQuery = !loading && _.isEmpty(query) && !hasMore;
    const showInitialSync = integration.status === "SETUP_INCOMPLETE" && hasLoadedWithoutQuery && (references.length === 0 || !_.some(references, r => r.state === "NEEDS_MAPPING"));
    return (
        <ContentContainer>
            <InfiniteScroll
                dataLength={references.length}
                next={() => onSearch(false)}
                hasMore={hasMore}
                scrollableTarget="content-wrapper"
            >
                {
                    isMapleUser ?
                        <Section title="">
                            { renderSyncPreview() }
                            <SectionNav size="sm" items={tabItems} shouldAutoMatch={true} />
                            <Loader loading={loading}>
                                {
                                    showInitialSync &&
                                    <div className="flex flex-row justify-end pt-2">
                                        <Button variant="primary" onClick={() => triggerSync()}>Initial Sync</Button>
                                    </div>
                                }
                                <MapleTable className="mt-2">
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search References"
                                        showFilters={false}
                                        meta={meta}
                                        onParamsChange={onParamsChange}
                                    >
                                        <div className="flex flex-row items-center gap-2">
                                            <div className="w-px h-[30px] bg-slate-300"/>
                                            <div className="cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={addNewReference}>
                                                <span className="text-slate-900">Add New</span>
                                            </div>
                                        </div>
                                    </MapleTableHeaderWithActions>
                                    <MapleTable.Content  overflow>
                                        <thead>
                                        <tr>
                                            <MapleTable.TH>Name</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                            <MapleTable.TH>{integration.name}</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(references, (row, i) =>
                                                <tr key={i}>
                                                    <td>{ renderMapleEntity(row, entity, getCompanySpecificUrl) }</td>
                                                    <td>{ renderState(row) }</td>
                                                    <td>{ renderRemoteData(row, integration) }</td>
                                                    <td className="w-px whitespace-nowrap">{ renderActions(row) }</td>
                                                </tr>
                                            )
                                        }
                                        {
                                            hasMore &&
                                            <tr>
                                                <td colSpan="10" className="text-center">
                                                    <div className="spinner-border text-secondary"/>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                            </Loader>
                        </Section>
                    : <div className={classnames("text-center m-auto p-5 rounded-md shadow-sm ring-1 ring-black ring-opacity-5 bg-white")}>
                        <HandRaisedIcon className="mx-auto h-12 w-12 text-gray-400"/>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">Onboarding in progress</h3>
                        <p className="mt-1 text-sm text-gray-500">Please hang tight while we complete the initial mapping of the various objects. We will reach out once that is complete.</p>
                    </div>
                }
            </InfiniteScroll>
            <UpdateIntegrationReferenceModal
                show={showUpdateIntegrationReferenceModal}
                onClose={onModalClose}
                feature={feature}
                reference_type={entity}
                reference={referenceToEdit}
            />
            <SimpleModal
                show={showUnlinkConfirmationModal}
                title="Confirm unlink?"
                body={
                    <p>
                        <span>Are you sure you want to unlink the {entity.toLowerCase()}?</span>
                        <br/><br/>
                        <span>Note: This will prevent any future updates for this {entity.toLowerCase()} to be pushed to {integration.name}.</span>
                    </p>
                }
                buttonVariant="danger"
                buttonTitle="Confirm Unlink"
                onConfirm={() => {
                    setShowUnlinkConfirmationModal(false);
                    unmatchReference(referenceToUnlink);
                }}
                onClose={() => {
                    setShowUnlinkConfirmationModal(false);
                }}
            />
        </ContentContainer>
    );
}

export default IntegrationReferences;
