import '../../App.scss';
import '../../css/modals.scss';
import React, {createRef} from 'react';
import { useTranslation } from 'react-i18next';
import BaseSideModal from "./BaseSideModal";
import SubmitButton from "../SubmitButton";
import BaseProductPricingCreateOrUpdate from "../BaseProductPricingCreateOrUpdate";
import {Button} from "react-bootstrap";
const _ = require('lodash');

function AddOrEditProductPricingModal2(props) {
    const { t } = useTranslation('common');
    const formRef = createRef();

    if (!props.show) {
        return;
    }

    const onSubmit = () => {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    }

    const onProductPricingCreated = (productPricing) => {
        if (props.onProductPricingCreated) {
            props.onProductPricingCreated(productPricing);
        }
        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Content className={props.formClassName}>
                <BaseSideModal.Header title="Create Product Pricing" />
                <BaseSideModal.Body>
                    <BaseProductPricingCreateOrUpdate
                        ref={formRef}
                        hideCreateButton
                        selectProduct
                        allowOnetimeOnly={props.allowOnetimeOnly}
                        onProductPricingCreated={onProductPricingCreated}
                    />
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Button variant="primary" onClick={onSubmit}>Create</Button>
                </BaseSideModal.Footer>
            </BaseSideModal.Content>
        </BaseSideModal>
    );
}

export default AddOrEditProductPricingModal2;
