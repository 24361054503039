import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverFetch } from "../../server";
const _ = require('lodash');

function useGetMembers(includePending= true) {
    const { company, getApiUrl } = useContext(BaseContext);

    const [members, setMembers] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const fetchData = (skipCache) => {
        setLoading(true);

        serverFetch(getApiUrl("/users"), { skipCache: skipCache }).then((res) => {
            setLoading(false);
            if (res) {
                let results = res;
                if (!includePending) {
                    results = _.filter(res, (r) => !r.pending)
                }

                if (!_.endsWith(company.email, "@maplebilling.com")) {
                    // Filter out Maple users for now
                    results = _.filter(res, (r) => {
                        if (r.user && r.user.email.endsWith("@maplebilling.com")) {
                            return false;
                        }
                        return true;
                    })
                }
                setMembers(results);
            }
        });
    };

    return {
        members,
        isMembersLoading: isLoading,
        fetchMembers: fetchData,
    };
}

export default useGetMembers;
