import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import {Button, Col, Row} from "react-bootstrap";
import { CSVImporter } from "csv-import-react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import moment from "moment";
import {
    BaseContext,
    getCsvImportColumns,
    renderContractStatusLabel,
    renderFileImportStatusLabel,
    sleep
} from "../../helpers/common";
import {serverPost, serverPut} from "../../helpers/server";
import Notification from "../../components/Notification";
import BaseModal from "../../components/modals/BaseModal";
import {useSearchParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Section from "../../components/Section";
import MapleTable from "../../components/MapleTable";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import SortableTableHeader from "../../components/SortableTableHeader";
import Columns from "../../components/Columns";
const _ = require("lodash")

function Imports() {
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportSubmittedModal, setShowImportSubmittedModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [sort, setSort] = useState("createdAtDesc");
    const [fromKey, setFromKey] = useState(null);
    const [ searchParams ] = useSearchParams();
    let importType = searchParams.get('import_type');
    const disableInitialFilters = importType !== null;
    const [imports, setImports] = useState([]);
    const [meta, setMeta] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState([]);

    const cachedSelectedFilters = useMemo(() => {
        if (disableInitialFilters) {
            return {
                import_type: importType
            };
        }
        const cachedString = localStorage.getItem(company.id + "_filter_cache_file_imports");
        if (cachedString) {
            return JSON.parse(cachedString);
        }
        return null;
    }, [company])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || {});

    useEffect(() => {
        setPageTitle(`Imports`);
    }, []);

    useEffect(() => {
        const importTypeOptions = [
            { value: "CUSTOMER", label: "Customer" },
            { value: "BILLABLE_EVENT", label: "Billable Event" },
        ]

        const importStatusOptions = [
            { value: "COMPLETED", label: "Completed" },
            { value: "FAILED", label: "Failed" },
            { value: "IN_PROGRESS", label: "In Progress" },
            { value: "PENDING", label: "Pending" },
        ]
        setFilters([
            { title: "Import Type", type: "select", name: "import_type", options: importTypeOptions },
            { title: "Import Status", type: "select", name: "import_status", options: importStatusOptions },
        ])
    }, [])

    const createImport = (data) => {
        setShowImportModal(false);
        setIsUploading(true);
        setShowImportSubmittedModal(true);
        setError(null)

        uploadToS3(data.rows).then((res) => {
            if (res) {
                const postData = {
                    file_id: res.fileId,
                    total_rows: data.num_rows,
                    type: "CUSTOMER"
                }
                serverPost(getApiUrl("/import/file_import"), postData).then((res) => {
                    if (res) {
                        setIsUploading(false)
                    } else {
                        setError("Import could not be started. Please try again later.")
                        setIsUploading(false);
                    }
                })
            } else {
                setError("Import could not be started. Please try again later.")
                setIsUploading(false);
            }
        })
    }

    const uploadToS3 = async (rows) => {
        const fileName = company.slug + "_customer_import_" + moment().format("YYYY-MM-DD HH:mm:ss");
        const jsonBody = {
            version: "v1",
            objects: rows
        }
        const encodedDataString = new TextEncoder().encode(JSON.stringify(jsonBody));
        const fileData = {
            config_name: "importFile",
            filename: fileName,
            content_type: "application/json",
            file_size: encodedDataString.length
        }
        const signedUrlResponse = await serverPost(getApiUrl('/files/upload_url'), fileData);

        const presignedUrl = signedUrlResponse.url;

        const putResponse = await serverPut(presignedUrl, encodedDataString, {headers: signedUrlResponse.headers})
        if (putResponse) {
            fileData.filename = signedUrlResponse.filename;
            fileData.original_filename = fileData.filename;

            const ackResponse = await serverPost(getApiUrl('/files/ack_upload'), fileData)
            return {
                finalUrl: ackResponse.finalUrl,
                fileId: ackResponse.file_id
            };
        } else {
            return Promise.resolve(null);
        }
    }

    const onSearch = (restart = true) => {
        const limit = 50
        const params = {
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null : fromKey,
                limit: limit
            },
            query: {
                ...selectedFilters,
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/import/file_import/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setImports(results);
                } else {
                    setImports(_.concat(imports, results));
                }
                if (res.meta) {
                    setMeta(res.meta);
                }

                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        })
    }

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    const onParamsChange = (data) => {
        setSelectedFilters(data);
        if (!disableInitialFilters) {
            localStorage.setItem(company.id + "_filter_cache_file_imports", JSON.stringify(data));
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                    dataLength={imports.length}
                >
                    <Section
                        title={"Imports"}
                        loading={loading}
                        actions={[{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Import Customer",
                            onClick: () => setShowImportModal(true)
                        }]}
                    >
                        <MapleTable>
                            <MapleTableHeaderWithActions
                                showSearch={false}
                                showFilters={true}
                                filters={filters}
                                meta={meta}
                                onParamsChange={onParamsChange}
                                cachedSelectedFilters={cachedSelectedFilters}
                            />
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <SortableTableHeader
                                        onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                                        currentSort={sort}
                                        className="whitespace-nowrap flex-nowrap d-none d-xl-table-cell">
                                        Uploaded At
                                    </SortableTableHeader>
                                    <MapleTable.TH className="no-stretch d-none d-md-table-cell">Uploaded By</MapleTable.TH>
                                    <MapleTable.TH>Type</MapleTable.TH>
                                    <MapleTable.TH>Status</MapleTable.TH>
                                    <MapleTable.TH>Total Rows</MapleTable.TH>
                                    <MapleTable.TH>Processed Rows</MapleTable.TH>
                                    <MapleTable.TH>Failed Rows</MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(imports, (row, i) =>
                                        <MapleTable.TR key={i} className="cursor-pointer hover:bg-gray-100"
                                                       href={getCompanySpecificUrl(`/settings/import/view/${row.id}`)}>
                                            <td className="d-none d-xl-table-cell">
                                                <span>{moment(row.created_at).format("MMM D, YYYY h:mm:ss a")}</span>
                                            </td>
                                            <td className="w-px whitespace-nowrap d-none d-md-table-cell">
                                                {
                                                    row.company_user &&
                                                    <Columns.CompanyUserName companyUser={row.company_user}/>
                                                }
                                            </td>
                                            <td>{row.type}</td>
                                            <td>
                                                <div>
                                                    {renderFileImportStatusLabel(row)}
                                                    {
                                                        row.failed_rows > 0 &&
                                                        <>
                                                            <div className={"text-xs gray3 mt-1"}><i className="fa fa-triangle-exclamation warning-color"/> Has Errors</div>
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                            <td>{row.total_rows}</td>
                                            <td>{row.processed_rows}</td>
                                            <td>{row.failed_rows}</td>
                                        </MapleTable.TR>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
            <CSVImporter
                modalIsOpen={showImportModal}
                modalOnCloseTriggered={() => setShowImportModal(false)}
                onComplete={(data) => createImport(data)}
                template={{
                    columns: getCsvImportColumns("CUSTOMER"),
                }}
            />
            <BaseModal
                size={'md'}
                show={showImportSubmittedModal}
                onClose={() => {
                    setShowImportSubmittedModal(false)
                    onSearch(true)
                }}>
                <BaseModal.Header>
                    <BaseModal.Title>Importing Data</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <div className="align-items-center">
                    {
                        isUploading &&
                            <div className="text-center">
                                <div className="spinner-border text-secondary"/>
                            </div>
                    }
                    {
                        !_.isNil(error) &&
                        <div className="body1">{error}</div>
                    }
                    {
                        !isUploading && _.isNil(error) &&
                        <div>
                            <span className="body1">Your import has successfully been started!</span><br/><br/>
                            {/*<span className="body1">You'll be notified via email once the import is complete.</span>*/}
                        </div>
                    }
                    </div>
                </BaseModal.Body>
                {
                    !isUploading &&
                    <BaseModal.Footer>
                        <Col md="12" className="text-end">
                            <Button variant="primary" onClick={() => {
                                setShowImportSubmittedModal(false)
                                onSearch(true)
                            }}>Dismiss</Button>
                        </Col>
                    </BaseModal.Footer>
                }
            </BaseModal>
        </BaseContainer>
    )
}

export default Imports;