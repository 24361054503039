import React, {useState} from 'react';
import '../../App.scss';
import '../../css/table.scss';
import '../../css/forms.scss';
import {serverPost, getUrl} from '../../helpers/server';
import {storageLogout} from '../../helpers/storage';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import SuperContainer from '../../components/SuperContainer';
import ContentBox from '../../components/ContentBox';
import {Row, Table, Button} from 'react-bootstrap';
import CopyableComponent from "../../components/CopyableComponent";
import {downloadBlob, getRoleDescription} from "../../helpers/common";
import moment from "moment";

const _ = require('lodash');

function SuperUserDashboard() {
    const [spoofError, setSpoofError] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    const createDemoAccount = (data) => {
        serverPost(getUrl("/super_user/demo"), data).then((res) => {
            if (res) {
                // do something
                console.log("Demo account created");
            }
        });
    }

    const sweepSubscriptions = (data) => {
        const newData = {
            company_id: data.company_id
        }
        serverPost(getUrl("/super_user/sweep_subscriptions"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Subscriptions sweep started");
            }
        });
    }

    const sweepChildRollups = (data) => {
        const newData = {
            company_id: data.company_id
        }
        serverPost(getUrl("/super_user/sweep_child_rollups"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Child rollups sweep started");
            }
        });
    }

    const sweepReports = (data) => {
        const newData = {
            company_id: data.company_id,
            report_id: data.report_id || null
        }
        serverPost(getUrl("/super_user/sweep_reports"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Reports sweep started");
            }
        });
    }

    const cacheConversionRates = (data) => {
        const newData = {}
        serverPost(getUrl("/super_user/cache_conversion_rates"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Cache conversion rates started");
            }
        });
    }

    const generateInvoice = (data) => {
        const newData = {
            company_id: data.company_id,
            subscription_id: data.subscription_id
        }
        serverPost(getUrl("/super_user/generate_invoice"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Invoice generated");
            }
        });
    }

    const triggerProcessSubscriptionJob = (data) => {
        const newData = {
            company_id: data.company_id,
            subscription_id: data.subscription_id
        }
        serverPost(getUrl("/super_user/trigger_process_subscription"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Invoice generated");
            }
        });
    }

    const triggerThresholdAlerts = (data) => {
        const newData = {
            company_id: data.company_id,
            customer_id: data.customer_id,
            record_ids: data.record_ids
        }
        serverPost(getUrl("/super_user/trigger_threshold_alerts"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Threshold alerts generated");
            }
        });
    }

    const updateSubsCounts = (data) => {
        const newData = {
            company_id: data.company_id,
        }
        serverPost(getUrl("/super_user/update_subs_counts"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Subs counts generated");
            }
        });
    }


    const generateOneTimeInvoice = (data) => {
        const newData = {
            company_id: data.company_id,
            customer_id: data.customer_id,
            auto_charges: data.auto_charges,
            currency: data.currency,
        }
        serverPost(getUrl("/super_user/generate_one_time_invoice"), newData).then((res) => {
            if (res) {
                // do something
                console.log("One time invoice generated");
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "search") {
            serverPost(getUrl("/super_user/search"), { query: value }).then((res) => {
                if (res) {
                    // do something
                    setSearchResults(res);
                }
            });
        }
    }

    const spoofUser = (data) => {
        const handleError = async (error) => {
            const message = await error.json();
            setSpoofError("Error: " + error.status + ": " + message.error_message)
        }
        if (_.isEmpty(data.user_id)) {
            data.user_id = null;
        }
        serverPost(getUrl("/super_user/spoof"), data, {}, handleError).then((res) => {
            if (res) {
                // do something
                // Store the current user info so we can restore later;
                const existingToken = localStorage.getItem("auth_token");

                storageLogout();
                localStorage.setItem("spoof_auth_token", existingToken);
                localStorage.setItem("auth_token", res['token']);
                window.location.href = "/user/home";
            }
        });
    }

    const triggerStripeCustomersImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id,
            stripe_customer_ids: data.stripe_customer_ids
        }
        serverPost(getUrl("/super_user/customer_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Customer import triggered");
            }
        });
    }

    const mapPaymentProviderProfile = (data) => {
        const newData = {
            company_id: data.company_id,
            customer_ids: data.customer_ids,
            stripe_account_id: data.stripe_account_id
        }
        serverPost(getUrl("/super_user/map_payment_profiles"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Map payment profile triggered");
            }
        });
    }

    const triggerSubscriptionsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id
        }
        serverPost(getUrl("/super_user/subscriptions_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Subscriptions import triggered");
            }
        });
    }

    const triggerSubscriptionsUsageMetricsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id
        }
        serverPost(getUrl("/super_user/subscriptions_usage_metrics_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Subscriptions usage metrics import triggered");
            }
        });
    }

    const triggerCustomersPaymentsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_customer_id: data.stripe_customer_id,
            customer_id: data.customer_id
        }
        serverPost(getUrl("/super_user/payments_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Payments import triggered");
            }
        });
    }

    const triggerCustomersInvoicesAndPaymentsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id
        }
        serverPost(getUrl("/super_user/invoices_and_payments_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Invoices and Payments import triggered");
            }
        });
    }

    const triggerCustomerInvoicesImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_invoice_ids: data.stripe_invoice_ids,
            stripe_customer_id: data.stripe_customer_id,
            stripe_account_id: data.stripe_account_id,
            handle_subscription: data.handle_subscription,
        }
        serverPost(getUrl("/super_user/invoices_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Invoice import triggered");
            }
        });
    }

    const triggerProductImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_product_id: data.stripe_product_id,
            stripe_account_id: data.stripe_account_id,
        }
        serverPost(getUrl("/super_user/products_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Product import triggered");
            }
        });
    }

    const triggerProductTaxCategories = (data) => {
        serverPost(getUrl("/super_user/import_tax_categories"), {}).then((res) => {
            if (res) {
                // do something
                console.log("Tax categories import triggered");
            }
        });
    }

    const sweepMRR = (data) => {
        const newData = {
            company_id: data.company_id,
            customer_id: data.customer_id
        }
        serverPost(getUrl("/super_user/sweep_mrr"), newData).then((res) => {
            if (res) {
                // do something
                console.log("MRR updated");
            }
        });
    }

    const updateMRRCache = (data) => {
        const newData = {
            company_id: data.company_id,
            time: data.time || null
        }
        serverPost(getUrl("/super_user/update_mrr_cache"), newData).then((res) => {
            if (res) {
                // do something
                console.log("MRR cache updated");
            }
        });
    }

    const updatePaymentProviderSync = (data) => {
        serverPost(getUrl("/super_user/update_payment_provider_sync"), data).then((res) => {
            if (res) {
                // do something
                console.log("Payment provider sync updated");
            }
        });
    }

    const runCleanupSubscriptions = (data) => {
        serverPost(getUrl("/super_user/cleanup_subscriptions"), data).then((res) => {
            if (res) {
                // do something
                console.log("Clean up subscriptions");
            }
        });
    }

    const runInvoiceMetricGroupingBreakdownCalculation = (data) => {
        serverPost(getUrl("/super_user/invoice_metric_grouping_breakdown"), data).then((res) => {
            if (res) {
                // do something
                console.log("Invoice Metric Grouping Breakdown");
            }
        });
    }

    const runInvoiceMetricGroupingBreakdownCalculationForCompany = (data) => {
        serverPost(getUrl("/super_user/invoice_metric_grouping_breakdown_for_company"), data).then((res) => {
            if (res) {
                // do something
                console.log("Invoice Metric Grouping Breakdown for company");
            }
        });
    }

    const updateStatusForCompanyContract = (data) => {
        serverPost(getUrl("/super_user/update_proposal_status"), data).then((res) => {
            if (res) {
                console.log("Updated Status For Contract");
            }
        })
    }

    const updateCalculationCachesForMetricForCompany = (data) => {
        serverPost(getUrl("/super_user/update_calc_caches_for_metric"), data).then((res) => {
            if (res) {
                console.log("Updated Calculation Caches for Metric for company");
            }
        })
    }

    const customMetricReport = (data) => {
        serverPost(getUrl("/super_user/custom_metric_report"), data, {noJson: true}).then((res) => {
            if (res) {
                downloadBlob(res, "custom_report.csv");
            }
        })
    }

    const generateJournalEntriesForSubscription = (data) => {
        serverPost(getUrl("/super_user/generate_subscription_journal_entries"), data).then((res) => {
            if (res) {
                console.log("Generated Journal Entries for Subscription");
            }
        })
    }

    return (
        <SuperContainer>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={createDemoAccount}>
                        <h3>Create Demo Account</h3>
                        <Row>
                            <BaseForm.Input colSpan="6" type="text" label="Company Name" name="company_name" required/>
                            <BaseForm.Input colSpan="6" type="text" label="Company Email" name="company_email"
                                            transformations={["lowercase", "trim"]} required/>
                            <BaseForm.Input colSpan="4" type="text" label="User Name" name="user_name" required/>
                            <BaseForm.Input colSpan="4" type="text" label="User Email" name="user_email"
                                            transformations={["lowercase", "trim"]} required/>
                            <BaseForm.Input colSpan="4" type="text" label="User Password" name="password" required/>
                        </Row>
                        <SubmitButton>Create Demo Account</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={spoofUser} onFieldChange={onFieldChange}>
                        <h3>Spoof</h3>
                        <Row>
                            <BaseForm.Input colSpan="12" type="text" label="Search" name="search"/>
                        </Row>
                        <Table>
                            <tbody>
                            {
                                _.map(searchResults, (sr, k) =>
                                    <tr key={k}>
                                        <td>
                                            { sr.company.name }
                                            <CopyableComponent value={ sr.company_id }/>
                                        </td>
                                        <td>
                                            <span>{ sr.user.name }</span><br/>
                                            <span>{ sr.user.email }</span>
                                        </td>
                                        <td>{ getRoleDescription(sr) }</td>
                                        <td><Button variant="primary" onClick={() => spoofUser({ user_id: sr.user.id })}>Spoof</Button></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <Row className="hide">
                            <BaseForm.Input colSpan="6" type="text" label="User ID" name="user_id"/>
                            <BaseForm.Input colSpan="6" type="text" label="User Email" name="user_email"
                                            transformations={["lowercase", "trim"]} validations={{validateEmail: true}}/>
                        </Row>
                        <div className="form-error-message">{spoofError}</div>
                        <SubmitButton className="hide">Spoof User</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepSubscriptions}>
                        <h3>Sweep Subscriptions For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Sweep Subscriptions</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepSubscriptions}>
                        <h3>Sweep Subscriptions For All Companies</h3>
                        <SubmitButton>Sweep Subscriptions</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepChildRollups}>
                        <h3>Sweep Child Rollups For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Sweep Child Rollups</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepChildRollups}>
                        <h3>Sweep Child Rollups For All Companies</h3>
                        <SubmitButton>Sweep Child Rollups</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepReports}>
                        <h3>Sweep Reports For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Report ID" name="report_id"/>
                        </Row>
                        <SubmitButton>Sweep Reports</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepReports}>
                        <h3>Sweep Reports For All Companies</h3>
                        <SubmitButton>Sweep Reports</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepMRR}>
                        <h3>Sweep MRR For Customer</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Customer ID" name="customer_id" required/>
                        </Row>
                        <SubmitButton>Sweep MRR</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={updateMRRCache}>
                        <h3>Update MRR Cache For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="date" label="Time" name="time" includeTime={true}/>
                        </Row>
                        <SubmitButton>Update MRR Cache</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={cacheConversionRates}>
                        <h3>Cache Conversion Rates</h3>
                        <SubmitButton>Cache Rates</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={generateInvoice}>
                        <h3>Generate Subscription Invoice</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Subscription ID" name="subscription_id"
                                            required/>
                        </Row>
                        <SubmitButton>Generate Subscription Invoice</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerProcessSubscriptionJob}>
                        <h3>Trigger Process Subscription Job</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Subscription ID" name="subscription_id" required/>
                        </Row>
                        <SubmitButton>Run Process Subscription</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={updateSubsCounts}>
                        <h3>Update Subscription Counts For Pricing</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Update Subscription Counts</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={generateOneTimeInvoice}>
                        <h3>Generate One-Time Invoice</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Customer ID" name="customer_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Currency" name="currency" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Auto Charges" name="auto_charges" required/>
                        </Row>
                        <SubmitButton>Generate One-Time Invoice</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runCleanupSubscriptions}>
                        <h3>Run Subscription Cleanup</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Subscription IDs" name="subscription_ids" required/>
                        </Row>
                        <SubmitButton>Run Cleanup</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerThresholdAlerts}>
                        <h3>Trigger Threshold Alerts Job</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Customer ID" name="customer_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Record IDs" name="record_ids" required/>
                        </Row>
                        <SubmitButton>Trigger Threshold Alerts</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerStripeCustomersImport}>
                        <h3>Trigger Stripe Customers Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Customer IDs" name="stripe_customer_ids"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Customer Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerCustomersInvoicesAndPaymentsImport}>
                        <h3>Trigger Stripe Customers Invoices and Payments Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Payments Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerCustomersPaymentsImport}>
                        <h3>Trigger Stripe Customers Payments Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Customer ID" name="stripe_customer_id"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="Customer ID" name="customer_id" required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Payments Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerSubscriptionsImport}>
                        <h3>Trigger Stripe Subscriptions Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Subscriptions Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerSubscriptionsUsageMetricsImport}>
                        <h3>Trigger Stripe Subscriptions Usage Metrics Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Subscriptions Usage Metrics Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerCustomerInvoicesImport}>
                        <h3>Trigger Stripe Customers Invoices Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Invoice IDs" name="stripe_invoice_ids"/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Customer ID"
                                            name="stripe_customer_id"/>
                            <BaseForm.Input colSpan="4" name="handle_subscription" type="switch"
                                            label="Handle Subscriptions" value="true"
                                            formInputClassName="transparent"/>
                        </Row>
                        <SubmitButton>Trigger Stripe Invoice Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerProductImport}>
                        <h3>Trigger Stripe Product Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Product ID" name="stripe_product_id"/>
                        </Row>
                        <SubmitButton>Trigger Stripe Product Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={mapPaymentProviderProfile}>
                        <h3>Trigger Stripe Payment Profile Mapping</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="Customer IDs" name="customer_ids"
                                            required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Payment Profile Mapping</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerProductTaxCategories}>
                        <h3>Trigger Tax Categories Import</h3>
                        <SubmitButton>Trigger Tax Categories Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={updatePaymentProviderSync}>
                        <h3>Update Payment Provider Sync</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="switch" label="Enable Sync" name="enabled" required/>
                        </Row>
                        <SubmitButton>Update Payment Provider Sync</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runInvoiceMetricGroupingBreakdownCalculation}>
                        <h3>Run Invoice Metric Grouping Calculation for Invoice</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Invoice UUID" name="invoice_uuid" required/>
                        </Row>
                        <SubmitButton>Run Invoice Metric Grouping</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runInvoiceMetricGroupingBreakdownCalculationForCompany}>
                        <h3>Run Invoice Metric Grouping Calculation for Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Invoice Metric Grouping</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={updateStatusForCompanyContract}>
                        <h3>Update Status for Contract</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Proposal ID" name="proposal_id" required/>
                        </Row>
                        <SubmitButton>Update Status</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={updateCalculationCachesForMetricForCompany}>
                        <h3>Update Metric Calculation Caches for Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Metric" name="metric" required/>
                            <BaseForm.Input colSpan="4" type="date" label="Date" name="date" includeTime={true} maxDate={moment()}/>
                        </Row>
                        <SubmitButton>Update Status</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={customMetricReport}>
                        <h3>Custom Metric Report for Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Metric" name="metric" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Grouping" name="grouping" required/>
                        </Row>
                        <SubmitButton>Get Custom Report</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={generateJournalEntriesForSubscription}>
                        <h3>Generate Journal Entries For Subscription</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Subscription ID" name="subscription_id" required/>
                        </Row>
                        <SubmitButton>Generate Journal Entries</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
        </SuperContainer>
    );
}

export default SuperUserDashboard;
