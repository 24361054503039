import '../../App.scss';
import '../../css/modals.scss';
import React, {useEffect} from 'react';
import BaseForm from '../BaseForm';
import ItemPricingFields from '../ItemPricingFields';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { useState, createRef } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {renderDescriptionForItemPricing} from "../../helpers/common";
const _ = require('lodash');

function AddOrEditBillableItemPricingModal(props) {
    const { t } = useTranslation('common');
    const fieldsRef = createRef();

    const [initialFields, setInitialFields] = useState(null);

    useEffect(() => {
        if (!_.isNil(props.pricingToEdit)) {
            const c = _.cloneDeep(props.pricingToEdit);
            c.currency = props.pricingToEdit.base_price.currency;
            setInitialFields(c);
        }
    }, [props.pricingToEdit]);

    useEffect(() => {
        if (!props.show) {
            setInitialFields(null)
        }
    }, [props.show])

    const onFieldChange = (key, value) => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields[key] = value;
            return newFields
        })
        if (fieldsRef.current) {
            fieldsRef.current.onFieldChange(key, value);
        }
    }

    const cleanupFields = (fields) => {
        let newFields = null;
        if (fields.type === "GRADIENT") {
            fields.gradient_price = initialFields.gradient_price;
        }
        if (fields.type === "VOLUME") {
            fields.volume_price = initialFields.volume_price;
        }
        if (fieldsRef.current) {
            newFields = fieldsRef.current.processFields(fields);
        } else {
            newFields = {...fields};
        }
        return newFields;
    }

    const displayTypeOptions = [{
        value: "UNITS",
        label: "Units"
    }, {
        value: "AMOUNT",
        label: "Amount"
    }]

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            itemLabel={t('billable_item_pricing.pricing')}
            itemBaseUrl="/item_pricings"
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            itemToEdit={initialFields}
            isEditing={!_.isNil(props.pricingToEdit)}
            submitDisabled={fieldsRef.current && fieldsRef.current.isDisabled()}
            onFieldChange={onFieldChange}
        >
            <p>You can create individual pricing objects that can be used across different products.</p>
            <Row>
                <BaseForm.Input colSpan="6" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="6" name="description" label={t('common.description')} type="textarea" />
            </Row>
            <Row>
                <BaseForm.Input
                    colSpan="6" name="external_name" label={"Public Name (optional)"} type="text"
                    description="This will display in communications to your customers, if different from the name."
                />
                {
                    !_.isNil(props.pricingToEdit) &&
                    <BaseForm.Input
                        colSpan="6" name="display_type" label={"Display Type"} type="select"
                        options={displayTypeOptions}
                        description="How should we display the item in the invoices. e.g 25 units or $25."
                    />
                }
            </Row>
            {
                (!props.pricingToEdit || props.pricingToEdit.editable) &&
                <>
                    <ItemPricingFields
                        ref={fieldsRef} setInitialFields={setInitialFields} initialFields={initialFields}
                        itemToEdit={props.pricingToEdit} onCreateBillableItem={props.onCreateBillableItem}
                    />
                </>
            }
            {
                props.pricingToEdit && !props.pricingToEdit.editable &&
                <div className="mt-3">
                    <div className="text-sm font-semibold">Pricing Details</div>
                    <span className="text-sm font-normal text-gray-700">
                    { renderDescriptionForItemPricing(props.pricingToEdit, false, props.pricingToEdit.item) }
                    </span>
                    <div className="text-sm font-normal text-gray-700 italic mt-3">*The pricing details can't be changed once it is attached to a product price.</div>
                </div>
            }
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditBillableItemPricingModal;
