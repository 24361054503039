import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice, downloadBlob, getCurrencyOptions} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Link from '../../components/Link';
import ContentBox from "../../components/ContentBox";
import MapleTable from "../../components/MapleTable";
import moment from "moment";
import BaseForm from "../../components/BaseForm";
import SubmitButton from "../../components/SubmitButton";
import Breadcrumb from "../../components/Breadcrumb";
import DateRangePicker from "../../components/DateRangePicker";
import {serverPost} from "../../helpers/server";
import Columns from "../../components/Columns";
const _ = require('lodash');

function RevenueRecognition() {
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [selectedDateRange, setSelectedDateRange] = useState({
        start: moment().startOf('year'),
        end: moment()
    });
    const [monthsToDisplay, setMonthsToDisplay] = useState([]);
    const [revRecData, setRevRecData] = useState([]);
    const initialFields = useMemo(() => {
        return {
            reference_date: moment(),
            currency: company.preferred_currency
        }
    }, []);
    const [summary, setSummary] = useState({
        month_totals: [],
        recognized_total: {},
        balance_total: {},
    });

    useEffect(() => {
        setPageTitle(`Financial Reports`);
    }, []);

    useEffect(() => {
        runReport(initialFields);
    }, [])

    const exportResults = () => {
        // TODO(Ursula): Hook up backend API call for export here
        // serverPost(getApiUrl("/reports/invoice_deferred_revenue_report_export"), reportInput, { noJson: true }).then(res => {
        //     downloadBlob(res, `Deferred-Revenue-Report-${moment(reportInput.reference_date).format("YYYY-MM-DD")}-${reportInput.currency}.csv`);
        // })
    }

    const getMonthsToDisplay = (dateRange) => {
        let start = dateRange.start.clone().startOf('month').utcOffset(0, true);
        let end = dateRange.end.clone().add(1, 'year').startOf('month').utcOffset(0, true);
        const dates = [
            start.utcOffset(0, true).clone()
        ];

        while (start.isBefore(end)) {
            start = start.add(1, 'month');
            dates.push(start.clone())
        }
        return dates;
    }

    const runReport = async (data) => {
        setLoading(true);
        const reportData = {
            period: {
                start_date: selectedDateRange.start.utcOffset(0, true),
                end_date: selectedDateRange.end.clone().add(1, 'year').utcOffset(0, true)
            },
            pagination: {
                limit: 100
            }
        };
        const result = await serverPost(getApiUrl("/journal_entries/revenue"), reportData);
        if (result) {
            setRevRecData(result.records);
            setMonthsToDisplay(getMonthsToDisplay(selectedDateRange));
        }
        setLoading(false);
    };

    const breadcrumbItems = [
        { name: 'Financial Reports', href: getCompanySpecificUrl(`/reports`), current: false },
        { name: "Revenue Recognition Report", current: true }
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <Section title="Revenue Recognition Report">
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={initialFields} onSubmit={runReport}>
                                <div className="flex flex-row gap-2">
                                    <div className="grow">
                                        <div className="space-y-0 w-48">
                                            <div className="form-group inline">
                                                <div className="form-label">Time Period</div>
                                                <div className="form-input">
                                                    <DateRangePicker
                                                        iconOnLeft
                                                        clearable={false}
                                                        defaultValue={selectedDateRange}
                                                        name={"date_range"}
                                                        onDatesChange={(start, end) => {
                                                            setSelectedDateRange({
                                                                start: start,
                                                                end: end
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-end">
                                        <SubmitButton>Run</SubmitButton>
                                    </div>
                                </div>

                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section loading={loading} className="mt-4">
                    <MapleTable>
                        <div className="flex flex-row items-center">
                            <div className="grow">
                                <span className="text-base font-semibold text-gray-700">Revenue Recognition Report</span><br/>
                                <span className="text-gray-500">Some summary here</span>
                            </div>
                            <div className="inline-block cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={exportResults}>
                                <span className="text-slate-900">Export</span>
                            </div>
                        </div>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH className="border-e border-gray-200">Customer</MapleTable.TH>
                                    {
                                        _.map(monthsToDisplay, (m, c) =>
                                            <MapleTable.TH key={c} className="text-end w-px whitespace-nowrap">{ m.format("MMM, YY") }</MapleTable.TH>
                                        )
                                    }
                                    <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50">Total</MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(revRecData, (row, i) =>
                                    <React.Fragment key={i}>
                                        <tr>
                                            <td className="border-e border-gray-200">
                                                <Columns.CustomerName customer={row.customer} hideAvatar />
                                            </td>
                                            {
                                                _.map(monthsToDisplay, (m, c) =>
                                                    <td key={c} className="text-end w-px whitespace-nowrap">
                                                        {
                                                            (row["breakdown"][c] && row["breakdown"][c]["amount"] && row["breakdown"][c]["amount"].value_in_cents > 0) ?
                                                                currencyFormatFromPrice(row["breakdown"][c]["amount"])
                                                            : <span className="text-sm text-gray-400">-</span>
                                                        }

                                                    </td>
                                                )
                                            }
                                            <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(row.total) }</td>
                                        </tr>
                                    </React.Fragment>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}
// { currencyFormatFromPrice(row["breakdown"][c]["amount"]) }

export default RevenueRecognition;
